<template>
  <div id="industry_menu">
    <div class="main-industry-contain">
      <div ref='main_view' class="content-view">
        <button class="industry-item" v-for="(item,index) in showIndustrys" :data="item" :key="index" :class="item.isSel ? 'sel-status' : 'nor-status'" @click="selectEvent(item)">{{item.name}}</button>
        <button v-if="industrys.length>15" @click="openEvent()" class="industry-item-more">{{ isOpen?'收起':'展开' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import conference from '../api/conference.js'
export default {
  props: {
    haveDataIndustrys: Array,
    clearFlag: Boolean
  },
  data () {
    return {
      industrys: [{id: '0',name: '全部行业', isSel:true}],
      isOpen: false,
      showIndustrys: []
    }
  },
  mounted() {
    this.getIndustrys()
  },
  watch: {
    clearFlag: function() {
      if (this.clearFlag == true) {
        this.getIndustrys()
      }
    },
    haveDataIndustrys:function() {
      this.industrys = [{id: '0',name: '全部行业', isSel:true}]
      this.getIndustrys()
    },
    industrys: function() {
      this.showIndustrys = []
      if (this.industrys.length >= 16) {
        this.isOpen = false
        var num = 0
        this.industrys.forEach( res => {
          if (num<15) {
            this.showIndustrys.push(res)
          }
          num++
        })
      }else{
        this.industrys.forEach( res => {
          this.showIndustrys.push(res)
        })
      }
    }
  },
  methods: {
    openEvent () {
      this.showIndustrys = []
      this.isOpen = !this.isOpen
      if (this.isOpen === true) {
        this.industrys.forEach( res => {
          this.showIndustrys.push(res)
        })
      }else{
        var num = 0
        this.industrys.forEach( res => {
          if (num<15) {
            this.showIndustrys.push(res)
          }
          num++
        })
      }
    },
    isHaveIndustrys (val) {
      var ishave = false
      this.haveDataIndustrys.forEach( res => {
        if (parseInt(res.value) === parseInt(val.id)) {
          ishave = true
        }
      })
      return ishave
    },
    getIndustrys () {
      conference.getIndustries().then(res => {
        this.industrys.length = 0
        this.industrys.push({id: '0',name: '全部行业', isSel:true})
        res.data.forEach( item => {
          if (this.isHaveIndustrys(item)) {
            this.industrys.push(item)
          }
        })
      })
    },
    selectEvent (val) {
      if (val.id === '0') {
        this.industrys.forEach( res => {
          if (res.id === '0') {
            res.isSel = true
          } else {
            res.isSel = false
          }
        })
      } else {
        val.isSel = !val.isSel
        var isAllNO = true
        this.industrys.forEach( res => {
          if (res.isSel === true && res.id !== '0') {
            isAllNO = false
          }
        })
        if (isAllNO === false) {
          this.industrys[0].isSel = false
        }else {
          this.industrys[0].isSel = true
        }

      }
      var industry_ids = ''
      this.industrys.forEach( res => {
        if (res.id !== '0' && res.isSel === true) {
          industry_ids = industry_ids.concat(res.id + ',')
        }
      })
      this.$emit('screenIndustry_ids', industry_ids.substr(0, industry_ids.length - 1))
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
  #industry_menu{
    width: calc(100% - 15px);
    margin-top: 16px;
    margin-left: 0px;
  }
  .main-industry-contain{
    width: 100%;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
  }
  .content-view{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 4px 14px 14px;
    overflow: hidden;
    transform: height .3s;
  }
  .industry-item{
    border: none;
    margin: 0 0 13px 13px;
    text-align: center;
    font-size: 14px;
    width: 100px;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .industry-item-more{
    border: none;
    margin: 0 0 10px 10px;
    text-align: center;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    width: 100px;
    padding: 0 15px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F6FAFB;
    color: #4880FF;
  }
  .sel-status{
    background-color: #4880FF;
    color: #FFFFFF;
  }
  .nor-status{
    background-color: #F6FAFB;
    color: #121212;
    &:hover {
      background-color: #f0f0f0;
    }
  }
</style>
