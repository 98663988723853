<template>
  <div class="setting-wrapper">
    <div class="setting-title">设置</div>
    <div class="setting-content-container">

      <!-- 登陆设置 -->
      <div class="setting-title-bar-container">
        <div class="setting-blue-bar"></div>
        <div class="setting-title-child">登录设置</div>
      </div>
      <div class="setting-item-container">
          <div class="setting-item-key">设置登录密码:</div>
          <el-input class="setting-password-value" placeholder="请输入密码" size="small" v-model="password1" @input="onInput()" show-password></el-input>
          <div class="setting-item-key">确认登录密码:</div>
          <el-input class="setting-password-value" placeholder="请确认输入密码" size="small" v-model="password2" @input="onInputAgain()" show-password></el-input>
        </div>

      <!-- 基本信息 -->
      <div class="setting-title-bar-container">
        <div class="setting-blue-bar"></div>
        <div class="setting-title-child">基本信息</div>
      </div>
      <div class="setting-info-item-hori-container">
        <div class="setting-info-item-container" @mouseover="onMouseOverName(true)" @mouseleave="onMouseOverName(false)">
           <div class="setting-item-key">姓名:</div>
           <div class="setting-item-value" ref="setName" id="name">{{ defaultName }}</div>
           <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="nameShow" @click="nameImgClick"/>
        </div>
        <div class="setting-info-item-container">
           <div class="setting-item-key">性别:</div>
           <el-select v-model="defaultGender" :placeholder="defaultGender"  class="setting-item-value-gender-sel" id="genderSelect" size="small">
             <el-option label="男" value="男"></el-option>
             <el-option label="女" value="女"></el-option>
           </el-select>
           <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="genderShow" @click="genderImgClick"/>
        </div>
        <div class="setting-info-item-container" @mouseover="onMouseOverCity(true)" @mouseleave="onMouseOverCity(false)">
           <div class="setting-item-key">常驻城市:</div>
           <div class="setting-item-value" ref="setCity">{{ defaultCity }}</div>
           <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="cityShow" @click="cityImageClick"/>
        </div>
      </div>
      <div class="setting-info-item-hori-container">
        <div class="setting-info-item-container" @mouseover="onMouseOverPhone(true)" @mouseleave="onMouseOverPhone(false)">
          <div class="setting-item-key">手机号:</div>
          <div class="setting-item-value" ref="setPhone">{{ defaultPhone }}</div>
          <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="phoneShow" @click="phoneImgClick"/>
        </div>
        <div class="setting-info-item-container" @mouseover="onMouseOverEmail(true)" @mouseleave="onMouseOverEmail(false)">
          <div class="setting-item-key">邮箱:</div>
          <div class="setting-item-value" ref="setEmail">{{ defaultEmail }}</div>
          <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="emailShow" @click="emailImgClick"/>
        </div>
      </div>

      <!-- 服务设置 -->
      <div class="setting-title-bar-container">
        <div class="setting-blue-bar"></div>
        <div class="setting-title-child">服务设置</div>
      </div>
      <div class="setting-item-container" @mouseover="onMouseOverIndustry(true)" @mouseleave="onMouseOverIndustry(false)">
        <div class="setting-item-key">行业偏好:</div>
        <div class="setting-item-value" ref="industry">{{industry_preference_info}}</div>
        <img class="setting-item-img" src="../assets/img/icon_setting_edit.png" v-if="ShowIndustry" @click="showSelectIndustry"/>
      </div>
      <div class="setting-item-container">
        <div class="setting-item-key">推送偏好:</div>
        <el-checkbox-group class="setting-item-value" v-model="checkList">
          <el-checkbox label="会议报告"></el-checkbox>
          <el-checkbox label="研究报告"></el-checkbox>
          <el-checkbox label="访谈主题"></el-checkbox>
        </el-checkbox-group>
      </div>

      <div style="width: 100%; height: auto; display: flex; justify-content: center;">
         <div class="setting-btn-save" @click="setting_save_change">保存修改</div>
      </div>

    </div>
      <el-dialog
        :visible.sync="centerDialogVisible"
        :modal-append-to-body='false'
        width="60%"
        center>
        <div slot="title" class="header-title">
           <span>选择您感兴趣的行业</span>
        </div>
       <div class="dialog_tags_container">
         <button :class="industry.isSelect?'industry-item-selected':'industry-item-unselect'" v-for="(industry,index) in industries" :data="industry" :key="index" @click="selectIndustry(index)">{{ industry.name }}</button>
       </div>
       <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="selectBtnConfirm" class="dialog_btn_corner_orange">选好了</el-button>
       </span>
      </el-dialog>


      <el-dialog
        title="提示"
        :visible.sync="fixCenterDialogVisible"
        width="30%"
        center>
        <span>修改成功</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="fixCenterDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

  </div>
</template>

<script>
import settingApi from '@/api/setting'
import store from '../store/index.js'
export default{
  mounted () {
    this.loadUserMsg()
  },
  methods: {
    onInput () {
      this.$forceUpdate()
    },
    onInputAgain () {
      this.$forceUpdate()
    },
    onMouseOverName (isOver) {
      this.nameShow = isOver
    },
    onMouseOverPhone (isOver) {
      this.phoneShow = isOver
    },
    onMouseOverEmail (isOver) {
      this.emailShow = isOver
    },
    onMouseOverIndustry (isOver) {
      this.ShowIndustry = isOver
    },
    onMouseOverCity (isOver) {
      this.cityShow = isOver
    },
    nameImgClick () {
      this.$refs.setName.contentEditable = true
      const dv = this.$refs.setName
      this.$nextTick(this.moveSelection2End(dv))
      document.getElementById('name').addEventListener('keyup',ev => {
        if(ev.target.innerText.length>15){
          ev.target.innerText = (ev.target.innerText).substr(0,15)
          this.$nextTick(this.moveSelection2End(dv))
        }
      })
    },
    phoneImgClick () {
      this.$refs.setPhone.contentEditable = true
      const dv = this.$refs.setPhone
      this.$nextTick(this.moveSelection2End(dv))
    },
    emailImgClick () {
      this.$refs.setEmail.contentEditable = true
      const dv = this.$refs.setEmail
      this.$nextTick(this.moveSelection2End(dv))
    },
    genderImgClick () {

    },
    cityImageClick () {
      this.$refs.setCity.contentEditable = true
      const dv = this.$refs.setCity
      this.$nextTick(this.moveSelection2End(dv))
    },
    moveSelection2End(view){
      if(window.getSelection()){
        view.focus()
        let range = window.getSelection()
        range.selectAllChildren(view)
        range.collapseToEnd()
      }
    },
    showSelectIndustry () {
      this.centerDialogVisible = true
    },
    selectIndustry (index) {
      this.industries[index].isSelect = !this.industries[index].isSelect
    },
    openAlert (msg) {
      this.$message({
        message: msg,
        type: 'error'
      });
    },
    selectBtnConfirm () {
      let industryTarget = ''
      let industryStrInfo = ''
      this.industries.forEach(function (industry) {
        if (industry.isSelect) {
          industryTarget = industryTarget.concat(industry.id + ',')
          industryStrInfo = industryStrInfo.concat(industry.name + ',')
        }
      })
      if(industryTarget.length >0){
        industryTarget = industryTarget.substr(0, industryTarget.length - 1)
        industryStrInfo = industryStrInfo.substr(0, industryStrInfo.length - 1);
      }
      this.selectIndustries = industryTarget
      this.industry_preference_info = industryStrInfo
      this.centerDialogVisible = false
      console.log("=====" + this.industry_preference_info)
    },
    setting_save_change () {
      if(this.checkPassword()){
        this.commitUserInfo()
      }
    },
    loadAllIndustries (selectIndustryIds) {
      settingApi.loadIndustryList().then(res => {
        if (res.code === '0000') {
          let industry_info = '';
          this.industries = res.data;
          this.industries.forEach(res => {
            this.$set(res, 'isSelect', false)
            selectIndustryIds.forEach(res1 => {
              if (res.id === parseInt(res1)) {
                res.isSelect = true
                industry_info = industry_info + res.name + ','
              }
            })
          })
          if(industry_info.charAt(industry_info.length-1) === ','){
            industry_info = industry_info.substring(0, industry_info.length-1)
          }
          this.industry_preference_info = industry_info;
        }
      })
    },
    loadUserMsg() {
      let params = {
        client_contact_id:  store.getters.uid
      };
      settingApi.loadSettingMsg(params).then(res => {
        if(res.code === '0000') {
          this.password1 = res.data.web_pwd
          this.password2 = res.data.web_pwd
          this.defaultName = res.data.name
          this.defaultEmail = res.data.email
          this.defaultPhone = res.data.mobile
          if(res.data.is_male === null){
            this.defaultGender = '未设置'
          }else {
            this.defaultGender = res.data.is_male?'男':'女'
          }
          this.initIndustry(res.data.interest_industry_ids)
          this.initPushPreference(res.data.push_preference)
          this.defaultCity = res.data.usual_location===''?'中国':res.data.usual_location
        }
      })
    },
    checkPassword () {
      if(this.password1 === this.password2){
        return true
      }else {
        this.openAlert('两次输入的密码不一致')
        return false
      }
    },
    commitUserInfo () {
      this.defaultName = this.$refs.setName.innerText
      this.defaultPhone = this.$refs.setPhone.innerText
      this.defaultCity = this.$refs.setCity.innerText
      this.defaultEmail = this.$refs.setEmail.innerText

      let params = {}
      params.web_pwd = this.password1
      params.client_contact_id = store.getters.uid
      params.interest_industry_ids = this.selectIndustries
      params.push_preference = this.targetPushPreference()
      params.email = this.defaultEmail
      params.mobile = this.defaultPhone
      params.usual_location = this.defaultCity
      params.name = this.defaultName
      params.is_male = this.defaultGender==='未设置'?null: this.defaultGender==='男'
      settingApi.commitSettingInfo(params).then(res => {
        if(res.code === '0000') {
          this.fixCenterDialogVisible = true
          this.$store.dispatch('user/setNickname',this.defaultName)
        }
      })
    },
    initIndustry (industries) {
      let selectIndustryIds = industries.split(',')
      this.loadAllIndustries(selectIndustryIds);
    },
    initPushPreference (push_preference) {
      let selectPreference = []
      selectPreference = push_preference.split(',')
      selectPreference.forEach( preference => {
        if(preference === 'CONFERENCE'){
          this.checkList.push('会议报告')
        }
        if(preference === 'RESEARCH'){
          this.checkList.push('研究报告')
        }
        if(preference === 'TOPIC'){
          this.checkList.push('访谈主题')
        }
      })
    },
    targetPushPreference () {
      let targetStr =''
      this.checkList.forEach( preference=> {
        if (preference ==='会议报告'){
          targetStr = targetStr + 'CONFERENCE,'
        }
        if (preference ==='研究报告'){
          targetStr = targetStr + 'RESEARCH,'
        }
        if (preference ==='访谈主题'){
          targetStr = targetStr + 'TOPIC,'
        }
      })
      if(targetStr.length>0){
        targetStr = targetStr.substring(0,targetStr.length-1)
      }
      return targetStr
    }
  },
  data () {
    return {
      password1: '',
      password2: '',
      nameShow: false,
      defaultName: 'loren Ding',
      genderShow: false,
      defaultGender: '男',
      cityShow: false,
      defaultCity: '合肥',
      phoneShow: false,
      defaultPhone: '13987654321',
      emailShow: false,
      defaultEmail: '888888886@163.com',
      ShowIndustry: false,
      showPush: false,
      checkList: [],
      industries: [],
      selectIndustries: '',
      centerDialogVisible: false,
      centerCityDialogVisible: false,
      fixCenterDialogVisible: false,
      industry_preference_info:'互联网、汽车、医疗',
      genderOptions: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ]
    }
  }
}
</script>

<style>
  .setting-wrapper{
    width: 100%;
    height: 100%;
    background-color: #F6FAFB;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
  }
  .setting-title{
    width: 100%;
    font-size: 28px;
    color: black;
    font-weight: 500;
    opacity: 1;
    margin-top: 35px;
    margin-bottom: 20px;
    text-align: center;
  }
  .setting-content-container{
    width: 100%;
    height: auto;
    min-height: 560px;
    background-color: white;
    border-radius: 8px;
  }
  .setting-title-bar-container{
    width: auto;
    height: 26px;
    display: flex;
    display: -webkit-flex;
    margin-bottom: 6px;
    margin-top: 30px;
    justify-content: left;
  }
  .setting-blue-bar{
    background-color: #4880FF;
    width: 6px;
    height: 18px;
    margin-top: 16px;
  }
  .setting-title-child{
    color:black;
    font-size: 18px;
    margin-left: 14px;
    margin-top: 12px;
    font-weight: bold;
  }
  .setting-btn-save{
    width: 29%;
    height: 48px;
    max-width: 500px;
    line-height: 48px;
    margin-bottom: 46px;
    margin-top: 34px;
    color: white;
    background-color: #1E459D;
    border-radius: 8px;
    font-size: 14px;
    float: left;
    text-align:  center;
  }
  .setting-item-container{
    width: 100%;
    height: auto;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
  }
  .setting-item-key{
    color: #ADADAD;
    font-size: 14px;
    margin-left: 20px;
    margin-top: 24px;
    white-space: nowrap;
  }
  .setting-item-value-gender-sel{
    color: #202224;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 24px;
    padding: 4px 3px;
    width: 130px;
  }
  .setting-item-value{
    color: #202224;
    text-align: left;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 24px;
    padding: 4px 3px;
    min-width: 40px;
  }
  .setting-item-img{
     margin-left: 18px;
     margin-top: 24px;
     margin-right: 13px;
     width: 16px;
     height: 16px;
  }
  .setting-password-value{
    width: 240px !important;
    height: 35px;
    margin-left: 14px;
    margin-top: 24px;
  }
  .setting-info-item-hori-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .setting-info-item-container{
    width: 30%;
    height: auto;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
  }
  .industry-item-unselect{
    border: none;
    border-radius: 4px;
    margin: 0 0 10px 10px;
    background-color: #F6FAFB;
    text-align: center;
    color: #121212;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 28px;
    outline: none;
  }
  .industry-item-selected{
    border: none;
    border-radius: 4px;
    margin: 0 0 10px 10px;
    background-color: #4880FF;
    text-align: center;
    color: white;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 28px;
    outline: none;
  }
  .setting-info-item-container ::v-deep .el-input__inner{
    color: #ADADAD;
  }

</style>
