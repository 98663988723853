// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { tool } from './utils/tool.js'
import '@/styles/index.scss'

import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
import DDConfig from './assets/ddapi' // 钉钉分享  ddapi.js的路径
Vue.prototype.DDConfig = DDConfig;
Vue.use(ElementUI)
let mainListScollTop = 0
Vue.prototype.$mainListScoll = mainListScollTop

Vue.config.productionTip = false
Vue.prototype.tool = tool
/* eslint-disable no-new */
new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
