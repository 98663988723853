<template>
  <div @touchmove.prevent class="vue-popup-success" v-show="showMask">
    <div class="mask-success" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content-success" v-show="showMask">
        <img style="width: 180px;height: 150px;margin-top: 15px;" src="../assets/img/expert_succes.svg" />
        <label style="width: calc(100% - 40px);margin-top: 20px;white-space: pre-wrap;line-height: 18px;font-size: 18px;color: #000000;">{{ title }}</label>
        <label style="width: calc(100% - 40px);margin-top: 10px;white-space: pre-wrap;line-height: 14px;font-size: 14px;color: #525252;">{{ des }}</label>
        <div style="width: calc(100% - 50px);margin-right: 25px;margin-left: 25px;margin-top: 25px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
          <div @click="closeMask()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #FFFFFF;border: 1px solid #1C99FC;height: 40px;width: 100%;background-color: #1C99FC;">知道了</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      value: {}
    },
    data() {
      return {
        showMask: false,
        title: '需求已提交',
        des: '凯盛会尽快为您安排',
        name: ''
      }
    },
    methods: {
      closeMask() {
        this.$emit('closeSuccess')
      }
    },
    mounted(){
        this.showMask = this.value;
    },
    watch:{
        value(newVal){
          this.showMask = newVal;
        }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-success{
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: 0;
  }
  .vue-popup-success {
      .popup-content-success {
          position: absolute;
          height: 320px;
          bottom: calc(50% - 160px);
          left: 15px;
          width: calc(100% - 30px);
          background-color: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      top: 0;
  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.1s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
</style>
