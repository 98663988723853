import {
  setSessionId,
  getSessionId,
  removeSessionId,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  setNickname, 
  getNickname,
  getUserContractType,
  setUserContractType
} from '../../utils/auth.js'
import { resetRouter } from '../../router/index.js'

const getInitState = () => {
  return {
    isLogin: getSessionId() !== null ? true :false,
    userInfo: getUserInfo(),
    sid: getSessionId(),
    nickname: getNickname(),
    contractType: getUserContractType()
  }
}

const state = getInitState()

const getters = {
  userInfo (state) {
    return state.userInfo
  },
  isLogin (state) {
    return state.isLogin
  },
  sid (state) {
    return state.sid
  },
  nickname (state){
    return state.nickname
  },
  contractType (state){
    return state.contractType
  }
}
const actions = {
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
    setUserInfo(data)
  },
  setIsLogin ({ commit }, data) {
    commit('setIsLogin', data)
  },
  setSid ({ commit }, data) {
    commit('setSid', data)
    commit('setIsLogin', true)
    setSessionId(data)
  },
  setNickname ({ commit }, data) {
    commit('setNickname', data)
    setNickname(data)
  },
  setContractType ({ commit }, data) {
    commit('setContractType', data)
    setUserContractType(data)
  },
  logout ({ commit }) {
    removeSessionId()
    removeUserInfo()
    resetRouter()
    commit('RESET_STATE')
  },
  logoutData ({ commit }) {
    removeSessionId()
    removeUserInfo()
    commit('RESET_STATE')
  }
}
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getInitState())
  },
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setIsLogin (state, data) {
    state.isLogin = data
  },
  setSid (state, data) {
    state.sid = data
  },
  setNickname (state, data) {
    state.nickname = data
  },
  setContractType (state, data) {
    state.contractType = data
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
