<template>
  <div>
    会议图谱
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    }
  }
</script>

<style>
</style>