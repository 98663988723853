<template>
  <div class="main-list-view">
    <img style="position: absolute;width: 280px;height: 210px;top: calc(50% - 120px);left: calc(50% - 140px);" v-show="loading && list.length === 0" src="../../assets/img/loading1.gif" />
    <img v-if="list.length > 0" class="img-top" src="../../assets/img/titleback.png" />
    <div v-if="list.length > 0" class="main-list-content">
      <div v-if="tool.isNullOrEmpty(introduce) === true" class="main-wudaoyu">
        <label style="color: #FFFFFF;text-align: right;margin-top: 10px;margin-left: auto;margin-right: 5px;font-size: 14px;margin-bottom: 5px;">
          {{ creator }}
        </label>
      </div>
      <div v-else class="main-daoyu">
        <label style="font-weight: 500;margin-top: 20px;margin-left: 20px;margin-right: 20px;font-size: 18px;color: #313131;text-align: left;line-height: 22px;">
          {{ introduce }}
        </label>
        <label style="color: #8B8B8B;text-align: right;margin-top: 10px;margin-left: auto;margin-right: 20px;font-size: 14px;margin-bottom: 20px;">
          {{ creator }}
        </label>
      </div>
      <div class="main-list-section">
        <div style="padding-bottom: 12px;" v-for="(item,index) in list" :key="index" @click="goIndex(item)">
          <div style="background-color: #FFFFFF;box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);border-radius: 10px;opacity: 1;flex-direction: column;display: flex;width: 100%;padding-bottom: 15px;" >
              <div class="cell-item-l">
                <img style="height: 18px;padding-top: 4px;" :src="getImageIcon(item)"/>
                <label style="padding-left: 4px;font-size: 16px;color: #5A5A5A;font-weight: 600;text-align: left;line-height: 20px;">{{ item.title }}</label>
              </div>
              <div style="margin-top: 12px;background-color: #EAF2F5;height: 1px;width: 100%;margin-bottom: 10px;"></div>
              <div v-if="tool.isNullOrEmpty(item.charge) === false || tool.isShowConferenceTime(item.start_time) === true" style="width: 100%;display: flex;flex-direction: column;margin-bottom: 10px;">
                <label v-if="item.type == 'RESEARCH'" style="font-size: 12px;color: #333333;text-align: left;margin-left: 15px;margin-right: 15px;"><span style="font-weight: 800;">时间：</span>{{ tool.getDateFormat('y/m/d',item.publish_time) }}</label>
                <label v-if="tool.isShowConferenceTime(item.start_time) === true" style="font-size: 12px;color: #333333;text-align: left;margin-left: 15px;margin-right: 15px;"><span style="font-weight: 800;">时间：</span>{{ tool.getDateFormat('y/m/d h:i',item.start_time) + '~' + tool.getDateFormat('y/m/d h:i',item.end_time) }}</label>
                <label v-if="tool.isNullOrEmpty(item.charge) === false" style="font-size: 12px;color: #333333;text-align: left;margin-left: 15px;margin-right: 15px;"><span style="font-weight: 800;">费用：</span>{{ item.charge }}</label>
              </div>
              <label v-for="(subItem,subindex) in item.consultant_infos" :key='subindex' style="font-size: 14px;color: #333333;text-align: left;margin-left: 15px;margin-right: 15px;">
                {{ subItem }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <a v-if="list.length > 0" class="footer-view-ad" href="http://iksh.com">
        <img style="width: 168px;height: 51px;margin-top: 145px;" src="../../assets/img/listbottomicon.png" />
      </a>
    </div>
  </div>
</template>
<script>
  import detailApi from '../../api/un_login_detail.js'
  import sharesdk from '../../utils/wxshare.js'
  export default {
    data () {
      return {
        list:[],
        identifier: this.$route.query.identifier,
        introduce: '',
        creator: '',
        saveY: 0,
        loading: true
      }
    },
    mounted() {
      this.identifier = this.$route.query.identifier
      this.getList()
    },
    activated() {
      this.$nextTick(() => {
        window.scrollTo(0, this.$mainListScoll)
        console.log(this.$mainListScoll, 2)
      })
    },
    beforeRouteLeave(to, from, next) {
      console.log(to,from)
      this.$mainListScoll = document.documentElement.scrollTop || document.body.scrollTop
      console.log(this.$mainListScoll, 1)
      next()
    },
    methods: {
      goIndex (val) {
        if (this.tool.isNullOrEmpty(val.link) === false) {
          this.$router.push({ path:'/visit', query: this.getQuery(val.link)})
        }
      },
      getList () {
        var data = {}
        data.identifier = this.identifier
        this.loading = true
        detailApi.getShareList(data).then( res => {
          this.loading = false
          if (res.code === '0000') {
            this.introduce = res.data.introduce
            this.creator = '—— 凯盛 ' + res.data.creator + ' 为您提供'
            this.list = res.data.contents
            let url = location.href.split('#')[0]
            var dess = ""
            var titleStr = ""
            if (this.tool.isNullOrEmpty(this.introduce) === false) {
              dess = this.introduce
            } else {
              dess = '由凯盛项目人员' + res.data.creator + '为您整理汇总。'
            }
            if (this.tool.isNullOrEmpty(res.data.title) === false) {
              titleStr = res.data.title
            }else {
              titleStr = '凯盛融英内容推荐'
            }
            var img = 'http://cdn.capvision.cn/media/conference/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210220095321.png'
            let obj = {
              title: titleStr,
              des: dess,
              linkurl: location.href,
              img: img
            }
            this.DDConfig.ddShowMenu(titleStr,img,location.href,dess)
            sharesdk.getJSSDK(url,obj)
          }
        })
      },
      getQuery (urlStr) {
        if (typeof urlStr === 'undefined') {
          var url = decodeURI(location.search)
        }else {
          url = "?" + urlStr.split("?")[1]
        }
        var theRequest = new Object()
        theRequest.from_share_list = '1'
        if (url.indexOf('?') !== -1) {
          var str = url.substr(1)
          var strs = str.split("&")
          for (var i = 0; i< strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split("=")[1])
          }
        }
        return theRequest
      },
      getImageIcon(val) {
        if (val.type === 'RESEARCH') {
          return require('../../assets/img/research_list_icon.png')
        }else if (val.type === 'TOPIC') {
          return require('../../assets/img/topic_list_icon.png')
        }else if (val.type === 'CONFERENCE') {
          if (val.conference_type === 'VIDEO') {
            return require('../../assets/img/video_list_icon.png')
          }else if (val.conference_type === 'TELEPHONE') {
            return require('../../assets/img/phone_list_icon.png')
          }else if (val.conference_type === 'OFFLINE') {
            return require('../../assets/img/offline_list_icon.png')
          }else if (val.conference_type === 'VIDEO_TRAINING') {
            return require('../../assets/img/video_train.png')
          }else if (val.conference_type === 'OFFLINE_TRAINING') {
            return require('../../assets/img/offline_train.png')
          }
        }
      }
    }
  }
</script>

<style>
  .main-list-view {
    margin: 0 auto;
    padding: 0;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
  .img-top {
    z-index: -100;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    margin: 0 auto;
  }
  .main-list-content {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-wudaoyu {
    width: calc(100% - 30px);
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
  }
  .main-daoyu {
    width: calc(100% - 30px);
    background-color: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 10px;
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
  }
  .main-list-section {
    margin-top: 12px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
  }
  .main-section-title {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cell-item-l {
    margin-top: 12px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
  }
  .footer-view-ad {
    z-index: -1001;
    background: linear-gradient(205deg, #047CD5 0%, #0B3EB1 100%);
    opacity: 1;
    height: 260px;
    width: 100%;
    margin-top: -100px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
