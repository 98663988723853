<template>
  <div>
<!--    <div class="mobile-app-download">
      <img style="width: 42px;height: 42px;margin-left: 18px;" src="../../assets/img/appicon.png" />
      <label style="color: #1E459D;font-size: 12px;margin-left: 10px;">下载凯盛专家APP<br/>查看更多精品会议</label>
      <a href="http://iksh.com" style="line-height: 35px;height: 35px;border-radius: 4px;width: 95px;color: #FFFFFF;background-color: #1E459D;margin-right: 18px;margin-left: auto;font-size: 14px;">下载</a>
    </div> -->
    <img style="position: absolute;width: 280px;height: 210px;top: calc(50% - 120px);left: calc(50% - 140px);" v-show="loading && tool.isNullOrEmpty(mainModel.conference_name)" src="../../assets/img/loading1.gif" />
    <div v-if="mainModel != null && mainModel != undefined"  class="main-content">
      <p v-if="mainModel.conference_name" style="font-size: 16px;color: #242424;margin-top: 25px;margin-left: 15px;margin-right: 15px;font-weight: bold;">{{ (mainModel.app_status === 'PREVIEW' ? '【会议预告】' : '【凯盛会议】') + mainModel.conference_name }}</p>
      <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in mainModel.contentArray" :key="indexModel+1">
         <div class="title-nav-view">
           <div class="flag-line"></div>
           <label class="nav-title">{{ itemModel.title }}</label>
         </div>
         <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
            <label
             v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
            <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 30px);margin-left: 15px;margin-bottom: 10px;"  />
          </div>
<!--         <label
           style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ itemModel.content }}</label> -->
       </div>
       <div class="download-conference-view">
         <downloadView :tipString='getDownTip'></downloadView>
       </div>
    </div>
    <div v-if="isHaveOption" class="main-footer-view">
      <slot v-if="mainModel.app_status === 'PREVIEW'">
        <slot v-if="isOngoing">
          <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要</button>
        </slot>
        <slot v-else>
          <!-- <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;margin-right: 10px;">预约纪要</button> -->
          <button @click="signUpMeet('CONFERENCE_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;margin-left: 10px;">报名参会</button>
        </slot>
      </slot>
      <slot v-else-if="mainModel.app_status === 'COMPLETE'">
          <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">获取纪要</button>
      </slot>
    </div>
    <optionview :noConference="false" :isComplete="mainModel.app_status === 'COMPLETE' ? true : false" resource_type='CONFERENCE' v-model="showLogin" @dissmiss='showLogin = false' @unloginCreateOrder='unloginCreateOrder' @loginSuccessEvent='loginSuccessEvent'></optionview>
    <chargeActionsheet :chargeString="charge" :eventString="event" v-model="showCharge" @cancelEvent='cancelEvent' @confirmEvent='confirmEvent'></chargeActionsheet>
  </div>
</template>

<script>
  import detailApi from '../../api/un_login_detail.js'
  import optionview from './mobile_option_view.vue'
  import conference from '../../api/conference.js'
  import sharesdk from '../../utils/wxshare.js'
  import downloadView from './download_app_tip.vue'
  import chargeActionsheet from './conference_charge_actionsheet.vue'
  import watermark from '../../utils/watermark.js'
  export default {
    data () {
      return {
        mainModel: {
          my_task: {
            status: ''
          }
        },
        token: "",
        showLogin:false,
        type: "",
        loading: true,
        showCharge: false,
        charge: '',
        event: '',
        requestMoming: false
      }
    },
    computed: {
      waterContent: function() {
        var watermarkString = ''
        if (this.tool.isNullOrEmpty(this.$store.getters.chinesename) === false && this.$store.getters.client) {
          watermarkString = `仅供凯盛客户${this.$store.getters.client.chinesename}-${this.$store.getters.chinesename}使用`
        }
        return watermarkString
      },
      isOngoing: function () {
        return this.tool.isSignUpStop(this.mainModel.start_time)
      },
      defaultEmail: function () {
        if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
          return this.$store.getters.email
        }else if(this.tool.isNullOrEmpty(this.$store.getters.app_email) === false) {
          return this.$store.getters.app_email
        }else {
          return ''
        }
      },
      isHaveOption: function () {
        if (this.mainModel.app_status === 'PREVIEW' || this.mainModel.app_status === 'COMPLETE') {
          return true
        }else{
          return false
        }
      },
      isMailGG: function () {
        if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
          return false
        }
        return true
      },
      isVip: function () {
        if (this.$store.getters.contractType === 'GRADE_PAY' ||
        this.$store.getters.contractType === 'MONTHLY' ||
        this.$store.getters.contractType === 'MONTHLY_TRAIL'||
        this.$store.getters.contractType === 'FREE'
        ) {
          return true
        }
        return false
      },
      isFirstAndMonthClient: function () {
        if (this.$store.getters.client.has_monthly_conference_plan === true) {
          return true
        }
        return false
      },
      getDownTip: function () {
        return '了解更多凯盛会议，在线查看会议纪要，\n请使用凯盛专家APP'
      }
    },
    components: {
      optionview,
      downloadView,
      chargeActionsheet
    },
    mounted() {
      this.token = this.$route.query.token
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (this.tool.isNullOrEmpty(isMobile)) {
        this.$router.replace({ path:'/conference/detail', query:{token:this.token}})
      }
      this.getConferenceDetail(false)
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.backChange, false)
      }
      if (this.waterContent) {
        watermark.set(this.waterContent)
      }
    },
    destroyed() {
      window.removeEventListener('popstate', this.backChange, false)
      watermark.remove()
    },
    methods: {
      cancelEvent () {
        this.showCharge = false
      },
      confirmEvent () {
        this.showCharge = false
        if (this.event === '报名参会'){
          this.sendMomOrSignUp('CONFERENCE_APPLY')
        }else {
          this.sendMomOrSignUp('SUMMARY_APPLY')
        }
      },
      backChange () {
        this.$router.back()
      },
      unloginCreateOrder (val) {
        this.showLogin = false
        var data = {}
        data.resource_id = this.mainModel.id
        data.resource_type = 'CONFERENCE'
        data.type = this.type
        data.mobile = val
        data.origin_channel = 'H5'
        detailApi.createOrder(data).then( res=> {
          if (res.code === '0000') {
            if(res.data.status === 'PENDING') {
              this.$router.replace({ path:'/success_tips', query:{type: 'conference'}})
            }
          }
        })
      },
      loginSuccessEvent () {
        this.showLogin = false
        this.getConferenceDetail(true)
      },
      buySummary (val) {
        this.signUpMeet(val)
      },
      signUpMeet (val) {
        if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
          if (this.mainModel.my_task && this.mainModel.my_task.status) {
            if (this.mainModel.my_task.status == 'CONFERENCE_APPLY'
            ||this.mainModel.my_task.status == 'SUMMARY_APPLY'
            ||this.mainModel.my_task.status == 'CONFERENCE_APPLY_SUCCEEDED'
            ||this.mainModel.my_task.status == 'SUMMARY_BOUGHT') {
              var isStatus = this.mainModel.app_status === 'PREVIEW' ? true : false
              this.$router.replace({ path:'/success/send/email',query:{id :this.mainModel.my_task.id,status: this.mainModel.my_task.status,defalutEmail: this.defaultEmail,isPreview: isStatus,isMailGG: this.isMailGG}})
            }else {
              this.$router.replace({ path:'/error_tips'})
            }
          } else {
            this.checkSendMom(val)
            // if (this.mainModel.app_status === 'COMPLETE') {//都要判断
            //   var data = {}
            //   data.conference_id = this.mainModel.id
            //   conference.checkMomSend(data).then( res=> {
            //     if (res.code === '0000') {
            //       if (res.data.check_result === true) {
            //         this.checkSendMom(val)
            //       }else {
            //         this.$message(res.data.message)
            //       }
            //     }
            //   })
            // }else{
            //   this.checkSendMom(val)
            // }
          }
        }else {
          this.type = val
          this.showLogin = true
        }
      },
      checkSendMom (val) {
        if (this.isVip === false) {
          if (val === 'MOM_APPLIED'){
            if (this.mainModel.app_status === 'COMPLETE') {
              this.event = '获取纪要'
              this.charge = `${this.mainModel.conference_fee}小时`
            }else{
              this.event = '预约纪要'
              this.charge = `${this.mainModel.conference_fee}小时`
            }
          }else if(val === 'CONFERENCE_APPLY'){
            this.event = '报名参会'
            this.charge = `${this.mainModel.conference_fee}小时`
          }
          this.showCharge = true
          return
        }
        this.sendMomOrSignUp(val)
      },
      sendMomOrSignUp (val) {
        if (this.requestMoming) {
          return
        }
        var data = {}
        // data.conference_id = this.mainModel.id
        // data.status = val
        // if (this.$store.getters.uid) {
        //   data.contact_id = this.$store.getters.uid
        // }
        // data.origin_channel = 'H5'
        data.conference_publish_id = this.mainModel.id
        data.status = val
        data.origin = 'H5'
        data.pay_type = this.mainModel.pay_type
        this.requestMoming = true
        conference.applyConference(data).then( res=> {
          this.requestMoming = false
          if (res.code === '0000') {
            if (res.data !== null) {
              this.mainModel.my_task = res.data
              if (this.mainModel.my_task) {
                if (this.mainModel.my_task.status == 'CONFERENCE_APPLY'
                ||this.mainModel.my_task.status == 'SUMMARY_APPLY'
                ||this.mainModel.my_task.status == 'CONFERENCE_APPLY_SUCCEEDED'
                ||this.mainModel.my_task.status == 'SUMMARY_BOUGHT') {
                  var isStatus = this.mainModel.app_status === 'PREVIEW' ? true : false
                  this.$router.replace({ path:'/success/send/email',query:{id :this.mainModel.my_task.id,status: this.mainModel.my_task.status,defalutEmail: this.defaultEmail,isPreview: isStatus,isMailGG: this.isMailGG}})
                }else {
                  this.$router.replace({ path:'/error_tips'})
                }
              }
            }
          }else {
              this.$router.replace({ path:'/error_tips'})
          }
        })
      },
      //登录之后刷新taskmodel
      getConferenceOne (val) {
        if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false && this.mainModel.id) {
          var data = {}
          data.id = this.mainModel.id
          data.extra = 'contact_task_for_contact,materials,conference_summary'
          conference.getConference(data).then( res=> {
            if (res.code == '0000') {
              if (res.data.contact_task_for_contact) {
                this.mainModel.my_task = res.data.contact_task_for_contact
              }
              if (val) {
                this.signUpMeet(this.type)
              }
            }
          })
        }
      },
      getConferenceDetail (val) {
        var data = {}
        data.token = this.token
        this.loading = true
        detailApi.getConferenceToken(data).then( res=> {
          this.loading = false
          if (res.code === '0000') {
            this.mainModel = res.data
            if (val === false) {
              let url = location.href.split('#')[0]
              let shareUrl = url + '#/visit?token=' + this.token
              var img = 'http://cdn.capvision.cn/media/conference/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210220095321.png'
              let obj = {
                title: '凯盛融英会议主题',
                des: this.mainModel.title,
                linkurl: shareUrl,
                img: img
              }
              sharesdk.getJSSDK(url,obj)
              this.DDConfig.ddShowMenu('凯盛融英会议主题',img,shareUrl,this.mainModel.title)
            }
            this.getConferenceOne(val)
            this.mainModel.contentArray = []
            var contents = res.data.content.split('@@@')
            contents.forEach((item) => {
              if (this.tool.isNullOrEmpty(item) === false) {
                if (item.indexOf('###') !== -1) {
                  var itemModel = {}
                  itemModel.title = item.slice(0,item.indexOf('###'))
                  itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                  itemModel.imageTextArray = []

                  var imgTextArray = itemModel.content.split('<img>')
                  imgTextArray.forEach( imgText => {
                    var imgTextModel = {}
                    if (imgText.indexOf('</img>') !== -1) {
                      var  subImgTextArray = imgText.split('</img>')
                      if (subImgTextArray.length === 2) {
                        for (var i = 0;i < subImgTextArray.length;i++) {
                          var imgSubTextModel = {}
                          if (i === 0) {
                            imgSubTextModel.img = subImgTextArray[0]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }else {
                            imgSubTextModel.text = subImgTextArray[1]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }
                        }
                      }else {
                        imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    }else{
                      imgTextModel.text = imgText
                      itemModel.imageTextArray.push(imgTextModel)
                    }
                  })
                  this.mainModel.contentArray.push(itemModel)
                }
              }
            })
          }
        })
      }
    }
  }
</script>

<style>
  .mobile-app-download {
    height: 55px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
  }
  .main-content {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding-bottom: calc(88px + env(safe-area-inset-bottom));
  }
  .title-nav-view {
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flag-line {
    margin-left: 0px;
    background-color: #1E459D;
    width: 4px;
    height: 18px;
  }
  .nav-title {
    color: #242424;
    font-size: 16px;
    font-weight: bold;
    margin-left: 14px;
  }
  .main-footer-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 68px;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
  }
  .download-conference-view {
    margin-top: 20px;
    width: 100%;
  }
</style>
