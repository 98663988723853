import { HttpNoErrorService, HttpService } from '../utils/request.js'

export default {
  async getConferenceList (data) {
    return HttpNoErrorService({
      url: '/web/conference/list',
      method: 'post',
      data
    })
  },
  async getConference (data) {
    return HttpNoErrorService({
      url: '/web/conference/one',
      method: 'post',
      data
    })
  },
  async getMomDetail (data) {
    return HttpNoErrorService({
      url: '/web/conference/one_mom',
      method: 'post',
      data
    })
  },
  async checkAmClient (data) {
    return HttpNoErrorService({
      url: '/web/conference/client_am_team',
      method: 'get',
      params: data
    })
  },
  async getIndustries () {
    return HttpNoErrorService({
      url: '/misc/industries',
      method: 'post'
    })
  },
  async getZeroIndustries () {
    return HttpNoErrorService({
      url: '/misc/zero_industries',
      method: 'post'
    })
  },
  async searchList (data) {
    return HttpNoErrorService({
		url: '/client_contacts/resources/search',
		method: 'post',
		data
	})
  },
  async confenenceNum () {
    return HttpNoErrorService({
      url: '/conferences/statistics',
      method: 'post'
    })
  },
  async researchNum () {
    return HttpNoErrorService({
      url: '/researches/statistics',
      method: 'post'
    })
  },
  async topicNum () {
    return HttpNoErrorService({
      url: '/topics/statistics',
      method: 'post'
    })
  },
  async createConference (data) {
    return HttpService({
      url: '/client_contacts/conferences/create',
      method: 'post',
      data
    })
  },
  async updateConference (data) {
    return HttpService({
      url: '/client_contacts/conferences/patch',
      method: 'post',
      data
    })
  },
  async cancelConference (data) {
    return HttpService({
      url: '/client_contacts/conferences/cancel',
      method: 'post',
      data
    })
  },
  async applyConference (data) {
    return HttpService({
      url: '/web/conference/contact/apply',
      method: 'post',
      data
    })
  },
  async cancelContactConference (data) {
    return HttpService({
      url: '/web/conference/contact/cancel',
      method: 'post',
      data
    })
  },
  async requireMom (data) {
    return HttpService({
      url: '/conferences/tasks/require_mom',
      method: 'post',
      data
    })
  },
  async confirmApplyEmail (data) {
    return HttpService({
      url: '/conferences/tasks/confirm_apply_email',
      method: 'post',
      data
    })
  },
  async checkMomSend (data) {
    return HttpService({
      url: '/conferences/tasks/check_mom_send',
      method: 'post',
      data
    })
  },
  async sendMom (data) {
    return HttpService({
      url: '/web/conference/send_mom_mail',
      method: 'post',
      data
    })
  },
  async tagAndIndustries (data) {
    return HttpNoErrorService({
      url: '/conferences/tags_and_industries',
      method: 'post',
      data
    })
  },
  async searchTags (data) {
    return HttpService({
      url: '/client_contacts/resources/search_tag',
      method: 'post',
      data
    })
  }
}
