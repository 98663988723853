import { HttpNoErrorService, HttpService, HttpCheckNoErrorService } from '../utils/request.js'
import store from '../store/index.js'
export default {
  async getDetail (data) {
    var sendData = data
    if (store.getters.fromShareList === true) {
      sendData.from_share_list = 1
    }else {
      sendData.from_share_list = 0
    }
    return HttpCheckNoErrorService({
      url: '/visit',
      method: 'get',
      params: sendData
    })
  },
  async getConferenceToken (data) {
    return HttpCheckNoErrorService({
      url: '/web/conference/one_by_visitor_link',
      method: 'get',
      params: data
    })
  },
  async checkSid (data) {
    return HttpCheckNoErrorService({
      url: '/misc/check_sid',
      method: 'get',
      params: data,
    })
  },
  async createOrder (data) {
    return HttpService({
      url: '/order/create',
      method: 'post',
      data
    })
  },
  async getShareList (data) {
    return HttpNoErrorService({
      url: '/share/visit',
      method: 'get',
      params: data
    })
  }
}
