<template>
  <div style="width: 100%;height: 100%;margin: 0 auto;">
    <div style="position: absolute;top: 35%;left: 50%;transform: translate(-50%, -55%);width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <img style="width: 110px;height: 110px;" src="../../assets/img/successicon.png" />
      <p style="margin-top: 15px;color: #1E9D40;font-size: 22px;font-weight: bold;text-align: center;">提交成功</p>
      <p style="margin-top: 15px;color: #606060;font-size: 16px;text-align: center;">请求提交成功，凯盛会尽快与您联系。</p>
    </div>
    <div class="download-view">
      <downloadView :tipString='getTips'></downloadView>
    </div>
  </div>
</template>

<script>
	import downloadView from './download_app_tip.vue'
	export default {
		components: {
      downloadView
		},
    data () {
      return {
        type: ''
      }
    },
    mounted() {
      this.type = this.$route.query.type
    },
    computed: {
      getTips: function () {
        if (this.type === 'topic') {
          return '免费查看2000条行业主题提纲和专家介绍\n请使用凯盛专家APP'
        }else {
          return '了解更多凯盛会议，在线查看会议纪要，\n请使用凯盛专家APP'
        }
      }
    }
	}
</script>

<style>
  .download-view {
    position: absolute;
    width: 100%;
    bottom: env(safe-area-inset-bottom);
    padding-bottom: 50px;
  }
</style>
