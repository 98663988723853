<template>
  <div>
    <div class="expert-top-view">
      <p style="margin-left: 15px;margin-top: 12px;text-align: left;white-space: pre-wrap;line-height: 24px;font-weight: 800;width: calc(100% - 30px);font-size: 16px;color: #1D253C;">{{ info.introduce }}</p>
      <p style="margin-top: 0px;margin-bottom: 12px;text-align: right;color: #AAAAAA;height: 12px;font-size: 14px;margin-left: auto;margin-right: 15px;line-height: 12px;">—— 凯盛 {{ info.creator }} 为您提供</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        default: ()=>{}
      }
    }
  }
</script>

<style>
  .expert-top-view {
    margin: 0px 15px 12px 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(62, 42, 90, 0.1);
    border-radius: 8px;
  }
</style>
