<template>
  <div></div>
</template>

<script>
  import { setFullgoalToken } from '../../utils/auth.js'
  export default {
    data() {
      return {
        token: this.$route.query.token,
        tsid: this.$route.query.tsid
      }
    },
    mounted() {
      this.token = this.$route.query.token
      this.tsid = this.$route.query.tsid
      if (this.token && this.tsid) {
        setFullgoalToken(this.token)
        let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (isMobile) {
          this.$router.replace({ path:'/fullgoalConferenceH5', query:{tsid:this.tsid}})
        }else {
          this.$router.replace({ path:'/fullgoalConference', query:{tsid:this.tsid}})
        }
      }
    }
  }
</script>

<style>
</style>
