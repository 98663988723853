import jsonpGet from 'jsonp-get'
import wx from 'weixin-js-sdk'
function getJSSDK(shareUrl,dataForWeixin) {
  var resUrl = 'https://wx.capvision.cn/wx/share'
  if (shareUrl.indexOf('https') === -1) {
    resUrl = 'http://wx.capvision.cn/wx/share'
  }
  jsonpGet(resUrl,{ url: shareUrl}).then( res=> {
    wx.config({
      debug: false,
      appId: 'wx39670c44d62fb9d3',
      jsApiList: [
        'updateTimelineShareData',
        'updateAppMessageShareData'
      ],
      ...res
    })
    wx.ready(function () {
      var title = dataForWeixin.title
      var desc = dataForWeixin.des
      let pos = desc.indexOf(title)
      if (pos !== -1) {
        desc = desc.slice(pos+title.length+1)
      }
      wx.updateAppMessageShareData({
        title: title,
        desc: desc,
        link: dataForWeixin.linkurl,
        imgUrl: dataForWeixin.img,
        success: function success(res) {
          console.log('已分享' + res)
        },
        cancel: function cancel(res) {
          console.log('已取消' + res)
        },
        fail: function fail(res) {
          console.log(JSON.stringify(res))
        }
      })
      wx.updateTimelineShareData({
        title: dataForWeixin.des,
        desc: dataForWeixin.des,
        link: dataForWeixin.linkurl,
        imgUrl: dataForWeixin.img,
        success: function success(res) {
          console.log('已分享' + res)
        },
        cancel: function cancel(res) {
          console.log('已取消' + res)
        },
        fail: function fail(res) {
          console.log(JSON.stringify(res))
        }
      })
      wx.error(function (res) {
        console.log('微信验证失败' + res)
      })
    })
  })
}

export default {
  getJSSDK
}
