import Cookies from 'js-cookie'

const SessionIdKey = 'sid'
const UserInfoKey = 'userInfo'
const NicknameKey = 'nickname'
const UserContractType = 'contractType'
const fullgoalToken = 'fullgoalToken'

export function getFullgoalToken() {
  return Cookies.get(fullgoalToken)
}

export function setFullgoalToken(token) {
  return Cookies.set(fullgoalToken, token, { expires: 14 })
}

export function getUserContractType() {
  return Cookies.get(UserContractType)
}

export function setUserContractType(type) {
  return Cookies.set(UserContractType, type, { expires: 14 })
}

export function getSessionId() {
  return Cookies.get(SessionIdKey)
}

export function setSessionId(sid) {
  return Cookies.set(SessionIdKey, sid, { expires: 14 })
}

export function setNickname(nickname) {
  return Cookies.set(NicknameKey, nickname, { expires: 14})
}

export function getNickname() {
  return Cookies.get(NicknameKey);
}

export function removeSessionId() {
  return Cookies.remove(SessionIdKey)
}

export function setUserInfo(info) {
  const data = {
    id: info.id,
    chinesename: info.chinesename,
    client_id: info.client_id,
    email: info.email,
    firstname: info.firstname,
    lastname: info.lastname,
    mobile: info.mobile,
    client: info.client,
    app_email: info.app_email
  }
  Cookies.set(UserInfoKey, data, { expires: 14 })
}

export function getUserInfo() {
  return Cookies.get(UserInfoKey) ? JSON.parse(Cookies.get(UserInfoKey)) : {}
}

export function removeUserInfo() {
  return Cookies.remove(UserInfoKey)
}
