<template>
	<div class="detail-conference-cell">
    <div :class="isSearch?'normal-cell-c':'normal-cell'" @click="openEvent" v-show="mainModel.isOpen === false">
      <img style="height: 20px;margin-left: 15px;margin-right: 0px;" v-if="mainModel.app_status === 'PREVIEW' && isOngoing === false" src="../assets/img/preview.svg" />
      <label style="margin-left: 15px;border-radius: 4px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
      <label :class="getIconType() != '' ? 'cell-gap-title' : 'cell-title'" v-html="getTitleBoldT(mainModel.conference_name)"></label>
      <div class="cell-right-option">
        <label class="time-label">{{ tool.getTimeYMD(this.mainModel.start_time) }}</label>
        <img v-if="tool.isNullOrEmpty(statusString) === false" :src="statusString" style="height: 1.5rem;margin-right: 12px;" />
      </div>
    </div>
    <el-collapse-transition>
      <div id="detail-cell" v-show="mainModel.isOpen === true">
        <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 24px;margin-left: 20px;margin-right: 20px;">
          <label style="font-size: 14px;padding: 2px 5px;border-radius: 4px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
          <div v-html="getTitleBold(mainModel.conference_name)" @click="closeEvent" style="font-size: 18px;line-height: 20px;color: #272727;cursor: pointer;cursor: pointer;"></div>
        </div>
        <div class="middle-view">
          <div class="left-view">
            <slot v-if="mainModel.consultants && mainModel.consultants.length > 0">
              <label @click="gotoDetail(false)" style="cursor: pointer;margin-left: 20px;margin-right: 20px;text-align: left;color: #000000;font-size: 14px;font-weight: 700;margin-right: 20px;line-height: 14px;height: 14px;">{{'【特邀专家】共' + mainModel.consultants.length + '位'}}</label>
              <div v-if="mainModel.consultants[currentPage-1] !== null" class="consultant-info-view">
                <label @click="gotoDetail(false)" style="cursor: pointer;color: #272727;font-size: 12px;line-height: 20px;text-align: left;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;"><span style="font-weight: 500;">公司：</span>{{ mainModel.consultants[currentPage-1].consultant_company }}</label>
                <label @click="gotoDetail(false)" style="cursor: pointer;color: #272727;font-size: 12px;line-height: 20px;text-align: left;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;"><span style="font-weight: 500;">职位：</span>{{ mainModel.consultants[currentPage-1].consultant_position }}</label>
                <label @click="gotoDetail(false)" style="cursor: pointer;color: #272727;font-size: 12px;line-height: 20px;text-align: left;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;" :class="mainModel.consultants.length > 1 ? 'three-lines' : 'five-lines'"><span style="font-weight: 500;">背景：</span>{{ mainModel.consultants[currentPage-1].consultant_background }}</label>
              </div>
              <el-pagination v-if="mainModel.consultants.length > 1"
              small
              background
              layout="pager" :page-size="1" :total="mainModel.consultants.length" :current-page="currentPage" @current-change="currentChange">
              </el-pagination>
            </slot>
          </div>
          <div style="margin-top: 10px;margin-bottom: 0px;width: 0.5px;background-color: #F5F5F5;"></div>
          <div class="right-view">
            <div @click="gotoDetail(false)" style="cursor: pointer;margin-left: 20px;display: flex;flex-direction: row;align-items: center;align-items: center;">
              <img style="height: 14px;width: 14px;" src="../assets/img/conference_time.png"  />
              <label style="margin-left: 8px;height: 14px;font-size: 14px;color: #000000;font-weight: 700;text-align: left;line-height: 14px;">会议时间</label>
            </div>
            <label @click="gotoDetail(false)" style="cursor: pointer;margin-top: 10px;margin-left: 20px;margin-right: 20px;color: #272727;text-align: left;font-size: 12px;height: 20px;">{{ meetTime }}</label>
            <div @click="gotoDetail(false)" style="cursor: pointer;margin-top: 10px;margin-left: 20px;display: flex;flex-direction: row;align-items: center;align-items: center;">
              <img style="height: 14px;width: 12px;" src="../assets/img/conference_back.png"/>
              <label style="margin-left: 8px;height: 14px;font-size: 14px;color: #000000;font-weight: 700;text-align: left;line-height: 14px;">{{ conferenceOutLine() == '' ? '会议背景' : '会议提纲' }}</label>
            </div>
            <label @click="gotoDetail(false)" style="cursor: pointer;margin-left: 20px;margin-right: 20px;font-size: 12px;line-height: 20px;text-align: left;color: #272727;margin-top: 10px;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;" :class="conferenceOutLine() == '' ? '' : 'huan_hang'">{{ conferenceOutLine() == '' ? mainModel.conference_background : conferenceOutLine() }}</label>
            <div v-if="mainModel.all_tags && mainModel.all_tags.length > 0" class="tags_view">
              <div @click="goToSearch(tag)" class="tags_view_label" v-for="(tag,tagIndex) in mainModel.all_tags" :key="tagIndex">
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
        <div class="footer-option-view">
          <label style="color: #FFA000;font-size: 12px;margin-left: 20px;text-align: left;line-height: 15px;margin-right: 20px;">{{ listChargeInfo }}</label>
          <div style="display: flex;flex-direction: row-reverse;align-items: center;margin-right: 0px;margin-left: auto;">
            <slot v-if="mainModel.app_status === 'PREVIEW'">
              <slot v-if="isOngoing">
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button ref='requestingBtn' @mouseenter="requestingBtnMouseenter" @mouseleave="requestingBtnMouseleave" @click="cancelConferenceRequest" v-if="isMomApplied === true || isConferenceApplied" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;width: 150px;outline: none;border: none;">{{ btnRequestingmom }}</button>
                <slot v-else-if="isMomApproved === true || isConferenceApproved === true">
                  <slot v-if="isEnd">
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消预约</button>
                    <button @click="popMomApprovedAppoint()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">预约纪要成功</button>
                  </slot>
                  <slot v-else>
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                    <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                  </slot>
                </slot>
                <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published">
                  <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                  <button @click="gotoDetail(true)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
                </slot>
                <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published == false">
                  <slot v-if="isEnd">
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消预约</button>
                    <button @click="popMomApprovedAppoint()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">预约纪要成功</button>
                  </slot>
                  <slot v-else>
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                    <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                  </slot>
                </slot>
                <slot v-else-if="isRejected === true">
                  <div style="margin-right: 15px;font-size: 14px;color: #4880FF;">报名参会或预约纪要失败  <span @click="feedback" style="color: #FFA000;">意见反馈</span></div>
                </slot>
                <button v-else @click="buySummary()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">预约纪要</button>
              </slot>
              <slot v-else>
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button ref='signUpBtn' @mouseenter="signUpBtnMouseenter" @mouseleave="signUpBtnMouseleave" @click="cancelConferenceRequest" v-if="isConferenceApplied === true" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;width: 150px;outline: none;border: none;">{{ btnSignuping }}</button>
                <button ref='requestingBtn' @mouseenter="requestingBtnMouseenter" @mouseleave="requestingBtnMouseleave" @click="cancelConferenceRequest" v-else-if="isMomApplied === true" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;width: 150px;outline: none;border: none;">{{ btnRequestingmom }}</button>
                <slot v-else-if="isConferenceApproved === true">
                  <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                  <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                </slot>
                <slot v-else-if="isMomApproved === true">
                  <slot v-if="isEnd">
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消预约</button>
                    <button @click="popMomApprovedAppoint()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">预约纪要成功</button>
                  </slot>
                  <slot v-else>
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                    <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                  </slot>
                </slot>
                <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published">
                  <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                  <button @click="gotoDetail(true)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
                </slot>
                <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published == false">
                  <slot v-if="isEnd">
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消预约</button>
                    <button @click="popMomApprovedAppoint()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">预约纪要成功</button>
                  </slot>
                  <slot v-else>
                    <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                    <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                  </slot>
                </slot>
                <slot v-else-if="isRejected === true">
                  <div style="margin-right: 15px;font-size: 14px;color: #4880FF;">  报名参会或预约纪要失败<span @click="feedback" style="color: #FFA000;cursor: pointer;">意见反馈</span></div>
                </slot>
                <slot v-else>
                  <button @click="signUpMeet()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">报名参会</button>
                  <!-- <button @click="buySummary()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">预约纪要</button> -->
                </slot>
              </slot>
            </slot>
            <slot v-else-if="mainModel.app_status === 'COMPLETE'">
              <slot v-if="isConferenceApproved || isMomApproved">
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button @click="gotoDetail(true)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
              </slot>
              <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published">
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button @click="gotoDetail(true)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
              </slot>
              <slot v-else-if="mainModel.my_task.status == 'COMPLETED' && mainModel.is_mom_file_published == false">
                <slot v-if="isEnd">
                  <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消预约</button>
                  <button @click="popMomApprovedAppoint()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">预约纪要成功</button>
                </slot>
                <slot v-else>
                  <button @click="cancelConference()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">取消报名</button>
                  <button @click="popMeetInfo()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看参会信息</button>
                </slot>
              </slot>
<!--              <slot v-else-if="isFreeMom">
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button @click="gotoDetail(true)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
              </slot> -->
              <slot v-else-if="isRejected === true">
                <div style="margin-right: 15px;font-size: 14px;color: #4880FF;">获取纪要失败  <span @click="feedback" style="cursor: pointer;color: #FFA000;">意见反馈</span></div>
              </slot>
              <slot v-else>
                <button @click="gotoDetail(false)" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">会议详情</button>
                <button ref='buyBtn' @mouseenter="buyBtnMouseenter" @mouseleave="buyBtnMouseleave" v-if="isMomApplied === true" @click="cancelConferenceRequest" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;width: 150px;outline: none;border: none;">{{ btnBuyingmom }}</button>
                <button v-else-if="isMomApproved === true" @click="gotoDetail(true)" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">查看会议纪要</button>
                <button v-else @click="buySummary()" style="cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 16px;outline: none;border: none;">获取会议纪要</button>
              </slot>
            </slot>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' :extraMessage="'--报名参会失败/预约纪要失败，会议id：' +  mainModel.id"></feedback>
    <alertView :title="alertTitle" :confirmTitle="confirmTitle" :cancelTitle="cancelTitle" :tipsArray="tipsArray" :isShow="showBuyMom || showSignUpMeet" @cancelEvent="cancelAlert" @confirmEvent="confirmEvent"></alertView>
    <meetInfoView :isShow="showMeetInfo" :info="mainModel.show_info" @dissmiss='showMeetInfo = false'></meetInfoView>
  </div>
</template>

<script>
import conference from '../api/conference.js'
import alertView from './alert_menu_view.vue'
import feedback from './feedback.vue'
import meetInfoView from './meet_info_view.vue'
  export default {
    props: {
      mainModel: {},
      isSearch: Boolean
    },
    components: {
      alertView,
      feedback,
      meetInfoView
    },
    computed: {
      meetTime: function () {
        var startTime = this.tool.getTimeYMD(this.mainModel.start_time)
        var endTime = this.tool.getTimeYMD(this.mainModel.end_time)
        if (startTime == endTime) {
          return startTime + ' ' + this.tool.getTimeHM(this.mainModel.start_time) + '-' + this.tool.getTimeHM(this.mainModel.end_time)
        }else {
          return startTime + ' 至 ' + endTime
        }
        // if (this.tool.getDateFormat('y-m-d', this.mainModel.start_time) === this.tool.getDateFormat('y-m-d', this.mainModel.end_time)) {
        //   return this.tool.getDateFormat('y-m-d', this.mainModel.start_time) + '  ' + this.tool.getDateFormat('h:i', this.mainModel.start_time) + '-' + this.tool.getDateFormat('h:i', this.mainModel.end_time)
        // } else {
        //   return this.tool.getDateFormat('y-m-d', this.mainModel.start_time) + ' 至 ' + this.tool.getDateFormat('y-m-d', this.mainModel.end_time)
        // }
      },
      isOngoing: function () {
        return this.tool.isSignUpStop(this.mainModel.start_time)
      },
      isEnd: function () {
        return this.tool.isEnd(this.mainModel.start_time)
      },
      isMomApproved: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'SUMMARY_BOUGHT') {
            return true
          }
        }
        return false
      },
      isConferenceApproved: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'CONFERENCE_APPLY_SUCCEEDED') {
            return true
          }
        }
        return false
      },
      isMomApplied: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'SUMMARY_APPLY') {
            return true
          }
        }
        return false
      },
      isConferenceApplied: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'CONFERENCE_APPLY') {
            return true
          }
        }
        return false
      },
      isRejected: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'FAILED'
          || this.mainModel.my_task.status === 'COMPLIANCE_EXCEPTION') {
            return true
          }
        }
        return false
      },
      isFirstAndMonthClient: function () {
        if (this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return true
        }else {
          return false
        }
        // if (this.$store.getters.client.has_monthly_conference_plan === true) {
        //   return true
        // }
        // return false
      },
      listChargeInfo: function () {
        if (this.isFreeMom) {
          return '费用说明：该场会议纪要免费'
        }
        if (this.mainModel.app_status === 'PREVIEW') {
          return this.mainModel.charge_preview
          } else {
          return this.mainModel.charge
        }
      },
      statusString: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'CONFERENCE_APPLY' || this.mainModel.my_task.status === 'SUMMARY_APPLY') {
            return require('../assets/img/yitijiao.svg')
          } else if (this.mainModel.my_task.status === 'CONFERENCE_APPLY_SUCCEEDED' || this.mainModel.my_task.status === 'SUMMARY_BOUGHT') {
            if (this.mainModel.app_status === 'COMPLETE') {
              return require('../assets/img/yiwancheng.svg')
            } else {
              return require('../assets/img/yibaoming.svg')
            }
          } else if (this.mainModel.my_task.status == 'COMPLETED') {
            return require('../assets/img/yiwancheng.svg')
          }
          else if (this.mainModel.my_task.status === 'FAILED') {
            return require('../assets/img/huoqushibai.svg')
          }
        }
        return ''
      },
      defaultEmail: function () {
        if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
          return this.$store.getters.email
        }else if(this.tool.isNullOrEmpty(this.$store.getters.app_email) === false) {
          return this.$store.getters.app_email
        }else {
          return ''
        }
     },
     isDBEmail: function () {
       if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
         return true
       }
       return false
     },
     isFreeMom: function () {
       if ((this.tool.isNewFree(this.mainModel.start_time) && this.mainModel.charge_type === 'FREE_AFTER_TWELVE_MONTHS') || this.mainModel.charge_type === 'FREE') {
         return true
      }else{
        return false
      }
    },
    typeImg: function () {
      // if (this.mainModel.type === 'TELEPHONE') {
      //   return '【电话会议】'
      // }else if (this.mainModel.type === 'VIDEO') {
      //   return '【视频会议】'
      // }else if (this.mainModel.type === 'OFFLINE') {
      //   return '【线下会议】'
      // }else if (this.mainModel.type === 'VIDEO_TRAINING') {
      //   return '【视频培训】'
      // }else if (this.mainModel.type === 'OFFLINE_TRAINING') {
      //   return '【线下培训】'
      // }
      return `【${this.mainModel.conference_type}】`
    }
    },
    data () {
      return {
        currentPage: 1,
        showSignUpMeet: false,
        showBuyMom: false,
        tipsArray: [],
        cancelTitle: "取消",
        confirmTitle: "确认",
        alertTitle: "提示",
        showFeedback:false,
        showMeetInfo:false,
        btnSignuping:'正在确认报名请求',
        btnRequestingmom:'正在确认预约请求',
        btnBuyingmom:'正在确认获取请求',
        requestMoming: false,
        requestSigning: false
      }
    },
    methods: {
      conferenceOutLine() {
        var contents = this.mainModel.content.split('@@@')
        var outline = ''
        contents.forEach( item=> {
          if(this.tool.isNullOrEmpty(item) == false) {
            if (item.indexOf('###') != -1) {
              var title = item.slice(0,item.indexOf('###')).replace(/\n/g, "")
              if (title == '【会议提纲】') {
                outline = item.slice(item.indexOf('###')+3,item.length).replace(/\n/g, " ")
                //shabi-code
                outline = outline.replace('2. ', "\n2. ")
                outline = outline.replace('3. ', "\n3. ")
                outline = outline.replace('4. ', "\n4. ")
                outline = outline.replace('5. ', "\n5. ")
                outline = outline.replace(/专家二/g, "\n专家二")
                outline = outline.replace(/专家三/g, "\n专家三")
                outline = outline.replace(/专家四/g, "\n专家四")
                outline = outline.replace(/专家2/g, "\n专家2")
                outline = outline.replace(/专家3/g, "\n专家3")
                outline = outline.replace(/专家4/g, "\n专家4")
              }
            }
          }
        })
        return outline
      },
      goToSearch(tag) {
        this.$store.dispatch('view/changeSearchTag', tag)
      },
      getIconTypeClass() {
        if (this.mainModel.charge_tag === 'FREE') {
          return 'free_icon_cell'
        }else if(this.mainModel.charge_tag === 'PAY') {
          return 'not_free_icon_cell'
        }else if(this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return 'monthly_icon_cell'
        }else if(this.mainModel.charge_tag === 'PAID') {
          return 'purchased_icon_cell'
        }else if(this.mainModel.charge_tag === 'COMPANY_PAID') {
          return 'purchased_icon_cell'
        }else {
          return ''
        }
        // if (this.mainModel.category === 'FIRST') {
        //   if (this.mainModel.icon === 'FREE') {
        //     return 'free_icon_cell'
        //   }else if(this.mainModel.icon === 'NOT_FREE') {
        //     return 'not_free_icon_cell'
        //   }else if(this.mainModel.icon === 'MONTHLY') {
        //     return 'monthly_icon_cell'
        //   }else if(this.mainModel.icon === 'PURCHASED') {
        //     return 'purchased_icon_cell'
        //   }else if(this.mainModel.icon === 'CLIENT_PURCHASED') {
        //     return 'purchased_icon_cell'
        //   }else {
        //     return ''
        //   }
        // }else {
        //   return ''
        // }
      },
      getIconType() {
        if (this.mainModel.charge_tag === 'FREE') {
          return '免费'
        }else if(this.mainModel.charge_tag === 'PAY') {
          return '付费'
        }else if(this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return '包月'
        }else if(this.mainModel.charge_tag === 'PAID') {
          return '已购'
        }else if(this.mainModel.charge_tag === 'COMPANY_PAID') {
          return '公司已购'
        }else {
          return ''
        }
        // if (this.mainModel.category === 'FIRST') {
        //   if (this.mainModel.icon === 'FREE') {
        //     return '免费'
        //   }else if(this.mainModel.icon === 'NOT_FREE') {
        //     return '付费'
        //   }else if(this.mainModel.icon === 'MONTHLY') {
        //     return '包月'
        //   }else if(this.mainModel.icon === 'PURCHASED') {
        //     return '已购'
        //   }else if(this.mainModel.icon === 'CLIENT_PURCHASED') {
        //     return '公司已购'
        //   }else {
        //     return ''
        //   }
        // }else {
        //   return ''
        // }
      },
      getTitleBoldT (val) {
        // var categoryss = '【一级】'
        // if (this.mainModel.category === 'FIRST') {
        //   categoryss = ''
        //   }else {
        //   categoryss = '【二级】'
        // }
        let str = this.typeImg + val
        if (this.tool.isNullOrEmpty(this.mainModel.title_highlight) === true) {
          return str
        }
        var reg = this.mainModel.title_highlight.split('，')
        reg.forEach( (res) => {
          str = str.replace(res,"<span style='font-weight: bold;color: #000000'>$&</span>")
        })
        return str
      },
      getTitleBold (val) {
        let str = val
        // var categoryss = '【一级】'
        // if (this.mainModel.category === 'FIRST') {
        //   categoryss = ''
        // }else {
        //   categoryss = '【二级】'
        // }
        if (this.mainModel.app_status === 'PREVIEW') {
          str = '【会议预告】' + val
        }else {
          str = '【凯盛会议】' + val
        }
        if (this.tool.isNullOrEmpty(this.mainModel.title_highlight) === true) {
          return str
        }
        var reg = this.mainModel.title_highlight.split('，')
        reg.forEach( (res) => {
          str = str.replace(res,"<span style='font-weight: bold;color: #000000'>$&</span>")
        })
        return str
      },
      saveAppEmail (val) {
        var userInfo = this.$store.getters.userInfo
        userInfo.app_email = val
        this.$store.dispatch('user/setUserInfo', userInfo)
      },
      buyBtnMouseenter () {
        var btn = this.$refs.buyBtn
        btn.style.background = '#FFA000'
        this.btnBuyingmom = '取消'
      },
      buyBtnMouseleave () {
        var btn = this.$refs.buyBtn
        btn.style.background = '#4880FF'
        this.btnBuyingmom = '正在确认获取请求'
      },
      requestingBtnMouseenter () {
        var btn = this.$refs.requestingBtn
        btn.style.background = '#FFA000'
        this.btnRequestingmom = '取消预约'
      },
      requestingBtnMouseleave () {
        var btn = this.$refs.requestingBtn
        btn.style.background = '#4880FF'
        this.btnRequestingmom = '正在确认预约请求'
      },
      signUpBtnMouseenter () {
        var btn = this.$refs.signUpBtn
        btn.style.background = '#FFA000'
        this.btnSignuping = '取消报名'
      },
      signUpBtnMouseleave () {
        var btn = this.$refs.signUpBtn
        btn.style.background = '#4880FF'
        this.btnSignuping = '正在确认报名请求'
      },
      chargeInfoContent: function (val) {
        if (this.mainModel.app_status === 'PREVIEW') {
          if (val === true) {
            return '参会费用：' + this.mainModel.conference_fee + '小时（含会议材料）'
          } else {
            return '纪要费用：' + this.mainModel.conference_fee + '小时'
          }
        }else if(this.mainModel.app_status === 'COMPLETE') {
          return '纪要费用：' + this.mainModel.conference_fee + '小时'
        }
        return ''
      },
      feedback () {
        this.showFeedback = true
      },
      cancelAlert () {
        this.showBuyMom = false
        this.showSignUpMeet = false
      },
      confirmEvent () {
        if(this.showBuyMom === true) {
          this.showBuyMom = false
          this.applyMomRequest()
        }else if (this.showSignUpMeet === true) {
          this.showSignUpMeet = false
          this.applyRequest()
        }
      },
      currentChange (val) {
        this.currentPage = val
      },
      gotoDetail ( val ) {
      let routeData = this.$router.resolve({
          path: "/conference/detail",
          query: {
            id: this.mainModel.id,
            isMom: val,
            },
		})
		window.open(routeData.href, '_blank')
    },
    cancelConferenceRequest () {
        var data = {}
        data.task_id = this.mainModel.my_task.task_id
        conference.cancelContactConference(data).then( res => {
          if (res.code === '0000') {
            var tips = '已取消报名'
            if (this.mainModel.app_status === 'PREVIEW') {
              if (this.isMomApplied) {
                tips = '已取消预约'
              } else if(this.isConferenceApplied) {
                tips = '已取消报名'
              }
            } else if(this.mainModel.app_status === 'COMPLETE') {
              tips = '已取消获取'
            }
            this.mainModel.my_task.status = ''/* 清状态 */
            this.$message(tips)
          }
        })
      },
      cancelConference () {
        var tips = '确认取消报名？'
        if (this.mainModel.status === 'PREVIEW') {
          if (this.isMomApplied) {
            tips = '确认取消预约？'
          } else if (this.isConferenceApplied) {
            tips = '确认取消报名？'
          }
        }
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: true,
          confirmButtonText: '确认取消',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.cancelConferenceRequest()
                done()
              } else if (action === 'cancel'){
                done()
              }
            }
        }).then(action => action).catch( e=>e)
      },
      buySummary () {
        if (this.isFreeMom && this.mainModel.app_status != 'PREVIEW') {
          this.applyMomRequest()
          return
        }
        this.alertTitle = this.mainModel.app_status === 'PREVIEW' ? '预约纪要' : '获取纪要'
        if (this.mainModel.app_status === 'PREVIEW') {
          this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,'发送纪要：会议结束后4-5个工作日内']
        } else {
          this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,this.chargeInfoContent(false)]
        }
        if (this.isFirstAndMonthClient === true) {
          if (this.mainModel.app_status === 'PREVIEW') {
            this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,'发送时间：会议结束后4-5个工作日内']
          } else {
            this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`]
          }
        }
        console.log(this.tipsArray)
        this.cancelTitle = '取消'
        this.confirmTitle = '确认'
        this.showBuyMom = true
      },
      applyMomRequest () {
        if (this.requestMoming) {
          return
        }
        this.requestMoming = true
        var data = {}
        data.conference_publish_id = this.mainModel.id
        data.status = 'SUMMARY_APPLY'
        data.origin = 'PC'
        data.pay_type = this.mainModel.pay_type
        conference.applyConference(data).then( res=> {
          this.requestMoming = false
          if (res.code === '0000') {
            if (res.data !== null) {
              this.mainModel.my_task = res.data
              if (res.data.status === 'SUMMARY_APPLY') {/* 人工审核 */
                if (this.mainModel.app_status === 'COMPLETE') {
                  this.popMomApplied()
                }else {
                  this.popMomAppliedAppoint()
                }
              } else if (res.data.status === 'SUMMARY_BOUGHT') { /* 报名成功*/
                if (this.mainModel.app_status === 'COMPLETE') {
                  this.popMomApproved()
                }else {
                  this.popMomApprovedAppoint()
                }
              } else if (this.mainModel.app_status === 'COMPLETE') {
                  this.$message('获取失败')
                }
            }
          }
        })
        },
        popMomApproved () {
            this.$message('获取成功')
            /*
            var message = ''
            if (this.isDBEmail === true) {
              message = '相关材料会发送到：' + this.$store.getters.email
            }else {
              message = '由于您未在凯盛登记邮箱，相关材料无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '获取成功',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
            */
          },
          popMomApplied () {
            var message = ''
            if (this.isDBEmail === true) {
              message = '凯盛正在处理您的获取请求！若获取成功，相关材料会发送到：' + this.$store.getters.email
            }else {
              message = '凯盛正在处理您的获取请求！由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '提示',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
          },
          popMomAppliedAppoint () {
            var message = ''
            if (this.isDBEmail === true) {
              message = '凯盛正在处理您的预约请求！若预约成功，会议资料完成整理后，发送到：' + this.$store.getters.email
            }else {
              message = '凯盛正在处理您的预约请求！由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '提示',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
          },
          popMomApprovedAppoint () {
            var message = ''
            if (this.isDBEmail === true) {
              message = '相关材料完成整理后会发送到：' + this.$store.getters.email
            }else {
              message = '由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '预约成功',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
          },
          popConferenceApplied () {
            var message = ''
            if (this.isDBEmail === true) {
              message = '凯盛正在处理您的报名请求！若报名成功，会将参会信息发送到：' + this.$store.getters.email
            }else {
              message = '凯盛正在处理您的报名请求！由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '提示',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
          },
          popConferenceApproved () {
            var message = ''
            if (this.isDBEmail === true) {
              message = '稍后会将参会信息发送到：' + this.$store.getters.email
            }else {
              message = '由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '报名成功',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '知道了',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
          },
      popMomApply () {
        var tips = `凯盛正在处理您的获取请求</br>若获取成功，相关材料会发送到您的邮箱。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        if (this.mainModel.app_status === 'PREVIEW') {
          tips = `凯盛正在处理您的预约请求</br>若预约成功，相关材料完成整理会发送到您的邮箱。</br>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        }
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel') {
              this.updateConferenceTaskEmail(instance.inputValue)
              done()
            }
          }
        }).then(value => {
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      popMomSuccess () {
        if (this.isFirstAndMonthClient === true && this.mainModel.app_status !== 'PREVIEW') {
          this.gotoDetail(true)
          return
        }
        var tips = `获取成功，相关材料会发送到您的邮箱。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        if (this.mainModel.app_status === 'PREVIEW') {
          tips = `预约成功，相关材料完成整理会发送到您的邮箱。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        }
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.mainModel.app_status === 'PREVIEW' ? '确认' :'查看会议纪要',
          showInput: this.mainModel.app_status === 'PREVIEW' ? (this.isDBEmail?false:true) : false,
          inputPlaceholder: '用于接收会议纪要',
          inputErrorMessage: '邮箱格式不正确',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              if (this.mainModel.app_status !== 'PREVIEW') {
                this.gotoDetail(true)
              }
              done()
            } else if (action === 'cancel'){
              this.updateConferenceTaskEmail(instance.inputValue)
              done()
            }
          }
        }).then(value => {
          console.log(value.value)
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      signUpMeet () {
        this.alertTitle = '报名参会'
        this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,this.chargeInfoContent(true),`会议时间：${this.meetTime}`]
        if (this.isFirstAndMonthClient ===  true) {
          this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,`会议时间：${this.meetTime}`]
        }
        this.cancelTitle = '取消'
        this.confirmTitle = '确认'
        this.showSignUpMeet = true
      },
      applyRequest () {
        if (this.requestSigning) {
          return
        }
        this.requestSigning = true
        var data = {}
        data.conference_publish_id = this.mainModel.id
        data.status = 'CONFERENCE_APPLY'
        data.origin = 'PC'
        data.pay_type = this.mainModel.pay_type
        conference.applyConference(data).then( res=> {
          this.requestSigning = false
          if (res.code === '0000') {
            if (res.data !== null) {
              this.mainModel.my_task = res.data
              if (res.data.show_info) {
                this.mainModel.show_info = res.data.show_info
              }
              if (res.data.status === 'CONFERENCE_APPLY') {
                this.popConferenceApplied()
              } else if(res.data.status === 'CONFERENCE_APPLY_SUCCEEDED') {
                this.popConferenceApproved()
              }
            }
          }
        })
      },
      popApply () {
        var tips = `凯盛正在处理您的报名请求。<br/>若报名成功，您的邮箱将会收到参会信息。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel') {
              this.updateConferenceTaskEmail(instance.inputValue)
              done()
            }
          }
        }).then(value => {
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      updateConferenceTaskEmail (email) {
        console.log(email)
        var sendEmail = email
        if (this.tool.isNullOrEmpty(email)) {
          sendEmail = this.defaultEmail
        }
        if (sendEmail) {
            var data = {}
            data.task_id = this.mainModel.my_task.id
            data.email = sendEmail
            conference.confirmApplyEmail(data).then( res => {
              if (res.code === '0000') {
                this.saveAppEmail(email)
              }
            })
        }
      },
      popSuccess () {
        var tips = `报名成功，您的邮箱将收到参会信息。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          cancelButtonText: '查看参会信息',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel'){
              this.popMeetInfo()
              this.updateConferenceTaskEmail(instance.value)
              done()
            }
          }
        }).then(value => {
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      popMeetInfo () {
        this.showMeetInfo = true
      },
      openEvent () {
        this.$emit('openEvent')
      },
      closeEvent () {
        this.$emit('closeEvent')
      }
    }
  }
</script>

<style lang="scss">
  .normal-cell-c{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    height: 60px;
    background-color: #F5FEFF;
    box-shadow:0px 0px 50px rgba(164,181,201,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
      box-shadow: 0px 0px 10px rgba(39, 138, 255, 0.26);
    }
  }
  .normal-cell{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    height: 60px;
    background-color: white;
    box-shadow:0px 0px 50px rgba(164,181,201,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
      box-shadow: 0px 0px 10px rgba(39, 138, 255, 0.26);
    }
  }
  .tags_view {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 14px;
    width: calc(100% - 34px);
    margin-top: 4px;
  }
  .tags_view_label {
    padding: 4px 10px;
    margin-right: 6px;
    margin-top: 6px;
    border: 1px solid #1E459D;
    border-radius: 4px;
    font-size: 12px;
    color: #1E459D;
    &:hover {
      background-color: #f0f0f0;
    }
    cursor: pointer;
  }
  #detail-cell {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .cell-title{
    color: #393939;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 24px;
    text-align: left;
  }
  .cell-gap-title{
    color: #393939;
    font-size: 15px;
    margin-left: 0px;
    margin-right: 24px;
    text-align: left;
  }
  .cell-right-option{
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .time-label{
    margin-right: 24px;
    font-size: 12px;
    color: #909090;
    width: 70px;
  }
  .status-label{
    width: 80px;
    height: 28px;
    font-size: 12px;
    margin-right: 20px;
    line-height: 28px;
    text-align: center;
    border-radius:4px;
  }
  .status-jxz{
    color: #4880FF;
    background-color: #F0F5FF;
  }
	.detail-conference-cell {
    width: 100%;
    margin-top: 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
	}
  .middle-view {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    margin-bottom: auto;
  }
  .right-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 69%;
    cursor: pointer;
  }
  .left-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 31%;
  }
  .consultant-info-view {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
  }
  .footer-option-view {
    display: flex;
    flex-direction: row;
    height: 56px;
    margin-top: auto;
    margin-bottom: 0px;
    border-top: 0.5px solid #F5F5F5;
    align-items: center;
    cursor: pointer;
  }
  .three-lines {
    -webkit-line-clamp: 4;
  }
  .five-lines {
    -webkit-line-clamp: 5;
  }
  .free_icon_cell {
    padding: 3px 8px;
    background-color: #D6FFE3;
    color: #32B94C;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 40px;
  }
  .not_free_icon_cell {
    padding: 3px 8px;
    background-color: #FFE3E3;
    color: #FF7510;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 40px;
  }
  .monthly_icon_cell {
    padding: 3px 8px;
    font-size: 12px;
    background-color: #FFF6B7;
    color: #DEA926;
  }
  .purchased_icon_cell {
    padding: 3px 8px;
    background-color: #D8F1FA;
    color: #008BDE;
    font-size: 12px;
  }
  .huan_hang {
    white-space: pre-wrap;
  }
</style>
