<template>
  <div id="list-conference">
    <div v-for="(item,index) in list" :key="index">
      <listResearchCell v-if="item.resource_type == 'RESEARCH'" titleExtra='' :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent(index)'></listResearchCell>
      <listDetailCell v-else :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent(index)'></listDetailCell>
    </div>
  </div>
</template>

<script>
import listDetailCell from './list_conference_cell.vue'
import listResearchCell from './list_research_cell.vue'
export default {
  components: {
    listDetailCell,
    listResearchCell
  },
  props: {
    list: Array
  },
  data () {
    return {
    }
  },
  methods: {
    openEvent (val) {
      this.$emit('openEventList', val)
    },
    closeEvent (val) {
      this.$emit('closeEventList', val)
    }
  }
}
</script>

<style>
  #list-conference{
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
  }
</style>
