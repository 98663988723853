<template>
  <div></div>
</template>

<script>
  import unloginApi from '../api/un_login_detail.js'
  export default {
    data () {
      return {
        token: this.$route.query.token
      }
    },
    mounted() {
      this.token = this.$route.query.token
      if (this.$route.query.from_share_list === '1') {
        this.$store.dispatch('view/updataFromShareList', true)
      }else {
        this.$store.dispatch('view/updataFromShareList', false)
      }
      //去除过期sid
      if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
        unloginApi.checkSid().then( res=> {
          if (res.code  === '0000') {
            if (res.data === false) {
              this.$store.dispatch('user/logoutData')
            }
          }else {
            this.$store.dispatch('user/logoutData')
          }
          let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          if (isMobile) {
            if (this.token.substr(0,1) === 'C') {
              this.$router.replace({ path:'/mobileConferenceDetail', query:{token:this.token}})
            } else if (this.token.substr(0,1) === 'R') {
              this.$router.replace({ path:'/mobileResearchDetail', query:{token:this.token}})
            } else if (this.token.substr(0,1) === 'T') {
              this.$router.replace({ path:'/mobileTopicDetail', query:{token:this.token}})
            }
          } else {
            if (this.token.substr(0,1) === 'C') {
              this.$router.replace({ path:'/conference/detail', query:{token:this.token}})
            } else if (this.token.substr(0,1) === 'R') {
              this.$router.replace({ path:'/researchDetail', query:{token:this.token}})
            } else if (this.token.substr(0,1) === 'T') {
              this.$router.replace({ path:'/topicDetail', query:{token:this.token}})
            }
          }
        })
      }else {
        let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (isMobile) {
          if (this.token.substr(0,1) === 'C') {
            this.$router.replace({ path:'/mobileConferenceDetail', query:{token:this.token}})
          } else if (this.token.substr(0,1) === 'R') {
            this.$router.replace({ path:'/mobileResearchDetail', query:{token:this.token}})
          } else if (this.token.substr(0,1) === 'T') {
            this.$router.replace({ path:'/mobileTopicDetail', query:{token:this.token}})
          }
        } else {
          if (this.token.substr(0,1) === 'C') {
            this.$router.replace({ path:'/conference/detail', query:{token:this.token}})
          } else if (this.token.substr(0,1) === 'R') {
            this.$router.replace({ path:'/researchDetail', query:{token:this.token}})
          } else if (this.token.substr(0,1) === 'T') {
            this.$router.replace({ path:'/topicDetail', query:{token:this.token}})
          }
        }
      }
    }
  }
</script>

<style>
</style>
