<template>
  <div>
    <el-dialog @close="cancel()" width="500px" :modal-append-to-body='false' :close-on-click-modal="false" :show-close="false" title="参会信息" center :visible.sync="isShow">
      <div v-html="httpInfo" style="height: 350px;text-align: left;font-size: 14px;color: #313131;overflow: auto;"></div>
      <div style="flex-direction: row;display: flex;padding-top: 30px;padding-bottom: 10px;justify-content: center;">
        <el-button size="medium" @click="cancel" style="width:150px;">知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      info: String,
      isShow: Boolean
    },
    computed: {
      httpInfo: function () {
        if (this.info === null || this.info === 'null') {
          return ''
        }
        var content = this.info
        if (this.tool.isNullOrEmpty(content) === false) {
          var textR = this.info
          var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
          content = textR.replace(reg, "<a href='$1$2' target ='_blank' style='color: #1E459D;'>点击这里</a>")
          return content
        }
        return ''
      }
    },
    methods: {
      cancel () {
        this.$emit('dissmiss')
      }
    }
  }
</script>

<style>
</style>
