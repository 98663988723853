<template>
  <div>
    <img style="position: absolute;width: 280px;height: 210px;top: calc(50% - 120px);left: calc(50% - 140px);" v-show="loading && tool.isNullOrEmpty(mainModel.title)" src="../../assets/img/loading1.gif" />
    <div class="main-content">
      <p style="font-size: 16px;color: #242424;margin-top: 25px;margin-left: 15px;margin-right: 15px;font-weight: bold;">{{ '【凯盛研究】' + mainModel.title }}</p>
      <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in mainModel.contentArray" :key="indexModel+1">
         <div class="title-nav-view">
           <div class="flag-line"></div>
           <label class="nav-title">{{ itemModel.title }}</label>
         </div>
         <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
            <label
             v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
            <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 30px);margin-left: 15px;margin-bottom: 10px;"  />
          </div>
<!--         <label
           style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ itemModel.content }}</label> -->
       </div>
    </div>
    <div class="main-footer-view">
       <button @click="buyResearch()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 140px;outline: none;border: none;">获取报告</button>
    </div>
    <optionview :noConference="true" resource_type='RESEARCH' v-model="showLogin" @dissmiss='showLogin = false' @unloginCreateOrder='unloginCreateOrder' @loginSuccessEvent='loginSuccessEvent'></optionview>
  </div>
</template>

<script>
  import detailApi from '../../api/un_login_detail.js'
  import optionview from './mobile_option_view.vue'
  import researchApi from '../../api/research_report.js'
  import sharesdk from '../../utils/wxshare.js'

  export default {
    data () {
      return {
        mainModel: {},
        token: "",
        showLogin:false,
        loading: true
      }
    },
    components: {
      optionview
    },
    mounted() {
      this.token = this.$route.query.token
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (this.tool.isNullOrEmpty(isMobile)) {
        this.$router.replace({ path:'/researchDetail', query:{token:this.token}})
      }
      this.getReseachDetail(false)
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.backChange, false)
      }
    },
    destroyed() {
      window.removeEventListener('popstate', this.backChange, false)
    },
    methods: {
      backChange () {
        this.$router.back()
      },
      unloginCreateOrder (val) {
        this.showLogin = false
        var data = {}
        data.resource_id = this.mainModel.id
        data.resource_type = 'RESEARCH'
        data.mobile = val
        data.origin_channel = 'H5'
        detailApi.createOrder(data).then( res=> {
          if (res.code === '0000') {
            if(res.data.status === 'PENDING') {
              this.$router.replace({ path:'/success_tips',query:{type: 'research'}})
            }
          }
        })
      },
      loginSuccessEvent () {
        this.showLogin = false
        this.getReseachDetail(true)
      },
      getReseachDetail (val) {
        var data = {}
        data.token = this.token
        this.loading = true
        detailApi.getDetail(data).then( res=> {
          this.loading = false
          if (res.code === '0000') {
            this.mainModel = res.data
            if (val === false) {
              let url = location.href.split('#')[0]
              let shareUrl = url + '#/visit?token=' + this.token
              var img = 'http://cdn.capvision.cn/media/conference/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210220095321.png'
              let obj = {
                title: '凯盛融英研究报告',
                des: this.mainModel.title,
                linkurl: shareUrl,
                img: img
            }
              sharesdk.getJSSDK(url,obj)
              this.DDConfig.ddShowMenu('凯盛融英研究报告',img,shareUrl,this.mainModel.title)
            }else {
              this.buyResearch()
            }
            this.mainModel.contentArray = []
            var contents = res.data.content.split('@@@')
            contents.forEach((item) => {
              if (this.tool.isNullOrEmpty(item) === false) {
                if (item.indexOf('###') !== -1) {
                  var itemModel = {}
                  itemModel.title = item.slice(0,item.indexOf('###'))
                  itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                  itemModel.imageTextArray = []

                  var imgTextArray = itemModel.content.split('<img>')
                  imgTextArray.forEach( imgText => {
                    var imgTextModel = {}
                    if (imgText.indexOf('</img>') !== -1) {
                      var  subImgTextArray = imgText.split('</img>')
                      if (subImgTextArray.length === 2) {
                        for (var i = 0;i < subImgTextArray.length;i++) {
                          var imgSubTextModel = {}
                          if (i === 0) {
                            imgSubTextModel.img = subImgTextArray[0]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }else {
                            imgSubTextModel.text = subImgTextArray[1]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }
                        }
                      }else {
                        imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    }else{
                      imgTextModel.text = imgText
                      itemModel.imageTextArray.push(imgTextModel)
                    }
                  })
                  this.mainModel.contentArray.push(itemModel)
                }
              }
            })
          }
        })
      },
      buyResearch () {
        if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
          if (this.mainModel.my_task !== null) {
            this.$router.replace({ path:'/success_tips'})
          } else {
            let params = {
              contact_id: this.$store.getters.uid,
              research_id: this.mainModel.id,
              status: "APPLIED",
              origin_channel: "H5"
            }
            researchApi.purchaseResearchDetail(params).then(res =>{
              if(res.code === '0000'){
                this.$router.replace({ path:'/success_tips'})
              }
            })
          }
        }else{
          this.showLogin = true
        }
      }
    }
  }
</script>

<style>
  .main-content {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding-bottom: calc(88px + env(safe-area-inset-bottom));
  }
  .title-nav-view {
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flag-line {
    margin-left: 0px;
    background-color: #1E459D;
    width: 4px;
    height: 18px;
  }
  .nav-title {
    color: #242424;
    font-size: 16px;
    font-weight: bold;
    margin-left: 14px;
  }
  .main-footer-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 68px;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
  }
</style>
