import {HttpNoErrorService} from '@/utils/request'

export default {

  async loadIndustryList () {
    return HttpNoErrorService({
      url: '/misc/industries',
      method: 'post'
    })
  },

  async loadSettingMsg (data) {
    return HttpNoErrorService({
      url: '/client_contacts/profile/query',
      method: 'post',
      data
    })
  },

  async commitSettingInfo (data) {
    return HttpNoErrorService({
      url: '/client_contacts/profile/update',
      method: 'post',
      data
    })
  }

}
