<template>
  <div id="conference_detail_full">
    <div v-if="mainModel == null || mainModel == undefined" class="conference-detail-contain-full">
      <div style="height: 90px;line-height: 90px;">您暂时不能通过线上平台获取该内容，请与您的客户经理联系。（错误编号：17）</div>
    </div>
    <div v-if="mainModel != null && mainModel != undefined" class="conference-detail-contain-full">
      <div style="margin-top: 30px;margin-right: 0rpx;margin-left: 0rpx;display: flex;flex-direction: row;align-items: center;justify-content: center;">
        <label style="font-size: 14px;padding: 2px 5px;border-radius: 4px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
        <label v-if="mainModel.conference_name" style="margin-top: 0px;" class="title">{{ (mainModel.app_status === 'PREVIEW' ? '【会议预告】' : '【凯盛会议】') + mainModel.conference_name }}</label>
      </div>
      <div v-show="isMom === false" class="detail-main">
       <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in mainModel.contentArray" :key="indexModel+1">
          <div class="title-nav-view-ss">
            <div class="flag-line-ss"></div>
            <label class="nav-title">{{ itemModel.title }}</label>
          </div>
         <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
            <label
             v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-wrap;text-align: left;margin-left: 24px;margin-right: 24px;margin-bottom: 20px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
            <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 48px);margin-left: 24px;margin-bottom: 20px;"  />
          </div>
        </div>
        <div v-if="mainModel.all_tags && mainModel.all_tags.length > 0">
          <div class="title-nav-view-ss">
            <div class="flag-line-ss"></div>
            <label class="nav-title">标签</label>
          </div>
          <div class="detail_tags_main_view">
            <div @click="goToSearch(tag)" class="detail_tags_view_label" v-for="(tag,tagIndex) in mainModel.all_tags" :key="tagIndex">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <div  v-show="isMom === true" class="detail-main">
       <div style="display: flex;flex-direction: column;" v-for="(momModel,indexMomModel) in momContent" :key="indexMomModel+1">
          <div class="title-nav-view-ss">
            <div class="flag-line-ss"></div>
            <label class="nav-title">{{ momModel.title }}</label>
          </div>
         <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in momModel.imageTextArray" :key='contentIndex'>
            <label
             v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-wrap;text-align: left;margin-left: 24px;margin-right: 24px;margin-bottom: 20px;color: #000000;font-size: 14px;line-height: 22px;" class="ztfzNoUse">{{ contentItem.text }}</label>
            <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 48px);margin-left: 24px;margin-bottom: 20px;"  />
          </div>
        </div>
        <div v-if="momContent.length === 0" class="summary-main" v-html="mainModel.mom_web_content"></div>
      </div>
      <slot v-if="isMom == false">
        <div v-if="momRightShow" @click="changeType(1)" class="right-option-btn-full">
           <img style="width: 100%;height: auto;" src="../../assets/img/jiyaoinfo.svg" />
         </div>
        <div v-if="momRightShow && showRightBigMenu" class="right-seg-btn-full">
           <img style="width: 100%;height: auto;margin-bottom: -10px;" src="../../assets/img/conferenc_sel.svg" />
           <img @click="changeType(1)" style="width: 100%;height: auto;margin-top: -10px;" src="../../assets/img/conference_mom_nor.svg" />
         </div>
      </slot>
      <slot v-if="isMom == true">
        <div v-if="momRightShow" @click="changeType(0)" class="right-option-btn-full">
          <img style="width: 100%;height: auto;" src="../../assets/img/huiyiinfo.svg" />
        </div>
        <div v-if="momRightShow && showRightBigMenu" class="right-seg-btn-full">
          <img @click="changeType(0)" style="width: 100%;height: auto;margin-bottom: -10px;" src="../../assets/img/conferenc_nor.svg" />
          <img style="width: 100%;height: auto;margin-top: -10px;" src="../../assets/img/conference_mom_sel.svg" />
        </div>
      </slot>
      <div v-if="isMom === false && ((mainModel.is_mom_file_published && mainModel.my_task && mainModel.my_task.status == 'COMPLETED') === false || !mainModel.contact_task_for_contact) && (mainModel.app_status === 'COMPLETE' && (isMomApproved === true || isConferenceApproved === true)) === false" class="footer-view-fullgoal"><!-- 会议介绍 -->
        <slot v-if="mainModel.app_status === 'PREVIEW'">
          <slot v-if="isOngoing">
            <button v-if="isMomApplied === true || isConferenceApplied === true" style="cursor: pointer;margin-left: 15px;width: 140px;height: 45px;color: #FFFFFF;font-size: 16px;background-color: #4880FF;border-radius:8px;outline: none;border: none;">{{ btnRequestingmom }}</button>
            <slot v-else-if="isMomApproved === true">
              <button @click="popMomApproved()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要成功</button>
              <!-- <button @click="cancelConference()" style="cursor: pointer;margin-left: 15px;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">取消预约</button> -->
            </slot>
            <slot v-else-if="isConferenceApproved === true">
              <button @click="popMomApproved()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要成功</button>
              <!-- <button @click="cancelConference()" style="cursor: pointer;margin-left: 15px;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">取消预约</button> -->
            </slot>
            <slot v-else-if="mainModel.my_task && mainModel.my_task.status == 'COMPLETED'">
              <slot v-if="mainModel.is_mom_file_published == false">
                <button @click="popMomApproved()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要成功</button>
                <!-- <button @click="cancelConference()" style="cursor: pointer;margin-left: 15px;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">取消预约</button> -->
              </slot>
            </slot>
            <button v-else @click="buySummary()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background: #4880FF;border-radius: 8px;height: 45px;width: 180px;outline: none;border: none;">预约纪要</button>
          </slot>
          <slot v-else>
            <button v-if="isConferenceApplied === true" style="cursor: pointer;margin-right: 15px;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 140px;outline: none;border: none;">{{ btnSignuping }}</button>
            <button @click="cancelConferenceRequest" v-else-if="isMomApplied === true" style="cursor: pointer;margin-left: 15px;height: 45px;color: #FFFFFF;font-size: 16px;background-color: #4880FF;border-radius:8px;height: 45px;width: 140px;outline: none;border: none;">{{ btnRequestingmom }}</button>
            <slot v-else-if="isConferenceApproved === true">
              <button @click="popMeetInfo()" style="cursor: pointer;font-size: 12px;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">查看参会信息</button>
              <!-- <button @click="cancelConference()" style="cursor: pointer;margin-left: 15px;font-size: 12px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">取消报名</button> -->
            </slot>
            <slot v-else-if="isMomApproved === true">
              <button @click="popMomApproved()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #3B7CFF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要成功</button>
              <!-- <button @click="cancelConference()" style="cursor: pointer;margin-left: 15px;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">取消预约</button> -->
            </slot>
            <slot v-else-if="isRejected === true">
              <div style="font-size: 16px;color: #4880FF;">报名参会或预约纪要失败<span style="color: #FFA000;">意见反馈</span></div>
            </slot>
            <slot v-else>
              <button @click="signUpMeet()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">报名参会</button>
              <!-- <button @click="buySummary()" style="cursor: pointer;font-size: 16px;margin-left: 15px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要</button> -->
            </slot>
          </slot>
        </slot>
        <slot v-else-if="mainModel.app_status === 'COMPLETE'">
          <button v-if="isMomApplied === true" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 140px;outline: none;border: none;">{{ btnBuyingmom }}</button>
          <button v-else @click="buySummary()" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">获取会议纪要</button>
        </slot>
      </div>
      <div v-else-if="isMom ===  true" class="footer-view-fullgoal"><!-- 会议纪要 -->
        <!-- <label style="color: #393939;font-size: 12px;">{{ '会议资料包括：' + fileInfo }}</label> -->
        <button
          style="cursor: pointer;margin-left: 15px;width: 180px;height: 45px;color: #FFFFFF;font-size: 16px;background-color: #4880FF;border-radius:8px;outline: none;border: none;" @click="sendEmail">
          发送到邮箱
        </button>
      </div>
    </div>
    <alertView v-if="mainModel != null && mainModel != undefined" :title="alertTitle" :confirmTitle="confirmTitle" :cancelTitle="cancelTitle" :tipsArray="tipsArray" :isShow="showBuyMom || showSignUpMeet" @cancelEvent="cancelAlert" @confirmEvent="confirmEvent"></alertView>
    <meetInfoView v-if="mainModel != null && mainModel != undefined" :isShow="showMeetInfo" :info="mainModel.show_info" @dissmiss='showMeetInfo = false'></meetInfoView>
  </div>
</template>

<script>
  import goalApi from '../../api/fullgoal.js'
  import alertView from '@/components/alert_menu_view.vue'
  import meetInfoView from '@/components/meet_info_view.vue'
  import watermark from '../../utils/watermark.js'
  export default {
    data() {
      return {
        tsid: '',
        mainModel: {
          my_task: {
            status: ''
          }
        },
        id: 0,
        isMom: false,
        showSignUpMeet: false,
        showBuyMom: false,
        tipsArray: [],
        cancelTitle: "取消",
        confirmTitle: "确认",
        alertTitle: "提示",
        showMeetInfo:false,
        isSendingEmail:false,
        mom_email_address:'',
        token:'',
        showLogin:false,
        type: '',
        btnSignuping:'正在确认报名请求',
        btnRequestingmom:'正在确认预约请求',
        btnBuyingmom:'正在确认获取请求',
        momContent: [],
        showRightBigMenu: true,
        requestMoming: false,
        requestSigning: false
      }
    },
    components: {
      alertView,
      meetInfoView,
    },
    computed: {
      meetTime: function () {
        return this.tool.getTimeYMDHM(this.mainModel.start_time)
        // if (this.tool.getDateFormat('y-m-d', this.mainModel.start_time) === this.tool.getDateFormat('y-m-d', this.mainModel.end_time)) {
        //   return this.tool.getDateFormat('y-m-d', this.mainModel.start_time) + '  ' + this.tool.getDateFormat('h:i', this.mainModel.start_time) + '-' + this.tool.getDateFormat('h:i', this.mainModel.end_time)
        // } else {
        //   return this.tool.getDateFormat('y-m-d', this.mainModel.start_time) + ' 至 ' + this.tool.getDateFormat('y-m-d', this.mainModel.end_time)
        // }
      },
      isOngoing: function () {
        return this.tool.isSignUpStop(this.mainModel.start_time)
      },
      isMomApproved: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'SUMMARY_BOUGHT') {
            return true
          }
        }
        return false
      },
      isConferenceApproved: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'CONFERENCE_APPLY_SUCCEEDED') {
            return true
          }
        }
        return false
      },
      isMomApplied: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'SUMMARY_APPLY') {
            return true
          }
        }
        return false
      },
      isConferenceApplied: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'CONFERENCE_APPLY') {
            return true
          }
        }
        return false
      },
      isRejected: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'REJECTED') {
            return true
          }
        }
        return false
      },
      isFirstAndMonthClient: function () {
        if (this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return true
        }else {
          return false
        }
        // if (this.$store.getters.client.has_monthly_conference_plan === true) {
        //   return true
        // }
        // return false
      },
      defaultEmail: function () {
         if (this.mainModel.fullgoal_user) {
           if (this.mainModel.fullgoal_user.email_contact) {
             return this.mainModel.fullgoal_user.email_contact.value
           }else {
             return ''
           }
         }else {
           return ''
         }
      },
      isLogin: function(){
        if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
          return false
        }else{
          return true
        }
      },
      isDBEmail: function () {
        if (this.mainModel.fullgoal_user) {
          if (this.mainModel.fullgoal_user.email_contact) {
            return true
          }else {
            return false
          }
        }else {
          return false
        }
      },
      isFreeMom: function () {
        if ((this.tool.isNewFree(this.mainModel.start_time) && this.mainModel.charge_type === 'FREE_AFTER_TWELVE_MONTHS') || this.mainModel.charge_type === 'FREE') {
          return true
        }else{
          return false
        }
      },
      fileInfo: function () {
        if (this.tool.isNullOrEmpty(this.mainModel.file_mom) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_shorthand) === true
        && this.tool.isNullOrEmpty(this.mainModel.file_speech) === true) {
          return '会议纪要。'
        } else if (this.tool.isNullOrEmpty(this.mainModel.file_mom) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_shorthand) === true
        && this.tool.isNullOrEmpty(this.mainModel.file_speech) === false) {
          return '会议纪要和演讲材料。'
        } else if (this.tool.isNullOrEmpty(this.mainModel.file_mom) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_shorthand) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_speech) === true) {
          return '会议纪要和会议速记。'
        } else if (this.tool.isNullOrEmpty(this.mainModel.file_mom) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_shorthand) === false
        && this.tool.isNullOrEmpty(this.mainModel.file_speech) === false) {
          return '会议纪要、演讲材料和会议速记。'
        }
        return '会议纪要。'
      },
      momRightShow: function () {
        if (this.mainModel.app_status == 'COMPLETE') {
          if (this.isMomApproved === true ||  this.isConferenceApproved === true) {
            return true
          }else {
            if (this.mainModel.my_task) {
              if(this.mainModel.my_task.status == 'COMPLETED'){
                if (this.mainModel.is_mom_file_published) {
                  return true
                }else {
                  return false
                }
              }
            }else {
              return false
            }
          }
          return false
        }
        if (this.mainModel.my_task) {
          if(this.mainModel.my_task.status == 'COMPLETED'){
            if (this.mainModel.is_mom_file_published) {
              return true
            }else {
              return false
            }
          }else {
            return false
          }
        }else {
          return false
        }
      }
    },
    mounted() {
      this.tsid = this.$route.query.tsid
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (isMobile) {
        this.$router.replace({ path:'/fullgoalConferenceH5', query:{tsid:this.tsid}})
      }
      this.isMom = false
      this.getConferenceDetail()
    },
    destroyed() {
      watermark.remove()
    },
    methods: {
      getIconTypeClass() {
        if (this.mainModel == null || this.mainModel == undefined) {
          return
        }
        if (this.mainModel.charge_tag === 'FREE') {
          return 'free_icon'
        }else if(this.mainModel.charge_tag === 'PAY') {
          return 'not_free_icon'
        }else if(this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return 'monthly_icon'
        }else if(this.mainModel.charge_tag === 'PAID') {
          return 'purchased_icon'
        }else if(this.mainModel.charge_tag === 'COMPANY_PAID') {
          return 'purchased_icon'
        }else {
          return ''
        }
      },
      getIconType() {
        if (this.mainModel == null || this.mainModel == undefined) {
          return
        }
        if (this.mainModel.charge_tag === 'FREE') {
          return '免费'
        }else if(this.mainModel.charge_tag === 'PAY') {
          return '付费'
        }else if(this.mainModel.charge_tag === 'PAY_MONTHLY') {
          return '包月'
        }else if(this.mainModel.charge_tag === 'PAID') {
          return '已购'
        }else if(this.mainModel.charge_tag === 'COMPANY_PAID') {
          return '公司已购'
        }else {
            return ''
        }
      },
      saveAppEmail (val) {
        var userInfo = this.$store.getters.userInfo
        userInfo.app_email = val
        this.$store.dispatch('user/setUserInfo', userInfo)
      },
      buyBtnMouseenter () {
        var btn = this.$refs.buyBtn
        btn.style.background = '#FFA000'
        this.btnBuyingmom = '取消获取'
      },
      buyBtnMouseleave () {
        var btn = this.$refs.buyBtn
        btn.style.background = '#4880FF'
        this.btnBuyingmom = '正在确认获取请求'
      },
      requestingBtnMouseenter () {
        var btn = this.$refs.requestingBtn
        btn.style.background = '#FFA000'
        this.btnRequestingmom = '取消预约'
      },
      requestingBtnMouseleave () {
        var btn = this.$refs.requestingBtn
        btn.style.background = '#4880FF'
        this.btnRequestingmom = '正在确认预约请求'
      },
      signUpBtnMouseenter () {
        var btn = this.$refs.signUpBtn
        btn.style.background = '#FFA000'
        this.btnSignuping = '取消报名'
      },
      signUpBtnMouseleave () {
        var btn = this.$refs.signUpBtn
        btn.style.background = '#4880FF'
        this.btnSignuping = '正在确认报名请求'
      },
      chargeInfoContent: function (val) {
          if (this.mainModel.app_status === 'PREVIEW') {
            if (val === true) {
              return '参会费用：' + this.mainModel.conference_fee + '小时（含会议材料）'
            } else {
              return '纪要费用：' + this.mainModel.conference_fee + '小时'
            }
          }else if(this.mainModel.app_status === 'COMPLETE') {
            return '纪要费用：' + this.mainModel.conference_fee + '小时'
          }
          return ''
      },
      cancelAlert () {
        this.showBuyMom = false
        this.showSignUpMeet = false
      },
      confirmEvent () {
        if(this.showBuyMom === true) {
          this.showBuyMom = false
          this.applyMomRequest()
        }else if (this.showSignUpMeet === true) {
          this.showSignUpMeet = false
          this.applyRequest()
        }
      },
      changeType (type) {
        if (type === 0 && this.isMom === true) {
          this.isMom = false
        } else if (type === 1 && this.isMom === false) {
          this.isMom = true
        }
      },
      cancelConferenceRequest () {
        var data = {}
        data.task_id = this.mainModel.my_task.task_id
        // conference.cancelContactConference(data).then( res => {
        //   if (res.code === '0000') {
        //     var tips = '已取消报名'
        //     if (this.isMomApplied) {
        //       tips = '已取消预约'
        //     }
        //     this.mainModel.my_task.status = ''/* 清状态 */
        //     this.$message(tips)
        //   }
        // })
      },
      cancelConference () {
        var tips = '确认取消报名？'
        if (this.isMomApplied) {
          tips = '确认取消预约？'
        }
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: true,
          confirmButtonText: '确认取消',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.cancelConferenceRequest()
                done()
              } else if (action === 'cancel'){
                done()
              }
            }
        }).then(action => action).catch( e=>e)
      },
      getMomDetail () {
        if (this.requestMoming) {
          return
        }
        var data = {}
        data.conference_publish_id = this.id
        this.requestMoming = true
        goalApi.fullgoalConferenceOneMom(data).then( res => {
          this.requestMoming = false
          this.showRightBigMenu = false
          if (this.tool.isNullOrEmpty(res.data.summary_text) === false) {
            this.mainModel.mom_web_content = res.data.summary_text
            this.mainModel.file_mom = res.data.file_mom
            this.mainModel.file_shorthand = res.data.file_shorthand
            this.mainModel.file_speech = res.data.file_speech
            if (res.data.summary_text.indexOf('@@@') === 0) {
              var mom_web_content = res.data.mom_web_content.split('@@@')
              this.momContent = []
              mom_web_content.forEach( (item) => {
                if(this.tool.isNullOrEmpty(item) === false) {
                  if (item.indexOf('###') !== -1) {
                    var itemModel = {}
                    itemModel.title = item.slice(0,item.indexOf('###'))
                    itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                    itemModel.imageTextArray = []

                    var imgTextArray = itemModel.content.split('<img>')
                    imgTextArray.forEach( imgText => {
                      var imgTextModel = {}
                      if (imgText.indexOf('</img>') !== -1) {
                        var  subImgTextArray = imgText.split('</img>')
                        if (subImgTextArray.length === 2) {
                          for (var i = 0;i < subImgTextArray.length;i++) {
                            var imgSubTextModel = {}
                            if (i === 0) {
                              imgSubTextModel.img = subImgTextArray[0]
                              itemModel.imageTextArray.push(imgSubTextModel)
                            }else {
                              imgSubTextModel.text = subImgTextArray[1]
                              itemModel.imageTextArray.push(imgSubTextModel)
                            }
                          }
                        }else {
                          imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                          itemModel.imageTextArray.push(imgTextModel)
                        }
                      }else{
                        imgTextModel.text = imgText
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    })
                    this.momContent.push(itemModel)
                  }
                }
              })
            }
          }
        })
      },
      buySummary () {
        if (this.mainModel == null || this.mainModel == undefined) {
          this.$message('您暂时不能通过线上平台获取该内容，请与您的客户经理联系。（错误编号：17）')
          return
        }
        if (this.isFreeMom && this.mainModel.app_status != 'PREVIEW') {
          this.applyMomRequest()
          return
        }
          this.alertTitle = this.mainModel.app_status === 'PREVIEW' ? '预约纪要' : '获取纪要'
          if (this.mainModel.app_status === 'PREVIEW') {
            this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,'发送纪要：会议结束后4-5个工作日内']
          } else {
            this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,this.chargeInfoContent(false)]
          }
          if (this.isFirstAndMonthClient === true) {
            if (this.mainModel.app_status === 'PREVIEW') {
              this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,'发送时间：会议结束后4-5个工作日内']
            } else {
              this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`]
            }
          }
          this.cancelTitle = '取消'
          this.confirmTitle = '确认'
          this.showBuyMom = true
      },
      applyMomRequest () {
        if (this.requestSigning) {
          return
        }
        var data = {}
        data.conference_publish_id = this.mainModel.id
        data.status = 'SUMMARY_APPLY'
        data.origin = 'PC'
        data.pay_type = this.mainModel.pay_type
        this.requestSigning = true
        goalApi.fullgoalConferenceContactApply(data).then( res=> {
          this.requestSigning = false
          if (res.code === '0000') {
            if (res.data !== null) {
              if (res.data.status === 'SUMMARY_APPLY') {/* 人工审核 */
                if (this.mainModel.app_status === 'COMPLETE') {
                  this.popMomApplied()
                }else {
                  this.popMomAppliedAppoint()
                }
              } else if (res.data.status === 'SUMMARY_BOUGHT') { /* 报名成功*/
                if (this.mainModel.app_status === 'COMPLETE') {
                  this.popMomApproved()
                }else {
                  this.popMomApprovedAppoint()
                }
              } else if (this.mainModel.app_status === 'COMPLETE') {
                  this.$message('获取失败')
              }
              this.getConferenceDetail()
            }
          }
        })
      },
      popMomApply () {
        var tips = `凯盛正在处理您的获取请求</br>若获取成功，相关材料会发送到您的邮箱。</br>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        if (this.mainModel.app_status === 'PREVIEW') {
          tips = `凯盛正在处理您的预约请求</br>若预约成功，相关材料完成整理会发送到您的邮箱。</br>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        }
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel') {
              done()
            }
          }
        }).catch( e=>e)
      },
      popMomSuccess () {
        this.getConferenceDetail()
        if (this.isFirstAndMonthClient === true && this.mainModel.app_status !== 'PREVIEW') {
          this.isMom = true
          this.getConferenceDetail()
          return
        }
        var tips = `获取成功，相关材料会发送到您的邮箱。</br>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        if (this.mainModel.app_status === 'PREVIEW') {
          tips = `预约成功，相关材料完成整理会发送到您的邮箱。</br>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        }
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.mainModel.app_status === 'PREVIEW' ? '确认' :'查看会议纪要',
          showInput: this.mainModel.app_status === 'PREVIEW' ? (this.isDBEmail?false:true) : false,
          inputPlaceholder: '用于接收会议纪要',
          inputErrorMessage: '邮箱格式不正确',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.isMom = true
              this.getConferenceDetail()
              done()
            } else if (action === 'cancel'){
              done()
            }
          }
        }).then(value => {
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      signUpMeet () {
        if (this.mainModel == null || this.mainModel == undefined) {
          this.$message('您暂时不能通过线上平台获取该内容，请与您的客户经理联系。（错误编号：17）')
          return
        }
          this.alertTitle = '报名参会'
          this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,this.chargeInfoContent(true),`会议时间：${this.meetTime}`]
          if (this.isFirstAndMonthClient ===  true) {
            this.tipsArray = [`会议名称：《${this.mainModel.conference_name}》`,`会议时间：${this.meetTime}`]
          }
          this.cancelTitle = '取消'
          this.confirmTitle = '确认'
          this.showSignUpMeet = true
      },
      applyRequest () {
        var data = {}
        data.conference_publish_id = this.mainModel.id
        data.status = 'CONFERENCE_APPLY'
        data.origin = 'PC'
        data.pay_type = this.mainModel.pay_type
        goalApi.fullgoalConferenceContactApply(data).then( res=> {
          if (res.code === '0000') {
            if (res.data !== null) {
              if (res.data.status === 'CONFERENCE_APPLY') {
                this.popConferenceApplied()
              } else if(res.data.status === 'CONFERENCE_APPLY_SUCCEEDED') {
                this.popConferenceApproved()
              }
              this.getConferenceDetail()
            }
          }
        })
      },
      popMomApproved () {
        this.$message('获取成功')
        this.getMomDetail()
        /*
        var message = ''
        if (this.isDBEmail === true) {
          message = '相关材料会发送到：' + this.$store.getters.email
        }else {
          message = '由于您未在凯盛登记邮箱，相关材料无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '获取成功',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
        */
      },
      popMomApplied () {
        var message = ''
        if (this.isDBEmail === true) {
          message = '凯盛正在处理您的获取请求！若获取成功，相关材料会发送到：' + this.defaultEmail
        }else {
          message = '凯盛正在处理您的获取请求！由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '提示',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
      },
      popMomAppliedAppoint () {
        var message = ''
        if (this.isDBEmail === true) {
          message = '凯盛正在处理您的预约请求！若预约成功，会议资料完成整理后，发送到：' + this.defaultEmail
        }else {
          message = '凯盛正在处理您的预约请求！由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '提示',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
      },
      popMomApprovedAppoint () {
        var message = ''
        if (this.isDBEmail === true) {
          message = '相关材料完成整理后会发送到：' + this.defaultEmail
        }else {
          message = '由于您未在凯盛登记邮箱，会议资料完成整理后无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '预约成功',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
      },
      popConferenceApplied () {
        var message = ''
        if (this.isDBEmail === true) {
          message = '凯盛正在处理您的报名请求！若报名成功，会将参会信息发送到：' + this.defaultEmail
        }else {
          message = '凯盛正在处理您的报名请求！由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '提示',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
      },
      popConferenceApproved () {
        var message = ''
        if (this.isDBEmail === true) {
          message = '稍后会将参会信息发送到：' + this.defaultEmail
        }else {
          message = '由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
        }
        this.$msgbox({
          title: '报名成功',
          message: message,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            }
          }
        })
      },
      popApply () {
        var tips = `凯盛正在处理您的报名请求。<br/>若报名成功，您的邮箱将会收到参会信息。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel') {
              this.updateConferenceTaskEmail(instance.value)
              done()
            }
          }
        }).catch( e=>e)
      },
      sendEmail () {
        var data = {}
        data.conference_id = this.id
        goalApi.fullgoalConferenceSendMomMail(data).then( res=> {
          if (res.code === '0000') {
            var message = ''
            if (this.isDBEmail === true) {
              message = '已将会议资料发送到邮箱：' + this.defaultEmail
            }else {
              message = '由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
            }
            this.$msgbox({
              title: '提示',
              message: message,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              dangerouslyUseHTMLString: true,
              confirmButtonText: '确认',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                }
              }
            })
            // if (res.data.check_result === true) {
            //   this.sendNewMomEmail()
            // }else {
            //   this.$message(res.data.message)
            // }
          }else {
            this.$message(res.message)
          }
        })
      },
      sendNewMomEmail () {
        var data = {}
        data.id = this.mainModel.id
        // conference.requireMom(data).then( res=> {
        //   if (res.code === '0000') {
        //     var message = ''
        //     if (this.isDBEmail === true) {
        //       message = '已将会议资料发送到邮箱：' + this.$store.getters.email
        //     }else {
        //       message = '由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
        //     }
        //     this.$msgbox({
        //       title: '提示',
        //       message: message,
        //       showCancelButton: false,
        //       closeOnClickModal: false,
        //       closeOnPressEscape: false,
        //       dangerouslyUseHTMLString: true,
        //       confirmButtonText: '确认',
        //       beforeClose: (action, instance, done) => {
        //         if (action === 'confirm') {
        //           done()
        //         }
        //       }
        //     })
        //   }
        // })
      },
      sendMomEmail () {
        var tips = `事项：发送《${this.mainModel.conference_name}》会议资料，收件邮箱：`
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          showInput: true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel') {
              done()
            }
          }
        }).then(value => {
          this.updateConferenceTaskEmail(value.value)
        }).catch( e=>e)
      },
      popSuccess () {
        var tips = `报名成功，您的邮箱将收到参会信息。<br/>您的邮箱：${this.isDBEmail?this.defaultEmail:''}`
        let email = this.defaultEmail
        this.$msgbox({
          title: '提示',
          message: tips,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确认',
          cancelButtonText: '查看参会信息',
          showInput: this.isDBEmail?false:true,
          inputPlaceholder: '用于接收会议纪要',
          inputValue: email,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done()
            } else if (action === 'cancel'){
              this.popMeetInfo()
              done()
            }
          }
        }).catch( e=>e)
      },
      popMeetInfo () {
        this.showMeetInfo = true
      },
      getConferenceDetail() {
        if (!this.tsid) {
          return
        }
        var form = {}
        form.tsid = this.tsid
        form.extra = 'contact_task_for_contact,materials,conference_summary,consultants'
        goalApi.fullgoalConferenceOne(form).then( res=> {
          if (res.code === '0000') {
            this.mainModel = res.data
            var watermarkString = ''
            if (this.mainModel.fullgoal_user.chinesename && this.mainModel.fullgoal_user.client) {
              watermarkString = `仅供凯盛客户${this.mainModel.fullgoal_user.client.name}-${this.mainModel.fullgoal_user.chinesename}使用`
            }
            if (watermarkString) {
              watermark.set(watermarkString)
            }
            this.id = res.data.id
            // this.changeRightHost(res.data)
            if (this.mainModel != null && this.mainModel != undefined) {
              if (this.mainModel.conference_label) {
                this.mainModel.all_tags = this.mainModel.conference_label.split(',')
              }
              if (this.mainModel.contact_task_for_contact) {
                this.mainModel.my_task = this.mainModel.contact_task_for_contact
              }
              this.mainModel.contentArray = []
              if (this.mainModel.app_status == 'COMPLETE') {
                if (this.isMomApproved === true) {
                  this.getMomDetail()
                }
              }
              if (this.mainModel.my_task) {
                if (this.mainModel.my_task.status == 'COMPLETED' && this.mainModel.is_mom_file_published) {
                  this.getMomDetail()
                }
              }
              var contents = res.data.content.split('@@@')
              contents.forEach( (item) => {
                if(this.tool.isNullOrEmpty(item) === false) {
                  if (item.indexOf('###') !== -1) {
                    var itemModel = {}
                    itemModel.title = item.slice(0,item.indexOf('###'))
                    itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                    itemModel.imageTextArray = []

                    var imgTextArray = itemModel.content.split('<img>')
                    imgTextArray.forEach( imgText => {
                      var imgTextModel = {}
                      if (imgText.indexOf('</img>') !== -1) {
                        var  subImgTextArray = imgText.split('</img>')
                        if (subImgTextArray.length === 2) {
                          for (var i = 0;i < subImgTextArray.length;i++) {
                            var imgSubTextModel = {}
                            if (i === 0) {
                              imgSubTextModel.img = subImgTextArray[0]
                              itemModel.imageTextArray.push(imgSubTextModel)
                            }else {
                              imgSubTextModel.text = subImgTextArray[1]
                              itemModel.imageTextArray.push(imgSubTextModel)
                            }
                          }
                        }else {
                          imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                          itemModel.imageTextArray.push(imgTextModel)
                        }
                      }else{
                        imgTextModel.text = imgText
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    })
                    this.mainModel.contentArray.push(itemModel)
                  }
                }
              })
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #conference_detail_full{
    margin: 0 auto;
  }
  .conference-detail-contain-full {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0px;
    width: calc(100% - 60px);
    background-color: white;
    // border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .title {
    font-size: 17px;
    color: #272727;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
    font-weight: 500;
  }

  .segment-nav {
    width:60%;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #4880FF;
    /*width: 550px;*/
    height: 38px;
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .segment-item-active {
    background-color: #4880FF;
    color: #FFFFFF;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    /*width: 275px;*/
    width:100%;
    cursor: pointer;
  }

  .segment-item-normal {
    background-color: #FFFFFF;
    color: #4880FF;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    /*width: 275px;*/
    width:100%;
    cursor: pointer;
  }

  .title-nav-view-ss {
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flag-line-ss {
    margin-left: 0px;
    background-color: #4880FF;
    width: 6px;
    height: 18px;
  }

  .nav-title {
    color: #000000;
    font-size: 17px;
    font-weight: 500;
    margin-left: 15px;
  }

  .detail-main {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
  }

  .summary-main {
    margin-top: 30px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 30px;
    -webkit-user-select: none;
    white-space: pre-line;
    text-align: left;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 60px;
  }

  .footer-view-fullgoal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.09);
  }
  .ztfzNoUse {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select:none;
    -moz-user-select: none;
  }
  .right-option-btn-full {
    left: calc(100% - 50px);
    top: calc(50% - 60px);
    width: 20px;
    position: fixed;
    z-index: 1000;
  }
  .right-seg-btn-full {
    left: calc(100% - 75px);
    width: 45px;
    position: fixed;
    top: calc(50% - 147px);
    z-index: 1000;
  }
  .free_icon {
    padding: 2px 5px;
    background-color: #D6FFE3;
    color: #32B94C;
    font-size: 16px;
  }
  .not_free_icon {
    padding: 2px 5px;
    background-color: #FFE3E3;
    color: #FF7510;
    font-size: 16px;
  }
  .monthly_icon {
    padding: 2px 5px;
    background-color: #FFF6B7;
    color: #DEA926;
    font-size: 16px;
  }
  .purchased_icon {
    padding: 2px 5px;
    background-color: #D8F1FA;
    color: #008BDE;
    font-size: 16px;
  }
  .detail_tags_main_view {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 48px);
    margin-top: 4px;
    margin-left: 24px;
  }
  .detail_tags_view_label {
    padding: 4px 10px;
    margin-right: 6px;
    margin-top: 6px;
    border: 1px solid #1E459D;
    border-radius: 4px;
    font-size: 12px;
    color: #1E459D;
    &:hover {
      background-color: #f0f0f0;
    }
    cursor: pointer;
  }
</style>
