<template>
  <div class="expert-list">
    <img style="position: absolute;width: 280px;height: 210px;top: calc(50% - 120px);left: calc(50% - 140px);" v-show="loading && list.length === 0" src="../../assets/img/loading1.gif" />
    <img v-if="list.length > 0" class="img-expert-back" src="../../assets/img/expert_back1.svg" />
    <div v-if="tool.isNullOrEmpty(info.introduce) === true" style="margin-top: 95px;"></div>
    <topInfoView v-if="tool.isNullOrEmpty(info.introduce) === false" :info="info" style="margin-top: 95px;"></topInfoView>
    <div v-for="(item, index) in list" :key='index'>
      <categoryCell @addCar="addEvent" :category='item' @openEvent="openCategory"></categoryCell>
    </div>
    <!-- <div v-if="selectList.length > 0" class="gap-btm"></div> -->
    <div v-if="list.length > 0" class="footer-view-ad-ss">
      <img style="width: 168px;height: 51px;margin-top: 145px;" src="../../assets/img/listbottomicon.png" />
    </div>
    <img @click="backTop" v-if="btnFlag && list.length > 0" class="go-top-bt" src="../../assets/img/dingbu.png" />
    <expertBottomMenu v-show="loading === false" @sendCar='commitEvent' @popCar='showCarList' :num="selectList.length" style="position: fixed;bottom: env(safe-area-inset-bottom);"></expertBottomMenu>
    <expertShopCar @deleteCarIndex='deleteCarItem' @deleteCar='deleteAll' :list="selectList" @hiddenCar='hiddenCarList' v-model="showCar"></expertShopCar>
    <expertAlert @commitConfirm='commitZZConfirm' @hiddenCommitView='hiddenCommitAlert' v-model="showCommitInfo"></expertAlert>
    <expertSuccess v-model="showSuccess" @closeSuccess='closeSuccessTip'></expertSuccess>
  </div>
</template>

<script>
  import topInfoView from '../../components/expert_top_view.vue'
  import categoryCell from '../../components/expert_category_cell.vue'
  import expertBottomMenu from '../../components/expert_bottom_menu.vue'
  import expertShopCar from '../../components/expert_shop_car.vue'
  import expertAlert from '../../components/expert_commit_info_alert.vue'
  import expertSuccess from '../../components/expert_succes_tip.vue'
  import expertApi from '../../api/expert_list.js'
  import sharesdk from '../../utils/wxshare.js'
  export default {
    components: {
      topInfoView,
      categoryCell,
      expertBottomMenu,
      expertShopCar,
      expertAlert,
      expertSuccess
    },
    data () {
      return {
          list: [],
          btnFlag: false,
          scrollY: 0,
          scrollYP: 0,
          showCar:false,
          className: 'modalOpen',
          info: {
            creator: '',
            introduce: ''
          },
          showCommitInfo:false,
          showSuccess:false,
          require_contact_to_submit_identity:true,
          title:'',
          loading: true
      }
    },
    methods: {
      addEvent() {
      },
      scollEvent () {
        let scollToTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.scrollY = scollToTop
        this.scrollYP = document.documentElement.scrollTop || document.body.scrollTop
        if (this.scrollY> 60) {
          this.btnFlag = true
        }else {
          this.btnFlag = false
        }
      },
      backTop () {
        let timer = setInterval(() => {
          let ispeed = Math.floor(-this.scrollY / 5)
          this.scrollY = this.scrollY + ispeed
          document.documentElement.scrollTop = this.scrollY
          document.body.scrollTop = this.scrollY
          if (this.scrollY=== 0) {
            clearInterval(timer)
          }
        }, 16)
      },
      showCarList () {
        this.showCar = !this.showCar
      },
      openCategory (val) {
        this.list.forEach( (item,i) => {
          if (i === val.index) {
            item.open = !item.open
          }
        })
      },
      hiddenCarList () {
        this.showCar = false
      },
      hiddenCommitAlert () {
        this.showCommitInfo = false
      },
      deleteAll () {
        this.list.forEach( categroyItem => {
          categroyItem.list.forEach( item => {
            item.isGou = false
          })
        })
        this.showCar = false
      },
      deleteCarItem (opItem) {
        this.list.forEach( categroyItem => {
          categroyItem.list.forEach( item => {
            if (opItem.recommend_id === item.recommend_id) {
              item.isGou = false
            }
          })
        })
        if (this.selectList.length === 0) {
          this.showCar = false
        }
      },
      commitEvent () {
        this.showCar = false
        if (this.require_contact_to_submit_identity === true) {
          this.showCommitInfo = true
        }else {
          this.commitZZConfirm()
        }
      },
      commitZZConfirm (name) {
        this.showCommitInfo = false
        var sendData = {}
        sendData.selected_recommend_ids = []
        this.selectList.forEach( item=> {
          sendData.selected_recommend_ids.push(item.recommend_id)
        })
        if (this.tool.isNullOrEmpty(name)) {
          sendData.contact_identity = ''
        }else {
          sendData.contact_identity = name
        }
        expertApi.sendSelectList(sendData).then( res=> {
          if (res.code === '0000') {
            this.showSuccess = true
            this.deleteAll()
          }
        })
      },
      closeSuccessTip () {
        this.showSuccess = false
      },
      getList () {
        this.loading = true
        expertApi.getExpertList(null).then( res=> {
          this.loading = false
          if (res.code === '0000') {
            this.info.creator = res.data.creator
            this.info.introduce = res.data.introduce
            this.require_contact_to_submit_identity = res.data.require_contact_to_submit_identity
            this.title = res.data.title
            if (this.tool.isNullOrEmpty(this.title) === true) {
              this.title = '凯盛融英专家推荐'
            }
            if (this.tool.isNullOrEmpty(this.info.introduce) === true) {
              this.info.introduce = '这是凯盛项目人员为您推荐的专家。'
            }
            res.data.list.forEach( (categoryItem,i) => {
              categoryItem.index = i
              if (i === 0) {
                categoryItem.open = true
              }else {
                categoryItem.open = false
              }
              categoryItem.list.forEach( item => {
                item.legacy_email_type = res.data.legacy_email_type
                if (item.legacy_email_type === 'OLD') {
                  item.workEx = []
                  let company = '公司：' + item.company
                  item.workEx.push(company)
                  let position = '职位：' + item.position
                  item.workEx.push(position)
                }
                item.open = false
                item.isGou = false
              })
              this.list.push(categoryItem)
            })
            let url = location.href.split('#')[0]
            var dess = ""
            if (this.tool.isNullOrEmpty(this.info.introduce) === false) {
              dess = this.info.introduce
            } else {
              dess = '由凯盛项目人员' + res.data.creator + '为您整理汇总。'
            }
            document.title = this.title
            let obj = {
              title: this.title,
              des: dess,
              linkurl: location.href,
              img: 'http://cdn.capvision.cn/media/conference/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210220095321.png'
            }
            sharesdk.getJSSDK(url,obj)
          }
        })
      }
    },
    computed: {
      selectList: function() {
        var selList = []
        this.list.forEach( categroyItem => {
          categroyItem.list.forEach( item => {
            if (item.isGou) {
              selList.push(item)
            }
          })
        })
        return selList
      }
    },
    mounted () {
      window.addEventListener('scroll', this.scollEvent)
      let token = this.$route.query.token
      if (this.tool.isNullOrEmpty(token) === false) {
        this.$store.dispatch('view/updataExpertListToken', token)
        this.getList()
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.scollEvent)
    },
    watch:{
      showCar(bool) {
        this.$nextTick(() => {
          document.getElementsByTagName('body')[0].className = bool ? 'noscroll' : ''
          document.documentElement.scrollTop = this.scrollYP
          document.body.scrollTop = this.scrollYP
        })
      }
    }
  }
</script>

<style>
  .noscroll{
    overflow: hidden;
    position: fixed;
  }
  .expert-list {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
  .img-expert-back {
    z-index: -1;
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
  }
  .gap-btm {
    height: 75px;
    width: 100%;
  }
  .go-top {
    bottom: calc(env(safe-area-inset-bottom) + 40px);
    width: 46px;
    height: 66px;
    right: 0px;
    position: fixed;
  }
  .go-top-bt {
    bottom: calc(env(safe-area-inset-bottom) + 80px);
    width: 46px;
    height: 66px;
    right: 0px;
    position: fixed;
  }
  .footer-view-ad-ss{
    z-index: -1001;
    background: linear-gradient(180deg, #399CFF 0%, #3755FD 100%);
    opacity: 1;
    height: 335px;
    width: 100%;
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
