<template>
  <div id="conference">
    <div id="main_conference">
      <zeroIndustryMenus :clearFlag="tool.isNullOrEmpty(searchConferceKeyword) == false || searchIndustrying == true" @screenIndustry_ids='screenIndustryIds' :haveDataIndustrys="industry_ids_have"></zeroIndustryMenus>
      <!-- <industryMenu @screenIndustry_ids='screenIndustryIds' :haveDataIndustrys="industry_ids_have"></industryMenu> -->
      <div style="cursor: pointer;" @click="backConference()" v-if="tool.isNullOrEmpty(searchConferceKeyword) == false || searchIndustrying == true" class="search_bav">
        <img style="height: 20px;width: 20px;" src="../assets/img/conference_nav_back.png" />
        <p style="color: #0745D1;font-size: 15px;margin-left: 5px;">返回行业列表</p>
      </div>
      <listTopMenu v-else :allOpen="allOpen" :num="count" @chargeType='screenChargeType' @changeEvent='changeAllOpen' @timeType='timeTypeEvent' @typeType='typeTypeEvent' @categoryType='categoryTypeEvent'></listTopMenu>
      <listTopTable v-if="isNeedTopTable()" :list="topList" @openEventList='openTEventOnly' @closeEventList='closeTEvent'></listTopTable>
      <listConference v-loading="loadingFresh" :list="list" @openEventList='openEventOnly' @closeEventList='closeEvent'></listConference>
      <button v-if="noMore === false" @click="loadMore()" style="margin-top: 12px;cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;margin-bottom: 20px;">点击加载更多</button>
      <div v-if="noMore === true && list.length > 0" style="font-weight: bold;color: #333333;font-size: 14px;padding-top: 12px;padding-bottom: 17px;">没有更多了</div>
    </div>
  </div>
</template>

<script>
import zeroIndustryMenus from '../components/zero_industry_menu.vue'
// import industryMenu from '../components/industry_menu.vue'
import listTopMenu from '../components/list_top_menu.vue'
import listTopTable from '../components/list_topic.vue'
import topic from '../api/topic.js'
import listConference from '../components/list_conference.vue'
import conference from '../api/conference.js'
import elementResizeDetectorMaker from 'element-resize-detector'
import { mapGetters } from "vuex"
export default{
  components: {
    zeroIndustryMenus,
    listTopMenu,
    listConference,
    listTopTable
  },
  computed: {
    disabled () {
      return this.loading || this.noMore
    },
    ...mapGetters('view', {
      searchConferenceTag: 'searchConferenceTag',
      searchIndustryId: 'searchIndustryId'
    })
  },
  watch: {
    searchConferenceTag: {
      handler: function() {
        if (this.tool.isNullOrEmpty(this.searchConferenceTag) == false) {
          this.searchConferceKeyword = this.searchConferenceTag
          this.requsetSearchTag(true)
          this.$store.dispatch('view/changeSearchConferenceTag', null)
        }
      },
      deep: true
    },
    searchIndustryId: {
      handler: function() {
        if (this.tool.isNullOrEmpty(this.searchIndustryId) == false) {
          this.searchIndustrying = true
          this.sub_industry_id = this.searchIndustryId
          this.industry_ids = ''
          this.getList(true)
          this.$store.dispatch('view/changeSearchIndustryId', null)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      industry_ids: '',
      sub_industry_id: '',
      charge_type: '',
      contact_id: '',
      extra: 'contact_task_for_contact,consultants,materials,conference_summary',
      list: [],
      count: 0,
      allOpen: false,
      industry_ids_have: [],
      timeType: '',
      conferenceType: '',
      conferenceCategory: '',
      page: 1,
      size: 50,
      loading: false,
      noMore: true,
      topList: [],
      zeroIndustrys:[],
      loadingFresh: false,
      searchConferceKeyword: '',
      searchIndustrying: false
    }
  },
  created () {
    window.addEventListener('scroll',this.scroll,true)
  },
  mounted() {
    this.noMore = true
    this.getList(true)
    this.getMainH()
    this.getTopList()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll,true)
  },
  methods: {
    backConference() {
      this.searchIndustrying = false
      this.sub_industry_id = ''
      this.searchConferceKeyword = ''
      this.getList(true)
    },
    scroll () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.body.scrollHeight
      if(scrollTop+windowHeight+1>=scrollHeight){
        console.log('moress')
        this.loadMore()
      }
    },
    isNeedTopTable () {
      if (this.tool.isNullOrEmpty(this.industry_ids) === false) {
        return false
      }
      if (this.tool.isNullOrEmpty(this.charge_type) === false) {
        return false
      }
      if (this.timeType !== 'all_time' && this.timeType !== '') {
        return false
      }
      if (this.conferenceType !== 'all_cf' && this.conferenceType !== '') {
        return false
      }
      if (this.conferenceCategory !== 'all_cf' && this.conferenceCategory !== '') {
        return false
      }
      return true
    },
    loadMore () {
      if (this.noMore == false && this.loading == false) {
        this.loading = true
        if (this.tool.isNullOrEmpty(this.searchConferceKeyword) == false) {
          this.requsetSearchTag(false)
        }else {
          this.getList(false)
        }
      }
    },
    getMainH () {
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById("main_conference"),(element)=>{
        this.$nextTick(()=>{
          this.$store.dispatch('view/changeLeftMainH', element.offsetHeight)
        })
      })
    },
    timeTypeEvent (val) {
      console.log(val)
      this.timeType = val
      this.getList(true)
    },
    typeTypeEvent (val) {
      console.log(val)
      this.conferenceType = val
      this.getList(true)
    },
    categoryTypeEvent (val) {
      this.conferenceCategory = val
      this.getList(true)
    },
    openTEventOnly (val) {
      this.closeEvent()
      this.topList.forEach( (res, index) => {
          if (index === val) {
            res.isOpen = true
            this.changeRightHost(res)
          } else {
            res.isOpen = false
          }
      })
    },
    closeTEvent () {
      this.topList.forEach( (res) => {
        res.isOpen = false
      })
    },
    openEventOnly (val) {
      this.closeTEvent()
      this.list.forEach( (res, index) => {
          if (index === val) {
            res.isOpen = true
            // this.changeRightHost(res)
          } else {
            res.isOpen = false
          }
      })
    },
    closeEvent () {
      this.list.forEach( (res) => {
        res.isOpen = false
      })
    },
    changeRightHost (val) {
      console.log(val)
      // var hostArray = []
      // if (val !== 0) {
      //   if (val.servant !== null) {
      //     val.servant.employee.position = '主持人'
      //     hostArray.push(val.servant)
      //   }
      // }
      // this.$store.dispatch('view/changeHostArray', hostArray)
    },
    changeAllOpen () {
      this.allOpen = !this.allOpen
      this.topList.forEach( (res, index) => {
          if (index === 0 || this.allOpen === true) {
            res.isOpen = true
            if (this.allOpen === true) {
              this.changeRightHost(0)
            } else {
              this.changeRightHost(res)
            }
          } else {
            res.isOpen = false
          }
      })
      this.list.forEach( (res, index) => {
          if ((index === 0 && this.topList.length === 0) || this.allOpen === true) {
            res.isOpen = true
            if (this.allOpen === true) {
              this.changeRightHost(0)
            } else {
              this.changeRightHost(res)
            }
          } else {
            res.isOpen = false
          }
      })
    },
    screenIndustryIds(val) {
      this.searchConferceKeyword = ''
      this.sub_industry_id = ''
      this.searchIndustrying = false
      this.industry_ids = val
      this.getList(true)
    },
    screenChargeType(val) {
      if (val === 'MY' && this.$store.getters.uid !== null) {
        this.contact_id = this.$store.getters.uid
        this.charge_type = val
      } else {
        this.contact_id = ''
        this.charge_type = val
      }
      this.getList(true)
    },
    getTopList () {
      var sendData = {}
      sendData.is_stick = '1'
      sendData.extra = 'topic_hot_contact,topic_contacts,create_employee.user,my_task,servant.employee.user,topic_consultants'
      topic.getTopicsList(sendData).then( res=> {
        if (res.code === '0000') {
          this.topList.length = 0
          res.data.list.forEach( (item,index)=> {
            if (index === 0 || this.allOpen === true) {
              item.isOpen = true
              this.changeRightHost(item)
            } else {
              item.isOpen = false
            }
            this.topList.push(item)
          })
        }
      })
    },
    requsetSearchTag(isFresh) {
      if (isFresh == true) {
        this.loadingFresh = true
        this.noMore = true
      }
      if (isFresh === true) {
        this.page = 1
        this.list = []
      }
      var data = {}
      data.keyword = this.searchConferceKeyword
      data.page = this.page
      data.size = this.size
      data.extra = this.extra
      data.sort = "is_stick desc, FIELD(conference.`status`,'PREVIEW','COMPLETE') asc, start_time desc"
      // data.resource_types = 'CONFERENCE'
      conference.searchTags(data).then( res=> {
        this.loadingFresh = false
        this.loading = false
        if(res.code == '0000') {
          if(res.data) {
            this.count = res.data.count
            if (res.data.list.length < 50) {
              this.noMore = true
            }else {
              this.page++
              this.noMore = false
              console.log('22')
            }
            res.data.list.forEach( (item, index) => {
              if ((index === 0 && isFresh === true && this.topList.length === 0) || this.allOpen === true) {
                item.isOpen = true
              } else {
                item.isOpen = false
              }
              this.list.push(item)
            })
          }
        }
      })
    },
    getList (isFresh) {
      if (isFresh == true) {
        this.loadingFresh = true
        this.noMore = true
      }
      var data = {}
      if (this.tool.isNullOrEmpty(this.industry_ids) === false) {
        data['project_industry.taxonomy_term.parent_term.parentid_in'] = this.industry_ids
      }
      if (this.tool.isNullOrEmpty(this.sub_industry_id) === false) {
        data['project_industry.taxonomy_term.parent_term.parentid_in'] = this.sub_industry_id
      }
      data.extra = this.extra
      data.sort = 'project.starttime desc,project.endtime desc'
      // data.sort = "is_stick desc, FIELD(conference.`status`,'PREVIEW','COMPLETE') asc, start_time desc"
      // data.statuses = 'COMPLETE,PREVIEW'
      if (this.conferenceType !== 'all_cf' && this.tool.isNullOrEmpty(this.conferenceType) === false) {
        data.conference_type_in = this.conferenceType
      }
      if (this.conferenceCategory !== 'all_cf' && this.tool.isNullOrEmpty(this.conferenceCategory) === false) {
        data.category = this.conferenceCategory
      }
      // var timestamp = new Date().getTime()
      // var second = Math.floor(timestamp/(1000))
      if(this.timeType === 'one_month') {
        data['project.start_time_passed_date_lte'] = '90'
      }else if(this.timeType === 'three_month') {
        data['project.start_time_passed_date_lte'] = '180'
      }else if(this.timeType === 'six_month') {
        data['project.start_time_passed_date_lte'] = '360'
      }else if(this.timeType == 'year_month') {
        data['project.start_time_passed_date_gte'] = '365'
      }
      if (this.tool.isNullOrEmpty(this.charge_type) === false) {
        if (this.charge_type === 'NOT_FREE') {
          data.is_free_now = false
        } else if (this.charge_type === 'FREE_AFTER_180_DAYS') {
          data.is_free_now = true
        } else if (this.tool.isNullOrEmpty(this.contact_id) === false) {//我的会议
          // data.contact_id = this.contact_id
          // data.contact_statuses = 'CONFERENCE_APPLIED,CONFERENCE_APPROVED,MOM_APPLIED,MOM_APPROVED'
          data['contact_task.id_is_null'] = false
          data['contact_task.status_in'] = 'CONFERENCE_APPLY_SUCCEEDED,SUMMARY_BOUGHT,COMPLETED'
        }
      }
      if (isFresh === true) {
        this.page = 1
        this.list = []
      }
      data.size = this.size
      data.page = this.page
      conference.getConferenceList(data).then(res => {
        this.loadingFresh = false
        this.loading = false
        if (isFresh === true) {
          this.list = []
        }
        if (res.data) {
          this.count = res.data.count
          // if (this.industry_ids_have.length === 0) {
          //   this.industry_ids_have = res.data.aggregations.industry_id
          // }
          if (res.data.list.length < 50) {
            this.noMore = true
          }else {
            this.page++
            this.noMore = false
          }
          res.data.list.forEach( (item, index) => {
            if ((index === 0 && isFresh === true && this.topList.length === 0) || this.allOpen === true) {
              item.isOpen = true
              this.changeRightHost(item)
            } else {
              item.isOpen = false
            }
            if (item.conference_label) {
              item.all_tags = item.conference_label.split(',')
            }
            if (item.contact_task_for_contact) {
              item.my_task = {
                ...item.contact_task_for_contact
              }
            }else {
              item.my_task = {}
            }
            this.list.push(item)
          })
        }
      })
    }
  }
}
</script>
<style>
  #conference{
    padding-top: 0px;
  }
  #main_conference{
    width: calc(100% - 320px);
  }
  .search_bav {
    margin-top: 0px;
    margin-left: 0px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 15px);
  }
</style>
