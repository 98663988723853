<template>
  <div id="research">
    <div id="main_research">
      <industryMenu :clearFlag="tool.isNullOrEmpty(searchConferceKeyword) == false || searchIndustrying == true" @screenIndustry_ids='screenIndustryIds' :haveDataIndustrys="industry_ids_have"></industryMenu>
      <div style="cursor: pointer;" @click="backConference()" v-if="tool.isNullOrEmpty(searchConferceKeyword) == false || searchIndustrying == true" class="search_bav">
        <img style="height: 20px;width: 20px;" src="../assets/img/conference_nav_back.png" />
        <p style="color: #0745D1;font-size: 15px;margin-left: 5px;">返回行业列表</p>
      </div>
      <listTopMenu v-else :allOpen="allOpen" :num="count" isNeedType=false @changeEvent='changeAllOpen'></listTopMenu>
      <listResearch v-loading="loadingFresh" :list="list" @openEventList='openEventOnly' @closeEventList='closeEventList'></listResearch>
      <button v-if="noMore === false" @click="loadMore()" style="margin-top: 12px;cursor: pointer;margin-right: 15px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;margin-bottom: 20px;">点击加载更多</button>
      <div v-if="noMore === true && list.length > 0" style="font-weight: bold;color: #333333;font-size: 14px;padding-top: 12px;padding-bottom: 17px;">没有更多了</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import industryMenu from '../components/industry_menu.vue'
  import listTopMenu from '../components/list_top_menu_research.vue'
  import listResearch from '../components/list_research.vue'
  import research from '../api/research_report.js'
  import conference from '../api/conference.js'
  import elementResizeDetectorMaker from 'element-resize-detector'
  export default {
    components: {
      industryMenu,
      listTopMenu,
      listResearch
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
      disabled () {
        return this.loading || this.noMore
      },
      ...mapGetters('view', {
        searchConferenceTag: 'searchConferenceTag',
        searchIndustryId: 'searchIndustryId'
      })
    },
    watch: {
      searchConferenceTag: {
        handler: function() {
          if (this.tool.isNullOrEmpty(this.searchConferenceTag) == false) {
            this.searchConferceKeyword = this.searchConferenceTag
            this.requsetSearchTag(true)
            this.$store.dispatch('view/changeSearchConferenceTag', null)
          }
        },
        deep: true
      },
      searchIndustryId: {
        handler: function() {
          if (this.tool.isNullOrEmpty(this.searchIndustryId) == false) {
            this.searchIndustrying = true
            this.sub_industry_id = this.searchIndustryId
            this.industry_ids = ''
            this.getList(true)
            this.$store.dispatch('view/changeSearchIndustryId', null)
          }
        },
        deep: true
      }
    },
    data () {
      return {
        industry_ids: '',
        sub_industry_id: '',
        industry_ids_have: [],
        list: [],
        count: 0,
        allOpen: false,
        extra: 'research_contacts,create_employee.user,my_task,servant.employee.user',
        page: 1,
        size: 50,
        loading: false,
        noMore: true,
        searchIndustrying: false,
        loadingFresh: false,
        searchConferceKeyword: ''
      }
    },
    created () {
      window.addEventListener('scroll',this.scroll,true)
    },
    mounted() {
      this.noMore = true
      this.getList(true)
      this.getMainH()
    },
    destroyed() {
      window.removeEventListener('scroll', this.scroll,true)
    },
    methods: {
      backConference() {
        this.searchIndustrying = false
        this.sub_industry_id = ''
        this.searchConferceKeyword = ''
        this.getList(true)
      },
      requsetSearchTag(isFresh) {
        if (isFresh == true) {
          this.loadingFresh = true
          this.noMore = true
        }
        if (isFresh === true) {
          this.page = 1
          this.list = []
        }
        var data = {}
        data.keyword = this.searchConferceKeyword
        data.page = this.page
        data.size = this.size
        data.extra = this.extra
        data.sort = 'is_stick desc, research.publish_time desc'
        data.resource_types = 'RESEARCH'
        conference.searchTags(data).then( res=> {
          this.loadingFresh = false
          this.loading = false
          if(res.code == '0000') {
            if(res.data) {
              this.count = res.data.count
              if (res.data.list.length < 50) {
                this.noMore = true
              }else {
                this.page++
                this.noMore = false
                console.log('22')
              }
              res.data.list.forEach( (item, index) => {
                if ((index === 0 && isFresh === true) || this.allOpen === true) {
                  item.isOpen = true
                  this.changeRightHost(item)
                } else {
                  item.isOpen = false
                }
                this.list.push(item)
              })
            }
          }
        })
      },
      scroll () {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        var scrollHeight = document.body.scrollHeight
        if(scrollTop+windowHeight>=scrollHeight){
          this.loadMore()
        }
      },
      loadMore () {
        if (this.noMore === false && this.loading === false) {
          this.loading = true
          this.getList(false)
        }
      },
      getMainH () {
        const erd = elementResizeDetectorMaker()
        erd.listenTo(document.getElementById("main_research"),(element)=>{
          this.$nextTick(()=>{
            this.$store.dispatch('view/changeLeftMainH', element.offsetHeight)
          })
        })
      },
      screenIndustryIds(val) {
        this.searchConferceKeyword = ''
        this.sub_industry_id = ''
        this.searchIndustrying = false
        this.industry_ids = val
        this.getList(true)
      },
      getList (isFresh) {
        var data = {}
        data.extra = this.extra
        data.sort = 'is_stick desc, research.publish_time desc'
        data.statuses = 'PUBLISH'
        if (isFresh === true) {
          this.page = 1
          this.list = []
        }
        if (this.tool.isNullOrEmpty(this.industry_ids) === false) {
          data.industry_ids = this.industry_ids
        }
        if (this.tool.isNullOrEmpty(this.sub_industry_id) === false) {
          data.sub_industry_id = this.sub_industry_id
        }
        data.size = this.size
        data.page = this.page
        research.getResearchesList(data).then(res => {
          this.loading = false
          if (res.data) {
            this.count = res.data.count
            if (this.industry_ids_have.length === 0) {
              this.industry_ids_have = res.data.aggregations.industry_id
            }
            if (res.data.list.length < 50) {
              this.noMore = true
            }else {
              this.page++
              this.noMore = false
            }
            res.data.list.forEach( (item, index) => {
              if ((index === 0 && isFresh === true) || this.allOpen === true) {
                item.isOpen = true
                this.changeRightHost(item)
              } else {
                item.isOpen = false
              }
              this.list.push(item)
            })
          }
        })
      },
      changeRightHost (val) {
      var hostArray = []
      if (val !== 0) {
        if (val.servant !== null) {
          val.servant.employee.position = '研究员'
          hostArray.push(val.servant)
        }
      }
      this.$store.dispatch('view/changeHostArray', hostArray)
      },
      openEventOnly (val) {
        this.list.forEach( (res, index) => {
            if (index === val) {
              res.isOpen = true
              this.changeRightHost(res)
            } else {
              res.isOpen = false
            }
        })
      },
      closeEventList () {
        this.list.forEach( (res) => {
          res.isOpen = false
        })
      },
      changeAllOpen () {
        this.allOpen = !this.allOpen
        this.list.forEach( (res, index) => {
            if (index === 0 || this.allOpen === true) {
              res.isOpen = true
              if (this.allOpen === true) {
                this.changeRightHost(0)
              } else {
                this.changeRightHost(res)
              }
            } else {
              res.isOpen = false
            }
        })
      }
    }
  }
</script>

<style>
  #research {
    padding-top: 0px;
  }
  #main_research{
    width: calc(100% - 320px);
  }
  .search_bav {
    margin-top: 0px;
    margin-left: 0px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 15px);
  }
</style>
