import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import view from './modules/view'
import getters from './getter.js'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    view
  },
  getters
})
