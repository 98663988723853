import * as dd from 'dingtalk-jsapi'
export default {
  ddShowMenu: function(title, img, url, content) {
    dd.ready(function() {
      dd.biz.navigation.setRight({
        show: true,
        control: true,
        text: '...', //空字符串表示显示默认文本
        onSuccess: function() {
          dd.biz.util.share({
            type: 0, //分享类型，0:全部组件 默认； 1:只能分享到钉钉；2:不能分享，只有刷新按钮
            url: url,
            content: content,
            title: title,
            image: img,
            onSuccess: function() {},
            onFail: function() {}
          })
        },
        onFail: function() {}
      });
    })
  }
}
