<template>
  <div id="list-top-menu">
    <div class="left-option-menu">
      <el-checkbox v-model="menus[0].isSel" @change="checkIndex(menus[0])">
        {{menus[0].title}}
      </el-checkbox>
      <el-checkbox style="margin-left: 10px;" v-model="menus[1].isSel" @change="checkIndex(menus[1])">
      {{menus[1].title}}
      </el-checkbox>
     <el-dropdown>
        <el-button style="background-color: transparent;border: transparent;padding: 0;margin-left: 40px;">
          {{ timeTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,indexTime) in timeMenus" :key="indexTime" :data="item" @click.native="timeEvent(item)">{{ item.title }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown>
        <el-button style="background-color: transparent;border: transparent;padding: 0;margin-left: 40px;">
          {{ typeTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  v-for="(item,indexType) in typeMenus" :key="indexType" :data="item" @click.native="typeEvent(item)">{{ item.title }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
<!--      <el-dropdown>
        <el-button style="background-color: transparent;border: transparent;padding: 0;margin-left: 40px;">
          {{ categoryTitle }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  v-for="(item,indexCategory) in categoryMenus" :key="indexCategory" :data="item" @click.native="categoryEvent(item)">{{ item.title }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="right-option-menu">
      <el-tooltip class="item" effect="dark" :content="allOpen ? '仅展开一个卡片' : '展开所有卡片'" placement="top-start">
        <div class="btn-view" @click="changeMenu">
          <div class="line"></div>
          <img class="btn-icon" src="../assets/img/change_icon.png" />
        </div>
      </el-tooltip>
<!--      <div class="num-view">
        <label class="num-tip1">共</label>
        <label class="num-text">{{num}}</label>
        <label class="num-tip2">条<span class="responsive-ipad-show">内容</span></label>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
    allOpen: Boolean
  },
  data () {
    return {
      menus: [{ title: '全部会议', isSel: true ,charge_type: ''},
      { title: '我的会议', isSel: false ,charge_type: 'MY'}],
      timeMenus: [],
      timeTitle: '全部时间',
      typeMenus: [],
      typeTitle: '全部类型',
      categoryMenus: [],
      categoryTitle: '会议团队'
    }
  },
  mounted() {
    this.timeMenus = [{ title: '3个月内', isSel: false ,charge_type: 'one_month'},
        { title: '6个月内', isSel: false ,charge_type: 'three_month'},
        { title: '1年内', isSel: false ,charge_type: 'six_month'},
        { title: '1年前', isSel: false ,charge_type: 'year_month'},
        { title: '全部时间', isSel: true ,charge_type: 'all_time'}]
    this.typeMenus = [{ title: '电话会议', isSel: false ,charge_type: '电话会议'},
        { title: '线下会议', isSel: false ,charge_type: '线下会议'},
        { title: '视频会议', isSel: false ,charge_type: '视频会议'},
		{ title: '视频培训', isSel: false ,charge_type: '视频培训'},
		{ title: '线下培训', isSel: false ,charge_type: '线下培训'},
        { title: '全部类型', isSel: true ,charge_type: 'all_cf'}]
        this.categoryMenus = [{ title: '一级会议', isSel: false ,charge_type: 'FIRST'},
		{ title: '二级会议', isSel: false ,charge_type: 'SECOND'},
        { title: '会议团队', isSel: true ,charge_type: 'all_cf'}]
  },
  methods: {
    timeEvent (item) {
      this.timeMenus.forEach(data => {
        if (item.title === data.title) {
          data.isSel = true
          this.timeTitle = data.title
        } else {
          data.isSel = false
        }
        })
        this.$emit('timeType', item.charge_type)
    },
    typeEvent (item) {
      this.typeMenus.forEach(data => {
        if (item.title === data.title) {
          data.isSel = true
          this.typeTitle = data.title
        } else {
          data.isSel = false
        }
        })
        this.$emit('typeType', item.charge_type)
    },
    categoryEvent (item) {
      this.categoryMenus.forEach(data => {
        if (item.title === data.title) {
          data.isSel = true
          this.categoryTitle = data.title
        } else {
          data.isSel = false
        }
        })
        this.$emit('categoryType', item.charge_type)
    },
    checkIndex (item) {
      this.menus.forEach(data => {
        if (item.title === data.title) {
          data.isSel = true
        } else {
          data.isSel = false
        }
        })
        this.$emit('chargeType', item.charge_type)
    },
    changeMenu () {
      this.$emit('changeEvent')
    }
  }
}
</script>

<style>
  #list-top-menu {
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
    display: flex;
    flex-direction: row;
    align-items: center;
    /*height: 50px;*/
    min-height: 50px;
  }

  .el-checkbox {
    font-size: 12px;
    color: #363636;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4880FF;
    border-color: #4880FF;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4880FF;
  }

  .el-checkbox__inner:hover {
    border-color: #4880FF;
  }

  .left-option-menu {
    display: flex;
    flex-wrap: wrap;
    z-index: 0;
    flex-direction: row;
    align-items: center;
  }

  .right-option-menu {
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    margin-left: auto;
    align-items: center;
  }

  .btn-view {
    margin-right: 0px;
    width: 50px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .btn-icon {
    width: 20px;
    height: 16px;
  }

  .line {
    height: 16px;
    width: 1px;
    background-color: #E0E0E0;
    margin-left: 0px;
    margin-right: 15px;
  }

  .num-view {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }

  .num-tip1 {
    font-size: 12px;
    color: #272727;
    margin-right: 8px;
  }

  .num-tip2 {
    font-size: 12px;
    color: #272727;
    margin-left: 8px;
  }

  .num-text {
    font-size: 18px;
    color: #FFA000;
  }

  @media only screen and (max-width: 768px) {
    .responsive-ipad-show {
      display: none;
    }
  }

</style>
