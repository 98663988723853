import { HttpH5NoErrorService,HttpH5Service } from '../utils/request.js'
export default {
  async getExpertList (data) {
    return HttpH5NoErrorService({
      url: '/crg/group',
      method: 'get',
      params: data
    })
  },
  async sendSelectList (data) {
    return HttpH5Service({
      url: '/crg/review',
      method: 'post',
      data
    })
  }
}
