<template>
  <div >
    <el-dialog @close="cancel()" :append-to-body="true" :modal-append-to-body='false' :close-on-click-modal="false" :show-close="false" title="提交反馈" center :visible.sync="isShow">
      <el-input :rows="5" type="textarea" placeholder="请把您的意见告诉我们。" v-model="content"></el-input>
      <div style="flex-direction: row;display: flex;padding-top: 40px;padding-bottom: 10px;justify-content: center;">
        <el-button size="medium" @click="cancel" style="width:150px;margin-right: 20px;">取消</el-button>
        <el-button size="medium" type="primary" @click="confirm" style="width:150px;margin-left: 20px;">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import feedbackApi from '../api/research_report.js'
  export default {
    data () {
      return {
        content: ''
      }
    },
    props: {
      isShow: Boolean,
      extraMessage: String
    },
    methods: {
      cancel () {
        this.$emit('dissmiss')
      },
      confirm () {
        var data = {}
        if (this.$store.getters.uid) {
          data.client_contact_id = this.$store.getters.uid
        }
        data.content = this.content + (this.tool.isNullOrEmpty(this.extraMessage)?'':this.extraMessage)
        feedbackApi.commitFeedback(data).then( res=> {
          if (res.code === '0000') {
            this.$message('提交成功')
          }
        })
        this.$emit('dissmiss')
      }
    }
  }
</script>

<style>
</style>
