import {HttpNoErrorService} from "@/utils/request";

export default {

  async loadIndustryList () {
    return HttpNoErrorService({
      url: '/misc/industries',
      method: 'post'
    })
  },

  async loadIndustryInterest (data) {
    return HttpNoErrorService({
      url: '/client_contacts/industry_interests/query',
      method: 'post',
      data
    })
  },

  async commitIndustries (data) {
    return HttpNoErrorService({
      url:'/client_contacts/industry_interests/update',
      method: 'post',
      data
    })
  },
  
  async getRightMenuClientList (data) {
    return HttpNoErrorService({
      url: '/clients/detail',
      method: 'post',
      data
    })
  }
}
