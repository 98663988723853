const getters = {
  userInfo: state => state.user.userInfo,
  sid: state => state.user.sid,
  uid: state => state.user.userInfo.id,
  email: state => state.user.userInfo.email,
  app_email: state => state.user.userInfo.app_email,
  client: state => state.user.userInfo.client,
  firstname: state => state.user.userInfo.firstname,
  lastname: state => state.user.userInfo.lastname,
  chinesename: state => state.user.userInfo.chinesename,
  searchkeyword: state => state.view.searchKeyword,
  nickname: state => state.user.nickname,
  leftmainh: state => state.view.leftMainH,
  expertToken: state => state.view.expertListToken,
  fromShareList: state => state.view.fromShareList,
  contractType: state => state.user.contractType,
}
export default getters
