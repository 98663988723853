<template>
  <div style="width: 100%;height: 100%;margin: 0 auto;">
    <div style="position: absolute;top: 35%;left: 50%;transform: translate(-50%, -55%);width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <img style="width: 110px;height: 110px;" src="../../assets/img/successicon.png" />
      <p style="color: #606060;font-size: 16px;text-align: center;">{{ getStatus }}</p>
      <p style="color: #606060;font-size: 16px;text-align: center;white-space: pre-line;">{{ getInfoString }}</p>
    </div>
    <div class="download-view">
      <downloadView :tipString='getTips'></downloadView>
    </div>
  </div>
</template>

<script>
	import downloadView from './download_app_tip.vue'
	export default {
		components: {
      downloadView
		},
    data () {
      return {
        type: ''
      }
    },
    mounted() {
      this.type = this.$route.query.type
    },
    computed: {
      getTips: function () {
        return '了解更多凯盛会议，在线查看会议纪要，\n请使用凯盛专家APP'
      },
      defaultEmail: function () {
         if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
           return this.$store.getters.email
         }else {
           return ''
         }
      },
      getStatus: function () {
        if (this.type === 'SUCCEED') {
          return '获取纪要成功'
        }else if (this.type === 'APPLIED') {
          return '获取请求已收到，我们会尽快处理。'
        }
        return ''
      },
      getInfoString: function () {
        if (this.type === 'SUCCEED') {
          if (this.tool.isNullOrEmpty(this.defaultEmail) === false) {
            return '相关资料将发送到您的邮箱：\n' + this.defaultEmail
          }else {
            return '由于您未将联络邮箱提供给凯盛，\n无法发送相关资料，请与您的客户经理联系。'
          }
        }else if (this.type === 'APPLIED') {
          if (this.tool.isNullOrEmpty(this.defaultEmail) === false) {
            return '请求通过后，相关资料将发送到您的邮箱：\n' + this.defaultEmail
          }else {
            return '由于您未将联络邮箱提供给凯盛，\n可能影响您收取相关资料，请与您的客户经理联系。'
          }
        }
        return ''
      }
    }
	}
</script>

<style>
  .download-view {
    position: absolute;
    width: 100%;
    bottom: env(safe-area-inset-bottom);
    padding-bottom: 50px;
  }
</style>
