import { HttpNoErrorService } from '../utils/request.js'

export default {
  async homepageInfo () {
    return HttpNoErrorService({
      url: '/misc/homepage',
      method: 'post'
    })
  }
}

export function login (data, type) {
  return HttpNoErrorService({
    url: type === 0 ? '/login/verification_code' : '/login/pwd',
    method: 'post',
    data
  })
}

export function askVerificationCode (data) {
  return HttpNoErrorService({
    url: '/verification_code',
    method: 'post',
    data
  })
}
