<template>
  <div class="vue-popup" v-show="showMask">
    <div class="mask" @click="closeMask()" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content" v-show="showMask">
        <div class="top-menu">
          <label style="margin-left: 18px;font-size: 12px;color: #ACACAC;">共选{{ list.length }}位专家</label>
          <div @click="deleteAllEvent()" style="display: flex;flex-direction: row;margin-right: 18px;align-items: center;">
            <img style="width: 15px;height: 15px;" src="../assets/img/qingkong.png" />
            <label style="margin-left: 6px;font-size: 14px;color: #1D253C;">清空</label>
          </div>
        </div>
        <div class="table">
          <div class="cell" v-for="(item, index) in list" :key='index'>
            <label style="font-weight: 500;overflow: hidden;height: 18px;line-height: 18px;text-align: left;margin-left: 15px;margin-top: 12px;font-size: 15px;color: #1D253C;">{{ '顾问编号：' + item.expert_code }}</label>
            <label style="overflow: hidden;height: 15px;line-height: 15px;text-align: left;margin-left: 15px;margin-top: 8px;font-size: 12px;color: #1D253C;" v-if="item.workEx.length > 0">{{ item.workEx[0] }}</label>
            <img @click="deleteEvent(item)" style="right: 12px;top: 12px;position: absolute;width: 16px;height: 16px;" src="../assets/img/gousel.svg" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      value: {},
      list: {
        type: Array,
        default: ()=>[]
      }
    },
    data() {
      return {
        showMask: false
      }
    },
    methods: {
      closeMask() {
        this.$emit('hiddenCar')
      },
      deleteEvent(item) {
        this.$emit('deleteCarIndex',item)
      },
      deleteAllEvent() {
        this.$emit('deleteCar')
      }
    },
    mounted(){
        this.showMask = this.value
    },
    watch:{
        value(newVal){
          this.showMask = newVal
        }
    }
  }
</script>

<style lang="scss" scoped>
  .mask {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: calc(env(safe-area-inset-bottom) + 75px);
  }
  .vue-popup {
      .popup-content {
          max-height: 300px;
          bottom: calc(env(safe-area-inset-bottom) + 75px);
          left: 0;
          width: 100%;
          background-color: #F2F5FA;
          border-radius: 8px 8px 0px 0px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          overflow: auto;
          .top-menu {
            display: flex;
            flex-direction: row;
            min-height: 50px;
            width: 100%;
            align-items: center;
            top: 0px;
            left: 0px;
            justify-content: space-between;
          }
          .table {
            display: flex;
            flex-direction: column;
            overflow: auto;
          }
          .cell {
            background: #FFFFFF;
            border-radius: 8px;
            margin-left: 15px;
            width: calc(100% - 30px);
            margin-bottom: 8px;
            min-height: 70px;
            display: flex;
            flex-direction: column;
            position: relative;
          }
          position: fixed;
          width: 100%;
          bottom: calc(env(safe-area-inset-bottom) + 75px);
          left: 0;
      }

  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
</style>
