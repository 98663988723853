<template>
  <div style="width: 100%;height: 100%;margin: 0;padding: 0;">
    <div style="position: absolute;top: 45%;left: 50%;transform: translate(-50%, -55%);width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <img style="width: 110px;height: 110px;" src="../../assets/img/successicon.png" />
      <p style="margin-top: 13px;color: #1E9D40;font-size: 22px;font-weight: bold;text-align: center;">{{ sendEmailSuccess ? '邮箱提交成功' : successTips }}</p>
      <!-- <div v-if="sendEmailSuccess === false" style="width: 80%;margin-top: 10px;height: 45px;background-color: #FFFFFF;border-radius: 4px;border: 1px solid #D6D6D6;">
        <div class="input-min">
          <input :disabled="isMailXG === false" class="input-view-s" v-model="email" placeholder="请输入您的邮箱" />
        </div>
      </div> -->
      <!-- <el-button v-if="sendEmailSuccess === false" :disabled="tool.isNullOrEmpty(email) === true" :loading="loading" type="primary" @click="sendEmail" style="width: 80%;margin-top: 20px;height: 45px;color: #FFFFFF;font-size: 14px;border-radius:4px;outline: none;border: none;margin-left: 24px;margin-right: 24px;">{{ btnTitle }}</el-button> -->
      <p style="margin-top: 30px;color: #606060;font-size: 16px;text-align: center;height: 50px;white-space: pre-line;">{{ sendEmailSuccess === true ? emailSuccess : tips }}</p>
    </div>
    <div v-if="sendEmailSuccess === true" class="download-email-view">
      <downloadView :tipString="getDownTip"></downloadView>
    </div>
  </div>
</template>

<script>
  import conference from '../../api/conference.js'
	import downloadView from './download_app_tip.vue'
  export default {
    components: {
      downloadView
    },
    data () {
      return {
        email: '',
        loading: false,
        id: 0,
        status: '',
        isPreview: false,
        sendEmailSuccess: false,
        isMailGG: false,
        isFullgoal: ''
      }
    },
    computed: {
      isDBEmail: function () {
        if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
          return true
        }else if(this.isFullgoal == 1) {
          return true
        }else {
          return false
        }
      },
      btnTitle: function () {
        if (this.status === 'SUMMARY_BOUGHT' || this.status === 'SUMMARY_APPLY') {
          return '确认接收会议资料的邮箱'
        }else if (this.status === 'CONFERENCE_APPLY_SUCCEEDED' || this.status === 'CONFERENCE_APPLY') {
          return '确认接收参会信息的邮箱'
        }else {
          return '确认'
        }
      },
      successTips: function () {
        if (this.status === 'SUMMARY_BOUGHT' || this.status === 'SUMMARY_APPLY') {
          if (this.isPreview === 'true' || this.isPreview === true) {
            if (this.status === 'SUMMARY_BOUGHT') {
              return '预约纪要成功'
            }else if (this.status === 'SUMMARY_APPLY') {
              return '已收到您的请求'
            }
          }else {
            if (this.status === 'SUMMARY_BOUGHT') {
              return '获取纪要成功'
            }else if (this.status === 'SUMMARY_APPLY') {
              return '已收到您的请求'
            }
          }
        }else if (this.status === 'CONFERENCE_APPLY_SUCCEEDED' || this.status === 'CONFERENCE_APPLY') {
          if (this.status === 'CONFERENCE_APPLY_SUCCEEDED') {
            return '报名成功'
          } else if(this.status === 'CONFERENCE_APPLY') {
            return '已收到您的请求'
          }
        }
        return '提交成功'
      },
      tips: function () {
        if (this.status === 'SUMMARY_BOUGHT' || this.status === 'SUMMARY_APPLY') {
          if (this.isPreview === 'true' || this.isPreview === true) {
            if (this.status === 'SUMMARY_BOUGHT') {
              if (this.isDBEmail === true) {
                return '相关材料完成整理后会发送到：\n' + this.showEmail()
              }else {
                return '由于您未在凯盛登记邮箱，\n会议资料完成整理后无法发送邮件，\n请与您的客户经理联系。'
              }
            }else if (this.status === 'SUMMARY_APPLY') {
              if (this.isDBEmail === true) {
                return '若预约成功，会议资料完成整理后，发送到：\n' + this.showEmail()
              }else {
                return '由于您未在凯盛登记邮箱，\n会议资料完成整理后无法发送邮件，\n请与您的客户经理联系。'
              }
            }
          }else{
            if (this.status === 'SUMMARY_BOUGHT') {
              if (this.isDBEmail === true) {
                return '相关材料会发送到：' + this.showEmail()
              }else {
                return '由于您未在凯盛登记邮箱，\n相关材料无法发送邮件，\n请与您的客户经理联系。'
              }
            }else if (this.status === 'SUMMARY_APPLY') {
              if (this.isDBEmail === true) {
                return '若获取成功，相关材料会发送到：\n' + this.showEmail()
              }else {
                return '由于您未在凯盛登记邮箱，\n会议资料完成整理后无法发送邮件，\n请与您的客户经理联系。'
              }
            }
          }
        }else if (this.status === 'CONFERENCE_APPLY_SUCCEEDED' || this.status === 'CONFERENCE_APPLY') {
          if (this.status === 'CONFERENCE_APPLY_SUCCEEDED') {
            if (this.isDBEmail === true) {
              return '稍后会将参会信息发送到：\n' + this.showEmail()
            }else {
              return '由于您未在凯盛登记邮箱，\n无法发送邮件，请与您的客户经理联系。'
            }
          } else if(this.status === 'CONFERENCE_APPLY') {
            if (this.isDBEmail === true) {
              return '若报名成功，会将参会信息发送到：\n' + this.showEmail()
            }else {
              return '由于您未在凯盛登记邮箱，\n无法发送邮件，请与您的客户经理联系。'
            }
          }
        }
        return ''
      },
      emailSuccess: function () {
        if (this.status === 'SUMMARY_BOUGHT' || this.status === 'SUMMARY_APPLY') {
         return '感谢您使用凯盛的服务，请关闭页面。'
        }else if (this.status === 'CONFERENCE_APPLY_SUCCEEDED' || this.status === 'CONFERENCE_APPLY') {
          return '感谢您使用凯盛的服务，请关闭页面。'
        }
        return ''
      },
      isMailXG: function () {
        if (this.isMailGG === 'true' || this.isMailGG === true) {
          return true
        }else{
          return false
        }
      },
      getDownTip: function () {
        return '了解更多凯盛会议，在线查看会议纪要，\n请使用凯盛专家APP'
      }
    },
    created() {
      this.status = this.$route.query.status
      this.id = parseInt(this.$route.query.id)
      this.email = this.$route.query.defalutEmail
      this.isPreview = this.$route.query.isPreview
      this.isMailGG = this.$route.query.isMailGG
      this.isFullgoal = this.$route.query.isFullgoal
    },
    methods: {
      saveAppEmail (val) {
        var userInfo = this.$store.getters.userInfo
        userInfo.app_email = val
        this.$store.dispatch('user/setUserInfo', userInfo)
      },
      showEmail() {
        if (this.isFullgoal) {
          return this.email
        }else {
          return this.$store.getters.email
        }
      },
      sendEmail () {
          this.loading = true
          var data = {}
          data.task_id = this.id
          data.email = this.email
          conference.confirmApplyEmail(data).then( res=> {
            this.loading = false
            if (res.code === '0000') {
              this.saveAppEmail(this.email)
              this.sendEmailSuccess = true
            }
          })
      }
    }
  }
</script>

<style>
  .input-min {
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input-view-s{
    text-align: left;
    width: 100%;
    height: 40px;
    color: #313131;
    font-size: 14px;
    background-color: rgb(1,1,1,0);
    border: 0;
    outline: none;
  }
  .download-email-view {
    position: absolute;
    width: 100%;
    bottom: env(safe-area-inset-bottom);
    padding-bottom: 20px;
  }
</style>
