import { HttpNoErrorService, HttpService } from '../utils/request.js'

export default {
  async getTopicsList (data) {
    return HttpNoErrorService({
      url: '/topics/page',
      method: 'post',
      data
    })
  },
  async getTopics (data) {
    return HttpNoErrorService({
      url: '/topics/detail',
      method: 'post',
      data
    })
  },
  async updateTopics (data) {
    return HttpService({
      url: '/topics/update',
      method: 'post',
      data
    })
  },
  async loadTopicDetail (data) {
    return HttpNoErrorService({
      url: '/topics/detail',
      method: 'post',
      data
    })
  },
  async loadMomDetail (data) {
    return HttpNoErrorService({
      url: '/topics/mom_detail',
      method: 'post',
      data
    })
  },
  async buySummary (data) {
    return HttpNoErrorService({
      url: '/client_contacts/topics/hot/create',
      method: 'post',
      data
    })
  },
  async checkSummarySend (data) {
    return HttpNoErrorService({
      url: '/topics/tasks/check_mom_send',
      method: 'post',
      data
    })
  },
  async sendSummary (data) {
    return HttpService({
      url: '/topics/tasks/require_mom',
      method: 'post',
      data
    })
  },
  async creatTopicStatus (data) {
    return HttpService({
      url: '/client_contacts/topics/hot/create',
          method: 'post',
              data
    })
  },
  //提交反馈
  async commitFeedback (data) {
    return HttpService({
      url: '/client_contacts/feedback',
      method: 'post',
      data
    })
  },
  //查询话题的mytask
  async queryTopicTaskDetail (data) {
    return HttpService({
      url: '/client_contacts/topics/list',
      method: 'post',
      data
    })
  },
  //感兴趣
  async commitPraise (data) {
    return HttpService({
      url: '/client_contacts/resources/like',
      method: 'post',
      data
    })
  },
  //取消感兴趣
  async cancelPraise (data) {
    return HttpService({
      url: '/client_contacts/resources/like/cancel',
      method: 'post',
      data
    })
  }

}
