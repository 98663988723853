<template>
  <div @touchmove.prevent class="vue-popup-success" v-show="showMask">
    <div class="mask-success" @click="closeMask" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content-success" v-show="showMask">
        <div style="margin-top: 25px;display: flex;flex-direction: row;width: 100%;">
          <img style="width: 150px;height: 150px;margin-left: 25px;" src="../../assets/img/qr_code.png" />
          <p style="text-align: left;margin-left: 15px;margin-right: 25px;font-size: 16px;color: #616161;font-weight: 800;">
            下载凯盛专家APP
            用登记在凯生的客户手机号注册
            免费试读30%纪要内容
          </p>
        </div>
        <div style="width: calc(100% - 50px);margin-right: 25px;margin-left: 25px;margin-top: 25px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
          <div @click="closeMask()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #1C99FC;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #FFFFFF;">关闭</div>
          <div @click="confirm()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #FFFFFF;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #1C99FC;">下载APP</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      value: {}
    },
    data() {
      return {
        showMask: false,
        ios_URL: 'http://itunes.apple.com/cn/app/id1065427847',
        android_URL: 'https://cdn.capvision.cn/apk/capvision_v3.1.2.apk'
      }
    },
    methods: {
      closeMask() {
        this.$emit('cancelEvent')
      },
      confirm() {
        const ua = navigator.userAgent.toLowerCase()
        if (ua.indexOf('android') !== -1) {
          window.open(this.android_URL)
        }else if (ua.indexOf('iphone') !== -1) {
          window.open(this.ios_URL)
        }
      }
    },
    mounted(){
        this.showMask = this.value;
    },
    watch:{
        value(newVal){
          this.showMask = newVal;
        }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-success{
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: 0;
  }
  .vue-popup-success {
      .popup-content-success {
          position: absolute;
          height: calc(env(safe-area-inset-bottom) + 265px);
          bottom: 0px;
          left: 0px;
          width: 100%;
          background-color: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      top: 0;
  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.1s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
</style>
