import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login.vue'
import Conference from '@/views/Conference.vue'
import Research from '@/views/Research.vue'
// import Topic from '@/views/Topic.vue'
import ConferenceDetail from '@/views/ConferenceDetail.vue'
import TopicDetail from '@/views/TopicDetail.vue'
import Search from '@/views/Search.vue'
import store from '../store/index'
import Setting from '../views/Setting.vue'
import ResearchDetail from '../views/ResearchDetail.vue'
import ConferenceH5Detail from '../views/H5/conference_mobile_detail.vue'
import Visit from '../views/visit.vue'
import successTips from '../views/H5/success_tips.vue'
import topicSuccessTips from '../views/H5/topic_success_tip.vue'
import errorTips from '../views/H5/error_tips.vue'
import successSendEmail from '../views/H5/success_commit_email.vue'
import researchH5Detail from '../views/H5/research_mobile_detail.vue'
import topicH5Detail from '../views/H5/topic_mobile_detail.vue'
import mainList from '../views/H5/main_home_list.vue'
import expertList from '../views/H5/expert_main_list.vue'

import conferenceMinder from '@/views/conferenceMinder.vue'

import fullgoal from '../views/fullgoal/fullgoal.vue'
import fullgoalConference from '../views/fullgoal/fullgoalConference.vue'
import fullgoalConferenceH5 from '../views/fullgoal/fullgoalConferenceH5.vue'
import { tool } from '../utils/tool.js'
Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/conference',
    name: 'Conference',
    component: Conference,
	meta: {
		requireAuth: true,
    rightIndutsry: true
	}
  },
  {
    path: '/research',
    name: 'Research',
    component: Research,
	meta: {
		requireAuth: true,
    rightIndutsry: false
	}
  },
 //  {
 //    path: '/topic',
 //    name: 'Topic',
 //    component: Topic,
	// meta: {
	// 	requireAuth: true
	// }
 //  },
  {
    path: '/conference/detail',
    name: 'ConferenceDetail',
    component: ConferenceDetail,
    meta: {
      rightIndutsry: true
    }
  },
  {
    path: '/topicDetail',
    name: 'TopicDetail',
    component: TopicDetail
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
	meta: {
		requireAuth: true
	}
  },
  {
    path: '/researchDetail',
    name: 'ResearchDetail',
    component: ResearchDetail,
    meta: {
      rightIndutsry: true
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
	meta: {
		requireAuth: true
	}
  },
  {
    path: '/mobileConferenceDetail',
    name: 'ConferenceH5Detail',
    component: ConferenceH5Detail
  },
  {
    path: '/visit',
    name: 'visit',
    component: Visit
  },
  {
    path: '/success_tips',
    name: 'successTips',
    component: successTips
  },
  {
    path: '/topic_success_tips',
    name: 'topicSuccessTips',
    component: topicSuccessTips
  },
  {
    path: '/error_tips',
    name: 'errorTips',
    component: errorTips
  },
  {
    path: '/success/send/email',
    name: 'successSendEmail',
    component: successSendEmail
  },
  {
    path: '/mobileResearchDetail',
    name: 'researchH5Detail',
    component: researchH5Detail
  },
  {
    path: '/mobileTopicDetail',
    name: 'topicH5Detail',
    component: topicH5Detail
  },
  {
    path: '/mainList',
    name: 'mainList',
    component: mainList,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/expertList',
    name: 'expertList',
    component: expertList,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/conferenceMinder',
    name: 'conferenceMinder',
    component: conferenceMinder
  },
  {
    path: '/fullgoal',
    name: 'fullgoal',
    component: fullgoal
  },
  {
    path: '/fullgoalConference',
    name: 'fullgoalConference',
    component: fullgoalConference
  },
  {
    path: '/fullgoalConferenceH5',
    name: 'fullgoalConferenceH5',
    component: fullgoalConferenceH5
  }
]

const createRouter = () => new Router({
  routes: constantRoutes
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if ((to.path === '/' || to.path === '/login') && isMobile) {
    store.dispatch('view/changeShowTopMenu', false)
    store.dispatch('view/changeShow', false)
    window.location.href = 'weixin://dl/business/?appid=wxa2d17824667e96fe&path=pages/home/index'
    return
  }
  if (tool.isNullOrEmpty(isMobile) && (to.path === '/mainList' || to.path === '/expertList')) {
    router.push({ path: '/' })
    return
  }
  const isUnLogin = tool.isNullOrEmpty(store.getters.sid)
  if (to.meta.requireAuth) {
    if (isUnLogin) {
      router.push({ path: '/' })
      return;
    }
  }
  if (to.path === '/login' && isUnLogin === false) {
    router.replace({ path: '/conference' })
    return;
  }
  if (to.name === 'Setting'
  || to.name === 'Login'
  || to.name === 'ConferenceH5Detail'
  || to.name === 'visit'
  || to.name === 'mainList'
  || to.name === 'expertList'
  || to.name === 'successTips'
  || to.name === 'topicSuccessTips'
  || to.name === 'errorTips'
  || to.name === 'successSendEmail'
  || to.name === 'researchH5Detail'
  || to.name === 'topicH5Detail'
  || to.name === 'Conference'
  || to.name === 'conferenceMinder'
  || to.name === 'ConferenceDetail'
  || to.name === 'fullgoal'
  || to.name === 'fullgoalConference'
  || to.name === 'fullgoalConferenceH5') {
    store.dispatch('view/changeShow', false)
  } else {
    store.dispatch('view/changeShow', true)
  }
  if (to.name === 'conferenceMinder' || to.name === 'Conference' || to.name === 'Search' || to.name === 'ConferenceDetail' || to.name === 'Research' || to.name === 'ResearchDetail') {
    store.dispatch('view/changeShowConference', true)
  } else {
    store.dispatch('view/changeShowConference', false)
  }
  if (to.name === 'Login') {
    store.dispatch('view/changeIsLoginPapge', true)
  } else {
    store.dispatch('view/changeIsLoginPapge', false)
  }
  if (to.name === 'ConferenceH5Detail'
  || to.name === 'visit'
  || to.name === 'mainList'
  || to.name === 'expertList'
  || to.name === 'successTips'
  || to.name === 'topicSuccessTips'
  || to.name === 'errorTips'
  || to.name === 'successSendEmail'
  || to.name === 'researchH5Detail'
  || to.name === 'topicH5Detail'
  || to.name === 'fullgoal'
  || to.name === 'fullgoalConference'
  || to.name === 'fullgoalConferenceH5') {
    store.dispatch('view/changeShowTopMenu', false)
  } else {
    store.dispatch('view/changeShowTopMenu', true)
  }
  next()
})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
