<template>
  <div>
    <img style="position: absolute;width: 280px;height: 210px;top: calc(50% - 120px);left: calc(50% - 140px);" v-show="loading && tool.isNullOrEmpty(mainModel.conference_name)" src="../../assets/img/loading1.gif" />
    <div v-if="mainModel == null || mainModel == undefined" class="main-content">
      <div style="height: 90px;line-height: 90px;">您暂时不能通过线上平台获取该内容，请与您的客户经理联系。（错误编号：17）</div>
    </div>
    <div v-if="mainModel != null && mainModel != undefined" class="main-content">
          <p v-if="mainModel.conference_name" style="font-size: 16px;color: #242424;margin-top: 25px;margin-left: 15px;margin-right: 15px;font-weight: bold;">{{ (mainModel.app_status === 'PREVIEW' ? '【会议预告】' : '【凯盛会议】') + mainModel.conference_name }}</p>
          <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in mainModel.contentArray" :key="indexModel+1">
             <div class="title-nav-view">
               <div class="flag-line"></div>
               <label class="nav-title">{{ itemModel.title }}</label>
             </div>
             <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
                <label
                 v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
                <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 30px);margin-left: 15px;margin-bottom: 10px;"  />
              </div>
    <!--         <label
               style="white-space: pre-line;text-align: left;margin-left: 15px;margin-right: 15px;color: #000000;font-size: 14px;line-height: 22px;">{{ itemModel.content }}</label> -->
           </div>
           <div v-if="mainModel.conference_name" class="download-conference-view">
             <downloadView :tipString='getDownTip'></downloadView>
           </div>
        </div>
        <div v-if="isHaveOption" class="main-footer-view">
          <slot v-if="mainModel.app_status === 'PREVIEW'">
            <slot v-if="isOngoing">
              <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">预约纪要</button>
            </slot>
            <slot v-else>
              <!-- <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;margin-right: 10px;">预约纪要</button> -->
              <button @click="signUpMeet('CONFERENCE_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;margin-left: 10px;">报名参会</button>
            </slot>
          </slot>
          <slot v-else-if="mainModel.app_status === 'COMPLETE'">
              <button @click="buySummary('SUMMARY_APPLY')" style="cursor: pointer;font-size: 16px;color:#FFFFFF;background-color: #1E459D;border-radius: 8px;height: 45px;width: 120px;outline: none;border: none;">获取纪要</button>
          </slot>
        </div>
        <chargeActionsheet :chargeString="charge" :eventString="event" v-model="showCharge" @cancelEvent='cancelEvent' @confirmEvent='confirmEvent'></chargeActionsheet>
  </div>
</template>

<script>
  import sharesdk from '../../utils/wxshare.js'
  import downloadView from "../H5/download_app_tip.vue"
  import chargeActionsheet from "../H5/conference_charge_actionsheet.vue"
  import goalApi from '../../api/fullgoal.js'
  import watermark from '../../utils/watermark.js'
  export default {
    data() {
      return {
        tsid: '',
        mainModel: {
          my_task: {
            status: ''
          }
        },
        token: "",
        showLogin:false,
        type: "",
        loading: true,
        showCharge: false,
        charge: '',
        event: '',
        requestMoming: false
      }
    },
    computed: {
      isOngoing: function () {
        return this.tool.isSignUpStop(this.mainModel.start_time)
      },
      defaultEmail: function () {
        if (this.mainModel.fullgoal_user) {
          if (this.mainModel.fullgoal_user.email_contact) {
            return this.mainModel.fullgoal_user.email_contact.value
          }else {
            return ''
          }
        }else {
          return ''
        }
      },
      isHaveOption: function () {
        if (this.mainModel.app_status === 'PREVIEW' || this.mainModel.app_status === 'COMPLETE') {
          return true
        }else{
          return false
        }
      },
      isVip: function () {
        if (this.$store.getters.contractType === 'GRADE_PAY' ||
        this.$store.getters.contractType === 'MONTHLY' ||
        this.$store.getters.contractType === 'MONTHLY_TRAIL'||
        this.$store.getters.contractType === 'FREE'
        ) {
          return true
        }
        return false
      },
      isFirstAndMonthClient: function () {
        if (this.$store.getters.client.has_monthly_conference_plan === true) {
          return true
        }
        return false
      },
      getDownTip: function () {
        return '了解更多凯盛会议，在线查看会议纪要，\n请使用凯盛专家APP'
      }
    },
    components: {
      downloadView,
      chargeActionsheet
    },
    mounted() {
      this.tsid = this.$route.query.tsid
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (this.tool.isNullOrEmpty(isMobile)) {
        this.$router.replace({ path:'/fullgoalConference', query:{tsid:this.tsid}})
      }
      this.getConferenceDetail(false)
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.backChange, false)
      }
    },
    destroyed() {
      window.removeEventListener('popstate', this.backChange, false)
      watermark.remove()
    },
    methods: {
      cancelEvent () {
        this.showCharge = false
      },
      confirmEvent () {
        this.showCharge = false
        if (this.event === '报名参会'){
          this.sendMomOrSignUp('CONFERENCE_APPLY')
        }else {
          this.sendMomOrSignUp('SUMMARY_APPLY')
        }
      },
      backChange () {
        this.$router.back()
      },
      buySummary (val) {
        this.signUpMeet(val)
      },
      signUpMeet (val) {
        if (this.mainModel.my_task && this.mainModel.my_task.status) {
          if (this.mainModel.my_task.status == 'CONFERENCE_APPLY'
          ||this.mainModel.my_task.status == 'SUMMARY_APPLY'
          ||this.mainModel.my_task.status == 'CONFERENCE_APPLY_SUCCEEDED'
          ||this.mainModel.my_task.status == 'SUMMARY_BOUGHT') {
            var isStatus = this.mainModel.app_status === 'PREVIEW' ? true : false
            this.$router.replace({ path:'/success/send/email',query:{id :this.mainModel.my_task.id,status: this.mainModel.my_task.status,defalutEmail: this.defaultEmail,isPreview: isStatus, isFullgoal: 1}})
          }else {
            this.$router.replace({ path:'/error_tips'})
          }
        } else {
          this.checkSendMom(val)
        }
      },
      checkSendMom (val) {
        if (this.isVip === false) {
          if (val === 'MOM_APPLIED'){
            if (this.mainModel.app_status === 'COMPLETE') {
              this.event = '获取纪要'
              this.charge = `${this.mainModel.conference_fee}小时`
            }else{
              this.event = '预约纪要'
              this.charge = `${this.mainModel.conference_fee}小时`
            }
          }else if(val === 'CONFERENCE_APPLY'){
            this.event = '报名参会'
            this.charge = `${this.mainModel.conference_fee}小时`
          }
          this.showCharge = true
          return
        }
        this.sendMomOrSignUp(val)
      },
      sendMomOrSignUp (val) {
        if (this.requestMoming) {
          return
        }
        var data = {}
        data.conference_publish_id = this.mainModel.id
        data.status = val
        data.origin = 'H5'
        data.pay_type = this.mainModel.pay_type
        this.requestMoming = true
        goalApi.fullgoalConferenceContactApply(data).then( res=> {
          this.requestMoming = false
          if (res.code === '0000') {
            if (res.data !== null) {
              this.mainModel.my_task = res.data
              if (this.mainModel.my_task) {
                if (this.mainModel.my_task.status == 'CONFERENCE_APPLY'
                ||this.mainModel.my_task.status == 'SUMMARY_APPLY'
                ||this.mainModel.my_task.status == 'CONFERENCE_APPLY_SUCCEEDED'
                ||this.mainModel.my_task.status == 'SUMMARY_BOUGHT') {
                  var isStatus = this.mainModel.app_status === 'PREVIEW' ? true : false
                  this.$router.replace({ path:'/success/send/email',query:{id :this.mainModel.my_task.id,status: this.mainModel.my_task.status,defalutEmail: this.defaultEmail,isPreview: isStatus,isFullgoal: 1}})
                }else {
                  this.$router.replace({ path:'/error_tips'})
                }
              }
            }
          }else {
              this.$router.replace({ path:'/error_tips'})
          }
        })
      },
      getConferenceDetail (val) {
        if (!this.tsid) {
          return
        }
        var form = {}
        form.tsid = this.tsid
        form.extra = 'contact_task_for_contact,materials,conference_summary,consultants'
        goalApi.fullgoalConferenceOne(form).then( res=> {
          this.loading = false
          if (res.code === '0000') {
            this.mainModel = res.data
            var watermarkString = ''
            if (this.mainModel.fullgoal_user.chinesename && this.mainModel.fullgoal_user.client) {
              watermarkString = `仅供凯盛客户${this.mainModel.fullgoal_user.client.name}-${this.mainModel.fullgoal_user.chinesename}使用`
            }
            if (watermarkString) {
              watermark.set(watermarkString)
            }
            if (val === false) {
              let url = location.href.split('#')[0]
              let shareUrl = url + '#/visit?token=' + this.token
              var img = 'http://cdn.capvision.cn/media/conference/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210220095321.png'
              let obj = {
                title: '凯盛融英会议主题',
                des: this.mainModel.title,
                linkurl: shareUrl,
                img: img
              }
              sharesdk.getJSSDK(url,obj)
              this.DDConfig.ddShowMenu('凯盛融英会议主题',img,shareUrl,this.mainModel.title)
            }
            if (res.data.contact_task_for_contact) {
              this.mainModel.my_task = res.data.contact_task_for_contact
            }
            this.mainModel.contentArray = []
            var contents = res.data.content.split('@@@')
            contents.forEach((item) => {
              if (this.tool.isNullOrEmpty(item) === false) {
                if (item.indexOf('###') !== -1) {
                  var itemModel = {}
                  itemModel.title = item.slice(0,item.indexOf('###'))
                  itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                  itemModel.imageTextArray = []

                  var imgTextArray = itemModel.content.split('<img>')
                  imgTextArray.forEach( imgText => {
                    var imgTextModel = {}
                    if (imgText.indexOf('</img>') !== -1) {
                      var  subImgTextArray = imgText.split('</img>')
                      if (subImgTextArray.length === 2) {
                        for (var i = 0;i < subImgTextArray.length;i++) {
                          var imgSubTextModel = {}
                          if (i === 0) {
                            imgSubTextModel.img = subImgTextArray[0]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }else {
                            imgSubTextModel.text = subImgTextArray[1]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }
                        }
                      }else {
                        imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    }else{
                      imgTextModel.text = imgText
                      itemModel.imageTextArray.push(imgTextModel)
                    }
                  })
                  this.mainModel.contentArray.push(itemModel)
                }
              }
            })
          }
        })
      }
    }
  }
</script>

<style>
</style>
