<template>
  <div @click="openEvent" class="expert-info-view">
    <div style="display: flex;flex-direction: column;">
      <div :class="tool.isNullOrEmpty(expertInfo.remark) === false || expertInfo.open ? 'top-border' : 'all-border'" style="background-color: #DDF0FF;display: flex;flex-direction: column;padding-bottom: 14px;">
        <div class="name-tag-view">
          <label style="font-size: 16px;color: #1D253C;font-weight: 800;">{{ '顾问编号：'}}</label>
          <label class="tag-label" :style="{ background:tagBack }">{{ expertInfo.expert_code }}</label>
          <label v-if="expertInfo.tags.length > 0" style="padding-right: 10px;padding-left: 10px;line-height: 20px;text-align: center;background-color: #EDB32E;height: 20px;border-radius: 10px;margin-left: 12px;font-size: 10px;color: #FFFFFF;">{{ expertInfo.tags[0] }}</label>
          <img @click.stop="selGou" style="margin-left: auto;margin-right: 0px;width: 22px;height: 22px;" :src="gouIcon" />
        </div>
        <label style="text-align: left;font-size: 14px;line-height: 16px;white-space: pre-wrap;color: #000000;margin-left: 15px;width: calc(100% - 30px);margin-top: 7px;" v-for="(item,index) in expertInfo.workEx" :key='index'>{{ item }}</label>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="expertInfo.open" class="backgroud-view">
        <label style="font-weight: 400;color: #1D253C;text-align: left;font-size: 14px;line-height: 20px;margin-top: 10px;margin-left: 15px;width: calc(100% - 30px);" v-if="tool.isNullOrEmpty(rateDesString) === false">{{ rateDesString }}</label>
        <label style="font-weight: 400;color: #1D253C;text-align: left;font-size: 14px;line-height: 20px;margin-top: 5px;margin-left: 15px;width: calc(100% - 30px);">
          {{ expertInfo.background }}
        </label>
        <label style="font-weight: 500;color: #000000;text-align: left;font-size: 14px;margin-top: 8px;margin-left: 15px;width: calc(100% - 30px);"><span style="font-weight: 500;">专长：</span>{{ expertInfo.expertise }}</label>
      </div>
    </el-collapse-transition>
    <div v-if="tool.isNullOrEmpty(expertInfo.remark) === false || expertInfo.open === true" :class="(expertInfo.open === false && tool.isNullOrEmpty(expertInfo.remark) === false) ? 'no-open-status' : 'open-status'" style="display: flex;flex-direction: column;">
      <label :class="(expertInfo.open === false && tool.isNullOrEmpty(expertInfo.remark) === false) ? 'no-open-status' : 'open-status'" style="text-align: left;white-space: pre-wrap;padding: 10px 15px;color: #A87418;font-size: 14px;line-height: 20px;width: 100%;">{{ expertInfo.remark }}</label>
      <!-- <img style="width: 20px;height: 20px;margin-bottom: 5px;margin-left: calc(50% - 10px);" :src="openIcon"  /> -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      expertInfo: {
        type:Object,
        default:()=>{}
      },
      index: {
        type:Number,
        default:0
      }
    },
    computed: {
      gouIcon: function () {
        if (this.expertInfo.isGou) {
          return require('../assets/img/gousel.svg')
        }else {
          return require('../assets/img/gou.svg')
        }
      },
      openIcon: function () {
        if (this.expertInfo.open) {
          return require('../assets/img/close.png')
        }else {
          return require('../assets/img/open.png')
        }
      },
      rateDesString: function () {
        return this.expertInfo.expert_desc
      },
      tagBack: function () {
        let tagIndex = this.index%8
        if (tagIndex === 0) {
          return '#4F2EFF'
        }else if(tagIndex === 1) {
          return '#146DFC'
        }else if(tagIndex === 2) {
          return '#25D8F5'
        }else if(tagIndex === 3) {
          return '#20CD83'
        }else if(tagIndex === 4) {
          return '#FEBC2C'
        }else if(tagIndex === 5) {
          return '#FD6720'
        }else if(tagIndex === 6) {
          return '#F01378'
        }else if(tagIndex === 7) {
          return '#F7392B'
        }
        return '#4F2EFF'
      }
    },
    data() {
      return {
      }
    },
    methods: {
      selGou() {
        console.log(this.expertInfo.isGou)
        this.expertInfo.isGou = !this.expertInfo.isGou
        this.$emit('addCar')
      },
      openEvent() {
        this.expertInfo.open = !this.expertInfo.open
      }
    }
  }
</script>

<style>
  .expert-info-view {
    display: flex;
    flex-direction: column;
    margin: 0px 15px 10px 15px;
    width: calc(100% - 30px);
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.04);
  }
  .name-tag-view {
    display: flex;
    flex-direction: row;
    margin: 15px 15px 8px 15px;
    align-items: center;
  }
  .backgroud-view {
    border-top: 1px solid #F2F5FA;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .open-status
  {
    background-color: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
  }
  .no-open-status
  {
    background-color: #FFF8E6;
    border-radius: 0px 0px 8px 8px;
  }
  .tag-label {
    border-radius: 10px;
    line-height: 20px;
    height: 20px;
    min-width: 20px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
  }
  .top-border {
    border-radius: 8px 8px 0px 0px;
  }
  .all-border {
    border-radius: 8px 8px 8px 8px;
  }
</style>
