import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store/index.js'
import router from '../router/index.js'
import { getFullgoalToken } from '../utils/auth.js'

const fullgoalHttpService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15*1000
})

fullgoalHttpService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (getFullgoalToken()) {
      config.headers['token'] = getFullgoalToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

fullgoalHttpService.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '0000') {
      Message({
        message: res.message || 'Error',
        showClose: true,
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    }else {
      return res
    }
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

const fullgoalHttpNoErrorService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15*1000
})

fullgoalHttpNoErrorService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (getFullgoalToken()) {
      config.headers['token'] = getFullgoalToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

fullgoalHttpNoErrorService.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

const HttpService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})

HttpService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.getters.sid) {
      config.headers['sid'] = store.getters.sid
      }
    if (store.getters.uid) {
      config.headers['uid'] = store.getters.uid
      }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

HttpService.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '0000') {
      Message({
        message: res.message || 'Error',
        showClose: true,
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code === '0203' || res.code === '0204') {
      store.dispatch('user/logout')
      router.replace('/login')
    }else {
      return res
    }
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

const HttpNoErrorService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})

HttpNoErrorService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.getters.sid) {
      config.headers['sid'] = store.getters.sid
      }
    if (store.getters.uid) {
      config.headers['uid'] = store.getters.uid
      }
    config.headers['token'] = '123abc'/* 测试 */
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

HttpNoErrorService.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === '0203' || res.code === '0204') {
      store.dispatch('user/logout')
      router.replace('/login')
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
const HttpCheckNoErrorService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})

HttpCheckNoErrorService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.getters.sid) {
      config.headers['sid'] = store.getters.sid
      }
    if (store.getters.uid) {
      config.headers['uid'] = store.getters.uid
      }
    config.headers['token'] = '123abc'/* 测试 */
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
HttpCheckNoErrorService.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

const HttpH5Service = axios.create({
  baseURL: 'http://wx.capvision.cn/cm-web/public',
  timeout: 15000
})

HttpH5Service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.getters.sid) {
      config.headers['sid'] = store.getters.sid
      }
    if (store.getters.uid) {
      config.headers['uid'] = store.getters.uid
      }
    if (store.getters.expertToken) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.expertToken
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

HttpH5Service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '0000') {
      Message({
        message: res.message || 'Error',
        showClose: true,
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code === '0203' || res.code === '0204') {
      store.dispatch('user/logout')
      router.replace('/login')
    }else {
      return res
    }
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

const HttpH5NoErrorService = axios.create({
  baseURL: 'http://wx.capvision.cn/cm-web/public',
  timeout: 15000
})

HttpH5NoErrorService.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.getters.sid) {
      config.headers['sid'] = store.getters.sid
      }
    if (store.getters.uid) {
      config.headers['uid'] = store.getters.uid
    }
    if (store.getters.expertToken) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.expertToken
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

HttpH5NoErrorService.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === '0203' || res.code === '0204') {
      store.dispatch('user/logout')
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error || 'Error',
      showClose: true,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export {
  HttpService,
  HttpNoErrorService,
  HttpCheckNoErrorService,
  HttpH5Service,
  HttpH5NoErrorService,
  fullgoalHttpService,
  fullgoalHttpNoErrorService
}
