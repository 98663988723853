<template>
  <div id="list-topic">
    <listDetailCell v-for="(item,index) in list" :key="index" titleExtra='' :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent(index)'></listDetailCell>
  </div>
</template>

<script>
  import listDetailCell from './list_topic_cell.vue'
  export default {
    components: {
      listDetailCell
    },
    props: {
      list: Array,
      loading: Boolean,
      noMore: Boolean
    },
    data () {
      return {
      }
    },
    computed: {
      disabled () {
        return this.loading || this.noMore
      }
    },
    methods: {
      openEvent (val) {
        this.$emit('openEventList', val)
      },
      closeEvent (val) {
        this.$emit('closeEventList', val)
      },
      load () {
        this.$emit('loadMore')
      }
    }
  }
</script>

<style>
  #list-topic{
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
  }
</style>
