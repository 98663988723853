<template>
  <div @touchmove.prevent class="vue-popup-success" v-show="showMask">
    <div class="mask-success" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content-success" v-show="showMask">
        <p style="margin-top: 30px;text-align: center;font-size: 16px;color: #000000;font-weight: 800;">费用确认</p>
        <div style="width: calc(100% - 50px);" v-if="tool.isNullOrEmpty(eventString) === false">
          <p style="margin-left: 25px;text-align: left;margin-top: 15px;"><span style="color: #313131;font-weight: 600;">行为：</span>{{ eventString }}</p>
        </div>
        <div style="width: calc(100% - 50px);" v-if="tool.isNullOrEmpty(chargeString) === false">
          <p style="margin-left: 25px;text-align: left;margin-top: 25px;"><span style="color: #313131;font-weight: 600;">费用：</span>{{ chargeString }}</p>
        </div>
        <div style="width: calc(100% - 50px);" v-if="tool.isNullOrEmpty(topicString) === false">
          <p style="margin-left: 25px;text-align: left;margin-top: 25px;">{{ topicString }}</p>
        </div>
        <div style="width: calc(100% - 50px);margin-right: 25px;margin-left: 25px;margin-top: 15px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
          <div @click="closeMask()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #1C99FC;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #FFFFFF;">取消</div>
          <div @click="confirm()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #FFFFFF;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #1C99FC;">确认</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      value: {},
      eventString: String,
      chargeString: String,
      topicString: String
    },
    data() {
      return {
        showMask: false
      }
    },
    methods: {
      closeMask() {
        this.$emit('cancelEvent')
      },
      confirm() {
        this.$emit('confirmEvent')
      }
    },
    mounted(){
        this.showMask = this.value;
    },
    watch:{
        value(newVal){
          this.showMask = newVal;
        }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-success{
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: 0;
  }
  .vue-popup-success {
      .popup-content-success {
          position: absolute;
          height: calc(env(safe-area-inset-bottom) + 265px);
          bottom: 0px;
          left: 0px;
          width: 100%;
          background-color: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      top: 0;
  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.1s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
</style>
