<template>
  <div @touchmove.prevent class="vue-popup-alert" v-show="showMask">
    <div class="mask-alert" @click="closeMask()" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content-alert" v-show="showMask">
        <label style="width: calc(100% - 40px);margin-top: 32px;white-space: pre-wrap;line-height: 25px;font-size: 15px;color: #000000;">{{ title }}</label>
        <div style="margin-top: 25px;width: calc(100% - 40px);margin-right: 20px;height: 40px;margin-left: 20px;background-color: #F8F8F8;border-radius: 4px;border: 1px solid #D8D8D8;">
          <div class="input-min">
            <input class="input-view-s" v-model="name" placeholder="请输入您的姓名" />
          </div>
        </div>
        <div style="width: calc(100% - 40px);margin-right: 20px;margin-left: 20px;margin-top: 25px;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
          <div @click="closeMask()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #1C99FC;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #FFFFFF;">放弃</div>
          <div @click="confirm()" style="border-radius: 8px;font-weight: 500;line-height: 40px;text-align: center;color: #FFFFFF;border: 1px solid #1C99FC;height: 40px;width: 142px;background-color: #1C99FC;">提交</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      value: {}
    },
    data() {
      return {
        showMask: false,
        title: '在提交需求前请确认您的姓名\n以便凯盛与您联系',
        name: ''
      }
    },
    methods: {
      closeMask() {
        this.$emit('hiddenCommitView')
      },
      confirm() {
        this.$emit('commitConfirm', this.name)
      }
    },
    mounted(){
        this.showMask = this.value;
    },
    watch:{
        value(newVal){
          this.showMask = newVal;
        }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-alert{
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: 0;
  }
  .vue-popup-alert {
      .popup-content-alert {
          position: absolute;
          height: 235px;
          bottom: calc(50% - 115px);
          left: 15px;
          width: calc(100% - 30px);
          background-color: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
          .input-min {
            margin: 0px 10px 0px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .input-view-s{
            text-align: left;
            width: 100%;
            height: 40px;
            color: #313131;
            font-size: 14px;
            background-color: rgb(1,1,1,0);
            border: 0;
            outline: none;
          }
      }
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      top: 0;
  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.1s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
</style>
