<template>
  <div id="main-sss" :class="(show && tagsList.length > 0) ? 'fixedwraper' : 'nofixedwraper'">
    <el-collapse-transition>
      <div id="search-tag-view" v-if="tagsList.length > 0" v-show="show && tagsList.length > 0" class="search_tag_view">
        <div class="section_view">
          <div class="section_flag"></div>
          <p class="section_title"><span style="color: #FFA000;">{{ tagName }}</span>&nbsp;共{{ count }}条相关</p>
        </div>
        <div @click="gotoDetail(conferenceItem)" class="tag_cell_view" v-for="(conferenceItem,conferenceIndex) in tagsList" :key="conferenceIndex">
          <div style="margin-left: 8px;font-size: 14px;color: #0745D1;text-align: left;width: calc(100% - 8px);">
            <img style="height: 12px;width: 12px;margin-right: 5px;" src="../assets/img/huayu.png"/>{{ conferenceItem.conference_name }}
            <span style="color: #000000;font-size: 12px;">{{ ' ' + tool.getTimeYMD(conferenceItem.start_time)  }}</span>
          </div>
        </div>
        <div style="width: 100%;height: 15px;"></div>
        <el-pagination style="margin-top: 0px;margin-bottom: 10px;" v-if="totalPage > 1"
        small
        background
        layout="pager" :page-size="5" :total="count" :current-page="page" @current-change="currentChange">
        </el-pagination>
      </div>
    </el-collapse-transition>
    <div v-if="hotList.length > 0 && isIndustry == true" class="hot_menu_list">
      <div class="section_view">
        <div class="section_flag"></div>
        <p class="section_title">近期热点</p>
      </div>
      <div class="hot_list">
        <div style="cursor: pointer;" @click="goToSearch(hotItem.name)" class="hot_cell" v-for="(hotItem,hotIndex) in hotList" :key="hotIndex">
          <p v-if="hotIndex == 0" class="hot_cell_number hot_first">{{ hotIndex + 1 }}</p>
          <p v-if="hotIndex == 1" class="hot_cell_number hot_second">{{ hotIndex + 1 }}</p>
          <p v-if="hotIndex == 2" class="hot_cell_number hot_three">{{ hotIndex + 1 }}</p>
          <p v-if="hotIndex > 2" class="hot_cell_number">{{ hotIndex + 1 }}</p>
          <div class="hot_cell_content">
            <img v-if="hotIndex == 0" style="width: 13px;height: 15px;margin-right: 5px;" src="../assets/img/one_hot.svg" />
            <img v-if="hotIndex == 1" style="width: 13px;height: 15px;margin-right: 5px;" src="../assets/img/two_hot.svg" />
            <img v-if="hotIndex == 2" style="width: 13px;height: 15px;margin-right: 5px;" src="../assets/img/three_hot.svg" />
            <p v-if="hotIndex == 0" style="font-size: 13px;" class="hot_first">{{ hotItem.name }}</p>
            <p v-if="hotIndex == 1" style="font-size: 13px;" class="hot_second">{{ hotItem.name }}</p>
            <p v-if="hotIndex == 2" style="font-size: 13px;" class="hot_three">{{ hotItem.name }}</p>
            <p v-if="hotIndex > 2" style="color: #0745D1;font-size: 13px;">{{ hotItem.name }}</p>
            <p v-if="hotItem.conference_ids" style="color: #B1B1B1;font-size: 12px;margin-left: auto;margin-right: 0px;">{{ hotItem.conference_ids.split(',').length }}条内容</p>
          </div>
        </div>
      </div>
      <p @click="showFeedback = true" style="margin-top: 15px;text-align: center;font-size: 16px;color: #393939;font-weight: bold;margin-bottom: 20px;">有什么感兴趣的话题？<span style="color: #0745D1;text-decoration: underline;">告诉我们</span></p>
    </div>
    <div v-if="currentHot.length > 0 && isIndustry == true" class="zj_hot_menu_list">
      <div class="section_view">
        <div class="section_flag"></div>
        <p class="section_title">近期热门行业</p>
      </div>
      <div class="current_hot_view">
        <div style="cursor: pointer;" @click="goToSearchIndustryId(currentHot.id)" class="current_hot_item" v-for="(currentHot,currentHotIndex) in currentHot" :key="currentHotIndex">
          <div class="industry_hot_line" style="color: #002ECC;font-size: 12px;">{{ currentHot.name }}</div>
          <img style="height: 8px;width: 8px;" v-if="currentHot.rank_change != 0" :src="currentHot.rank_change > 0 ? require('../assets/img/up_icon.png') : require('../assets/img/down_icon.png')"/>
          <p v-if="currentHot.rank_change != 0" :class="currentHot.rank_change != 0 ? 'current_hot_up' : 'current_hot_down'">{{ Math.abs(currentHot.rank_change) }}</p>
        </div>
      </div>
    </div>
    <div class="ad_view">
      <div style="cursor: pointer;" @click="showFeedback = true" class="ad_view_item">
        <img style="margin-top: 8px;width: 100px;height: 85px;" src="../assets/img/feedback_new_s.svg" />
        <div style="margin-top: 9px;color: #1E459D;font-size: 11px;font-weight: bold;">意见反馈</div>
      </div>
      <div style="cursor: pointer;" @click="goToAppDownLoad" class="ad_view_item">
        <img style="margin-top: 8px;width: 85px;height: 85px;" src="../assets/img/qrcode_new_s.svg" />
        <div style="margin-top: 9px;color: #1E459D;font-size: 11px;font-weight: bold;">凯盛专家APP</div>
      </div>
    </div>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' extraMessage='服务团队入口：'></feedback>
    <div @click="backTop" v-if="showBackButton" class="backBtn">Top</div>
  </div>
</template>

<script>
  import conference from '../api/conference.js'
  import { mapGetters } from "vuex"
  import feedback from './feedback.vue'
  export default {
    data() {
      return {
        hotList: [],
        currentHot: [],
        tagsList: [],
        page: 1,
        totalPage: 0,
        count: 0,
        tagName: '',
        showFeedback:false,
        show: false,
        showBackButton: false,
        scrollY: 0,
        scrollRightY: 0
      }
    },
    props: {
      isIndustry: Boolean
    },
    components: {
      feedback
    },
    computed: {
      ...mapGetters('view', {
        searchTag: 'searchTag'
      })
    },
    watch: {
      searchTag: {
        handler: function() {
          if (this.tool.isNullOrEmpty(this.searchTag) == false) {
            this.tagName = this.searchTag
            this.show = false
            this.requsetSearchTag()
            this.page = 1
            this.$store.dispatch('view/changeSearchTag', null)
          }
        },
        deep: true
      }
    },
    mounted() {
      // if (this.tool.isNullOrEmpty(this.$store.getters.sid) === false) {
      //   this.requestTagsAndIndutries()
      // }
      this.createRequest()
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      backTop () {
        let timer = setInterval(() => {
          let ispeed = Math.floor(-this.scrollY / 5)
          this.scrollY = this.scrollY + ispeed
          document.documentElement.scrollTop = this.scrollY
          document.body.scrollTop = this.scrollY
          if (this.scrollY=== 0) {
            clearInterval(timer)
          }
        }, 16)
      },
      createRequest () {
        window.addEventListener('scroll',this.handleScroll)
      },
      initScrollRightY () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.scrollRightY = scrollTop
        this.showBackButton = false
      },
      handleScroll () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.scrollY = scrollTop
        var mainH = document.getElementById('main-sss').offsetHeight
        if (scrollTop>=mainH) {
          this.showBackButton = true
        }else {
          this.showBackButton = false
        }
        // if (document.getElementById('search-tag-view')) {
        //   var searchTagViewH = document.getElementById('search-tag-view').offsetHeight
        //   var offsetY = Math.abs(scrollTop - this.scrollRightY)
        //   if (this.show) {
        //     if (offsetY>searchTagViewH) {
        //       this.show = false
        //       this.tagsList.length = 0
        //     }
        //   }
        // }
      },
      goToAppDownLoad() {
        window.open('http://iksh.com', '_blank')
      },
      showFeedbackDialog () {
        this.showFeedback = true
      },
      currentChange(e) {
        this.page = e
        this.requsetSearchTag()
        console.log(e)
      },
      gotoDetail (val) {
        let routeData = this.$router.resolve({
            path: "/conference/detail",
            query: {
              id: val.id,
              isMom: false,
            }
        })
        window.open(routeData.href, '_blank')
        // if (val.resource_type == 'CONFERENCE') {
        //   let routeData = this.$router.resolve({
        //       path: "/conference/detail",
        //       query: {
        //         id: val.id,
        //         isMom: false,
        //       }
        //   })
        //   window.open(routeData.href, '_blank')
        // }else if(val.resource_type == 'RESEARCH') {
        //   let routeData = this.$router.resolve({
        //     path: '/researchDetail',
        //     query: {id: val.id}
        //   })
        //   window.open(routeData.href, '_blank')
        // }else if(val.resource_type == 'TOPIC') {
        //   let routeData = this.$router.resolve({
        //     path: '/topicDetail',
        //     query: {id: val.id}
        //   })
        //   window.open(routeData.href, '_blank')
        // }
      },
      requestTagsAndIndutries() {
        conference.tagAndIndustries().then( res=> {
          if (res.code == '0000') {
            if (res.data.tags) {
              this.hotList = res.data.tags
            }
            if (res.data.industries) {
              this.currentHot = res.data.industries
            }
          }
        })
      },
      clearTagsList() {
        this.tagsList.length = 0
        this.page = 1
        this.show = false
        this.backTop()
      },
      goToSearch(tag) {
        if (this.$route.name == 'ConferenceDetail') {
          this.$router.replace({
              path: "/conference"
          })
          setTimeout(()=>{
            this.$store.dispatch('view/changeSearchConferenceTag', tag)
          },1000)
        }else if(this.$route.name == 'ResearchDetail') {
          this.$router.replace({
              path: "/research"
          })
          setTimeout(()=>{
            this.$store.dispatch('view/changeSearchConferenceTag', tag)
          },1000)
        }
        else {
          this.$store.dispatch('view/changeSearchConferenceTag', tag)
        }
        this.clearTagsList()
      },
      goToSearchIndustryId(id) {
        if (this.$route.name == 'ConferenceDetail') {
          this.$router.replace({
              path: "/conference"
          })
          setTimeout(()=>{
            this.$store.dispatch('view/changeSearchIndustryId', id)
          },1000)
        }else if (this.$route.name == 'ResearchDetail') {
          this.$router.replace({
              path: "/research"
          })
          setTimeout(()=>{
            this.$store.dispatch('view/changeSearchIndustryId', id)
          },1000)
        }
        else {
          this.$store.dispatch('view/changeSearchIndustryId', id)
        }
        this.clearTagsList()
      },
      requsetSearchTag() {
        var data = {}
        data.keyword = this.tagName
        data.page = this.page
        data.size = 5
        data.extra = 'contact_task_for_contact,materials,conference_summary'
        data.sort = 'project.starttime desc,project.endtime desc'
        conference.getConferenceList(data).then( res=> {
          if(res.code == '0000') {
            this.show = true
            this.initScrollRightY()
            this.tagsList = res.data.list
            this.count = res.data.count
            if(res.data.count > 0) {
              this.totalPage = Math.ceil(res.data.count / 5)
            }
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .nofixedwraper{
    top: 74px;
    display: flex;
    flex-direction: column;
    width: 320px;
    left: 960px;
    position: absolute;
  }
  .fixedwraper{
    position: fixed;
    top: 74px;
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: 960px;
  }
  .fixedwraper::-webkit-scrollbar { width: 0 !important }
  .hot_menu_list{
    background-color: #fff;
    border-radius: 6px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .section_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
  .section_flag {
    height: 18px;
    width: 6px;
    background-color: #4880FF;
  }
  .section_title {
    color: #000000;
    font-size: 17px;
    font-weight: bold;
    margin-left: 14px;
  }
  .hot_cell {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .hot_list {
    margin-left: 10px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
  }
  .hot_cell_number {
    font-size: 14px;
    font-weight: 700;
    color: #AFAFAF;
    min-width: 24px;
  }
  .hot_first {
    color: #FF003B;
  }
  .hot_second {
    color: #F86F0A;
  }
  .hot_three {
    color: #FFB100;
  }
  .hot_cell_content {
    margin-left: 5px;
    height: 35px;
    border-bottom: 0.5px solid #eaeaea;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 5px);
  }
  .zj_hot_menu_list {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .current_hot_view {
    margin-left: 15px;
    margin-right: 0px;
    width: calc(100% - 15px);
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .current_hot_item {
    background-color: #F6FAFB;
    height: 34px;
    width: 92px;
    margin-right: 6px;
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .current_hot_down {
    color: #3EEF37;
    font-size: 8px;
    font-weight: bold;
  }
  .current_hot_up {
    color: #EF5337;
    font-size: 8px;
    font-weight: bold;
  }
  .ad_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  .ad_view_item {
    width: 155px;
    height: 125px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ad_view_item_content {
    height: 110px;
    width: calc(100% - 70px);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .search_tag_view {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .tag_cell_view {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100% - 30px);
    border-bottom: 0.5px solid #eaeaea;
    padding: 14px 2px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .industry_hot_line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-align: left;
    line-height: 20px;
  }
  .backBtn {
    width: 50px;
    height: 50px;
    background-color: #4880FF;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    position: fixed;
    top: calc(100vh - 60px);
    color: #fff;
    left: calc(100vh - 320px);
    left: auto;
    cursor: pointer;
    &:hover {
      background-color: #4840FF;
    }
  }
</style>
