<template>
  <div id="list-research">
     <listDetailCell v-for="(item,index) in list" :key="index" titleExtra='' :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent(index)'></listDetailCell>
  </div>
</template>

<script>
  import listDetailCell from './list_research_cell.vue'
  export default {
    components: {
      listDetailCell
    },
    props: {
      list: Array
    },
    data () {
      return {
      }
    },
    methods: {
      openEvent (val) {
        this.$emit('openEventList', val)
      },
      closeEvent (val) {
        this.$emit('closeEventList', val)
      }
    }
  }
</script>

<style>
  #list-research{
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
  }
</style>
