<template>
  <div class="research_detail_wrapper">
    <div class="research_content_container">
      <!-- 左边 -->
      <div class="research_left">
        <div style="margin-top: 30px;margin-right: 0rpx;margin-left: 0rpx;display: flex;flex-direction: row;align-items: center;justify-content: center;">
          <label style="font-size: 14px;padding: 2px 5px;border-radius: 4px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
          <p class="research_title">{{title}}</p>
        </div>
        <div style="display: flex;flex-direction: column;">
          <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in contentArray" :key="indexModel+1">
               <div class="title-nav-view-ss">
                 <div class="flag-line-ss"></div>
                 <label class="nav-title">{{ itemModel.title }}</label>
               </div>
               <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
                 <label
                  v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-wrap;text-align: left;margin-left: 24px;margin-right: 24px;margin-bottom: 20px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
                 <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 48px);margin-left: 24px;margin-bottom: 20px;"  />
               </div>
             </div>
          </div>
          <div v-if="mainModel.tags && mainModel.tags != ''">
            <div class="title-nav-view-ss">
              <div class="flag-line-ss"></div>
              <label class="nav-title">标签</label>
            </div>
            <div class="detail_tags_main_view" style="padding-bottom: 20px;">
              <div v-for="(tag,tagIndex) in mainModel.tags.split('/')" :key="tagIndex">
                <div v-if="tag != ''" @click="goToSearch(tag)" class="detail_tags_view_label">
                  {{ tag }}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- 吸底 -->
    <div class="research_footer_container">
      <div class="research_footer_container_inner">
        <slot>
          <P class="research-detail-status" v-if="purchaseStatus === 'APPLIED'">正在处理购买请求</P> <!-- 购买申请中 -->
          <P class="research-detail-status" v-if="purchaseStatus === 'PURCHASED'" v-html="purchaseSuccess"></P> <!-- 购买成功 -->
          <P class="research-detail-status" v-if="purchaseStatus === 'FAILED'" v-html="purchaseFailed"></P> <!-- 购买失败 -->
          <button class="btn_corner_orange" v-if="purchaseStatus === 'INITIAL'" @click="showPurchaseDialog">购买研报</button>
<!--          <div class="interesting-btn" v-if="isInteresting === true" @click="cancelInteresting()">
            <img src="../assets/img/favsel.png" style="width: 27px;height: 25px;" />
            <p style="margin-left: 12px;color: #2F2F2F;font-size: 14px;">收藏</p>
          </div>
          <div class="interesting-btn" v-else @click="commitInteresting()">
            <img src="../assets/img/favunsel.png" style="width: 27px;height: 25px;" />
            <p style="margin-left: 12px;color: #2F2F2F;font-size: 14px;">收藏</p>
          </div> -->
        </slot>
      </div>
    </div>

    <el-dialog
      title="提交购买意向"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <span class="research-text-center" v-html="dialogContent"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="purchaseResearch">提交购买意向</el-button>
      </span>
    </el-dialog>

    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' extraMessage='提交反馈'></feedback>
    <unloginOptionView :noConference="true" resource_type='RESEARCH' :isShow="showLogin" @dissmiss='showLogin = false' @unloginCreateOrder='unloginCreateOrder' @loginSuccessEvent='loginSuccessEvent'></unloginOptionView>
  </div>
</template>

<script>
import researchApi from '@/api/research_report'
import store from '../store/index.js'
import feedback from "@/components/feedback"
import detailApi from '../api/un_login_detail.js'
import unloginOptionView from '../components/unlogin_option_view.vue'
export default{
  created () {
    let _this = this
    window.showFeedbackDialog = _this.showFeedbackDialog
  },
  components: {
    feedback,
    unloginOptionView
  },
  props: {

  },
  data () {
    return {
      researchId: 0,
      title: '【研究报告】',
      dialogContent: '费用说明：提交购买意向后，凯盛工作人员会尽快与您联系。',
      centerDialogVisible: false,
      purchaseStatus: 'INITIAL', //  APPLIED已发出购买申请,PURCHASED 已购买成功, FAILED失败
      purchaseSuccess: '<span>购买成功。如果您有疑问，请点击：<span style="color: deepskyblue" onclick="showFeedbackDialog()">这里</span></span>',
      purchaseFailed:  '<span>购买失败，请与您的服务团队联系：<span style="color: deepskyblue" onclick="showFeedbackDialog()">这里</span></span>',
      client_id: store.getters.uid,
      showFeedback: false,
      isInteresting: false,
      contentArray: [],
      token:'',
      showLogin:false,
      mainModel: {}
    }
  },
  watch: {
    '$route' () {
      if (this.tool.isNullOrEmpty(this.$route.query.id) === false) {
        this.researchId = parseInt(this.$route.query.id)
      }
      this.loadResearchDetail()
    }
  },
  mounted () {
    this.researchId = parseInt(this.$route.query.id)
    this.token = this.$route.query.token
    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (isMobile) {
      this.$router.replace({ path:'/mobileResearchDetail', query:{token:this.token}})
    }
    this.loadResearchDetail()
    if (this.tool.isNullOrEmpty(this.token) === true) {
      this.loadBtnStatus()
    }
  },
  methods: {
    goToSearch(tag) {
      this.$store.dispatch('view/changeSearchTag', tag)
    },
    getIconTypeClass() {
      if(this.mainModel.icon === 'CLIENT_PURCHASED') {
        return 'purchased_icon'
      }else {
        return ''
      }
    },
    getIconType() {
      if(this.mainModel.icon === 'CLIENT_PURCHASED') {
        return '公司已购'
      }else {
        return ''
      }
    },
    changeRightHost (val) {
      var hostArray = []
      if (val !== 0) {
        if (val.servant !== null) {
          val.servant.employee.position = '研究员'
          hostArray.push(val.servant)
        }
      }
      this.$store.dispatch('view/changeHostArray', hostArray)
    },
    showPurchaseDialog () {
      if (this.optionUnlogin()) {
        return
      }
      this.centerDialogVisible = true
    },
    purchaseResearch () {
      this.centerDialogVisible = false
      console.log('购买')
      let params = {
        contact_id: this.client_id,
        research_id: this.researchId,
        status: "APPLIED"
      }
      researchApi.purchaseResearchDetail(params).then(res =>{
        if(res.code === '0000'){
          this.purchaseStatus = res.data.status;
        }
      })
    },
    unloginCreateOrder (val) {
      this.showLogin = false
      var data = {}
      data.resource_id = this.researchId
      data.resource_type = 'RESEARCH'
      data.mobile = val
      detailApi.createOrder(data).then( res=> {
        if (res.code === '0000') {
          if(res.data.status === 'PENDING') {
            this.$message('请求提交成功，我们会尽快与您联系。')
          }
        }
      })
    },
    loginSuccessEvent () {
      this.showLogin = false
      this.client_id = this.$store.getters.uid
      this.loadResearchDetail()
    },
    optionUnlogin () {
      if (this.tool.isNullOrEmpty(this.$store.getters.uid) === true) {
        this.showLogin = true
        return true
      }
      this.showLogin = false
      return false
    },
    loadResearchDetail () {
      let params = {}
      if (this.tool.isNullOrEmpty(this.token) === false) {
        params.token = this.token
        detailApi.getDetail(params).then( res=>{
          if (res.code === '0000') {
            this.title = res.data.title
            this.mainModel = res.data
            this.researchId = res.data.id
            if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
              this.loadBtnStatus()
            }
            this.changeRightHost(res.data)
            var contents = res.data.content.split('@@@')
            contents.forEach( (item) => {
              if(this.tool.isNullOrEmpty(item) === false) {
                if (item.indexOf('###') !== -1) {
                  var itemModel = {}
                  itemModel.title = item.slice(0,item.indexOf('###'))
                  itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                  itemModel.imageTextArray = []

                  var imgTextArray = itemModel.content.split('<img>')
                  imgTextArray.forEach( imgText => {
                    var imgTextModel = {}
                    if (imgText.indexOf('</img>') !== -1) {
                      var  subImgTextArray = imgText.split('</img>')
                      if (subImgTextArray.length === 2) {
                        for (var i = 0;i < subImgTextArray.length;i++) {
                          var imgSubTextModel = {}
                          if (i === 0) {
                            imgSubTextModel.img = subImgTextArray[0]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }else {
                            imgSubTextModel.text = subImgTextArray[1]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }
                        }
                      }else {
                        imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    }else{
                      imgTextModel.text = imgText
                      itemModel.imageTextArray.push(imgTextModel)
                    }
                  })
                  this.contentArray.push(itemModel)
                }
              }
            })
            this.dialogContent = res.data.charge +'，收到您的购买意向后，我们会尽快与您联系。'
            if (this.mainModel.icon == 'CLIENT_PURCHASED') {
              this.dialogContent = '提交获取意向后，我们会尽快与您联系。'
            }
            this.isInteresting = res.data.like_status === 'YES'
          }
        })
      }else {
        if (this.tool.isNullOrEmpty(this.$store.getters.uid) === true) {
          this.$store.dispatch('user/logout')
          this.$router.replace('/login')
          return
        }
        params.id = this.researchId;
        params.extra = 'research_contacts,create_employee.user,my_task,servant.employee.user'
        researchApi.getResearches(params).then(res => {
          if(res.code === '0000'){
            this.changeRightHost(res.data)
            this.title = res.data.title
            this.mainModel = res.data
            var contents = res.data.content.split('@@@')
            contents.forEach( (item) => {
              if(this.tool.isNullOrEmpty(item) === false) {
                if (item.indexOf('###') !== -1) {
                  var itemModel = {}
                  itemModel.title = item.slice(0,item.indexOf('###'))
                  itemModel.content = item.slice(item.indexOf('###')+3,item.length)
                  itemModel.imageTextArray = []

                  var imgTextArray = itemModel.content.split('<img>')
                  imgTextArray.forEach( imgText => {
                    var imgTextModel = {}
                    if (imgText.indexOf('</img>') !== -1) {
                      var  subImgTextArray = imgText.split('</img>')
                      if (subImgTextArray.length === 2) {
                        for (var i = 0;i < subImgTextArray.length;i++) {
                          var imgSubTextModel = {}
                          if (i === 0) {
                            imgSubTextModel.img = subImgTextArray[0]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }else {
                            imgSubTextModel.text = subImgTextArray[1]
                            itemModel.imageTextArray.push(imgSubTextModel)
                          }
                        }
                      }else {
                        imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                        itemModel.imageTextArray.push(imgTextModel)
                      }
                    }else{
                      imgTextModel.text = imgText
                      itemModel.imageTextArray.push(imgTextModel)
                    }
                  })
                  this.contentArray.push(itemModel)
                }
              }
            })
            this.dialogContent = res.data.charge +'，收到您的购买意向后，我们会尽快与您联系。'
            if (this.mainModel.icon == 'CLIENT_PURCHASED') {
              this.dialogContent = '提交获取意向后，我们会尽快与您联系。'
            }
            this.isInteresting = res.data.like_status === 'YES'
          }else {
            this.showError(res.message)
          }
        })
      }
    },
    loadBtnStatus () {
      let params = {
        contact_id: this.client_id,
        research_id: this.researchId
      }
      researchApi.checkResearchStatus(params).then(res => {
        if(res.code === '0000') {
          let array = res.data
          if(array === null || array.length === 0){
            this.purchaseStatus = 'INITIAL'
          }else {
            this.purchaseStatus = array[0].status
          }
        }
      })
    },
    commitInteresting(){
      let params = {
        client_contact_id: this.client_id,
        resource_id: this.researchId,
        resource_type: 'RESEARCH'
      }
      researchApi.commitPraise(params).then(res=>{
        if(res.code === '0000'){
          this.isInteresting = true
        }
      })
    },
    cancelInteresting(){
      let params = {
        client_contact_id: this.client_id,
        resource_id: this.researchId,
        resource_type: 'RESEARCH'
      }
      researchApi.cancelPraise(params).then(res=>{
        if(res.code === '0000'){
          this.isInteresting = false
        }
      })
    },
    showError (msg) {
      console.log(msg)
    },
    showFeedbackDialog () {
      this.showFeedback = true
    },
    commitFeedback (valueMsg) {
      let params = {
        client_contact_id: this.client_id,
        content: valueMsg,
        researchId: this.researchId
      }
      researchApi.commitFeedback(params).then( res =>{
        if(res.code === '0000') {
          this.$message({
            type: 'success',
            message: '提交成功'
          });
        }else {
          this.$message({
            type: 'error',
            message: '提交失败'
          });
        }
      })
    }
  }
}
</script>

<style lang="scss">
.research_detail_wrapper{
  width: calc(100% - 335px);
  height: 100%;
  display: flex;
  display: -webkit-flex;
  background-color: #F6FAFB;
  flex-direction: column;
  padding-bottom: 67px;
}
.research_content_container{
  width: 100%;
  height:auto;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
}
.research_left{
  flex: 1;
  height: auto;
  margin-left: 0px;
  margin-top: 15px;
  background: white;
  border-radius: 8px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}
.research_footer_container{
  position: fixed;
  bottom: 0px;
  left: calc(50% - 640px);
  right: calc(50% - 640px);
  width: 945px;
  height: 67px;
  background-color: white;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.09);
}
.research_footer_container_inner{
  width: 945px;
  height: 100%;
  background-color: white;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn_corner_orange{
  background-color: #4880FF;
  color: white;
  font-size: 16px;
  border: 0;
  border-radius: 8px;
  width: 120px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}
.research-img-heart-interesting{
  width: 40px;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
}
.research_title{
  height: auto;
  font-size: 18px;
  color: #272727;
  font-weight: 500;
}
p.research_content{
  font-size: 14px;
  color: black;
  margin:0px 24px 20px 24px;
  text-align: left;
  line-height: 22px;
  white-space: pre-wrap;
}
p.research_content_un_indent{
  font-size: 14px;
  color: black;
  margin: 0px 24px 20px 24px;
  text-align: left;
  line-height: 22px;
  white-space: pre-wrap;
}
.research-text-center{
  text-align: center;
}
/*.research_detail_wrapper /deep/ .el-dialog__body{*/
/*  text-align: center;*/
/*}*/
.research-detail-status{
  color: #FFA000;
}
.el-message-box .el-input__inner{
  min-height: 48px;
  text-align: start;
}
.interesting-btn{
  width: 120px;
  height: 47px;
  border: 1px solid #DEDEDE;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    border: 1px solid #4880FF;
  }
}
 .title-nav-view-ss {
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flag-line-ss {
    margin-left: 0px;
    background-color: #4880FF;
    width: 6px;
    height: 18px;
  }

  .nav-title {
    color: #000000;
    font-size: 17px;
    font-weight: 500;
    margin-left: 15px;
  }
  .purchased_icon {
    padding: 2px 5px;
    background-color: #D8F1FA;
    color: #008BDE;
    font-size: 16px;
    margin-right: 5px;
  }
  .detail_tags_main_view {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 48px);
    margin-top: 4px;
    margin-left: 24px;
  }
  .detail_tags_view_label {
    padding: 4px 10px;
    margin-right: 6px;
    margin-top: 6px;
    border: 1px solid #1E459D;
    border-radius: 4px;
    font-size: 12px;
    color: #1E459D;
    &:hover {
      background-color: #f0f0f0;
    }
    cursor: pointer;
  }
</style>
