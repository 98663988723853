<template>
  <div class="menu-main-contain">
    <img @click="goToHome" class="logo-img" src="../assets/logo.svg" />
    <slot v-if="!isLoginPage">
      <div class="menu-item" v-for="(item,index) in menusArray" :data="item" :key="item.activeTitle" @click="clickEvent(item,index)" ref="menuItem">
        <p :class="item.isActive?'active-btn':'normal-btn'" type="text">{{item.isActive?item.activeTitle:item.normalTitle}}</p>
        <!-- <p class="info-text" v-if="item.isActive">{{item.info}}</p> -->
      </div>
    </slot>
   <div v-if="!isLoginPage" class="search-main-back">
      <img class="search-icon" src="../assets/img/sousuo.png" />
      <input @keydown="keyDownEvent()" class="input-view" v-model="searchKey" placeholder="请输入搜索关键字" />
      <p class="search-btn" @click="search">搜索</p>
    </div>
    <div v-if="!isLoginPage" class="user-name-set">
      <img class="set-icon" src="../assets/img/seticon.png" @click="jumpSettingPage"/>
      <el-dropdown trigger="click">
        <span @click="goToLogin" class="user-name">{{ name }}<i v-if="isLogin" class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu v-if="isLogin" slot="dropdown">
          <el-dropdown-item @click.native="clickLogout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-if="!isLoginPage" class="nav-footer-cursor" :style="{width:Width,left:Left}"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import numApi from '../api/conference.js'
export default {
  computed: {
    ...mapState({
      view: state => state.view.isLoginPage
    }),
    ...mapGetters('view', {
      isLoginPage: 'isLoginPage'
    }),
    name: function() {
      if(this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return '游客'
      }
      if(this.$store.getters.nickname===null || this.$store.getters.nickname ===''){
        return this.$store.getters.chinesename
      }
      return this.$store.getters.nickname
    },
    isLogin: function(){
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return false
      }else{
        return true
      }
    },
    ...mapState({
      user: state => state.user.sid,
    }),
    ...mapGetters('user', {
      sid: 'sid',
    })
    },
    watch: {
      sid() {
        if (this.tool.isNullOrEmpty(this.$store.getters.sid) === false) {
          this.requestNum()
      }
    }
  },
  created () {
    this.menuUpdate()
  },
  beforeUpdate () {
    this.menuUpdate()
  },
  mounted() {
    this.requestNum()
  },
  methods: {
    keyDownEvent () {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    goToHome () {
      if (this.isLogin === false) {
        this.$router.push({ path: '/' })
        return
      }
      if (this.$router.currentRoute.path === '/conference') {
        return
      }
      this.currentPage = 0
      this.menusArray.forEach((item, i) => {
        if (i === this.currentPage) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      this.changeIndex(this.currentPage)
      this.$router.push({ path: '/conference' })
	},
    goToLogin () {
      if (this.isLogin === true) {
        return
      }
      this.$router.push({ path: '/' })
    },
    requestNum () {
      if (this.currentPage === 0) {
        this.requestConference()
      }else if(this.currentPage === 1){
        this.requestResearch()
      }else if(this.currentPage === 2){
        this.requestTopic()
      }
    },
    clickEvent (item, index) {
      if (item.isActive === true && this.$router.currentRoute.path === item.path) {
        return
      }
      this.menusArray.forEach((item, i) => {
        if (i === index) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      this.changeIndex(index)
      if (index === 0) {
        this.requestConference()
      }else if(index === 1){
        this.requestResearch()
      }else if(index === 2){
        this.requestTopic()
      }
      this.$router.push(item.path)
    },
    requestConference () {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      if (this.isLoginPage === false) {
        numApi.confenenceNum().then( res=> {
            if (res.code === '0000') {
              this.changeInfo(res.data,0)
              }
        })
      }
    },
    requestResearch () {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      if (this.isLoginPage === false) {
        numApi.researchNum().then( res=> {
            if (res.code === '0000') {
              this.changeInfo(res.data,1)
              }
        })
      }
    },
    requestTopic () {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      if (this.isLoginPage === false) {
        numApi.topicNum().then( res=> {
            if (res.code === '0000') {
              this.changeInfo(res.data,2)
              }
        })
      }
    },
    changeInfo (val,index) {
		this.menusArray.forEach((item, i) => {
			if (i === index) {
        var single = "场"
        if (index === 0) {
          single = "场"
        }else if(index === 1) {
          single = "份"
        }else if(index === 2) {
          single = "个"
        }
				item.info = `共${val.total_count}${single}，本周新增${val.this_week_count}${single}`
        }
		})
    },
    menuUpdate () {
      if (this.isLoginPage) {
        return
      }
      if (this.$router.currentRoute.path === '/conference' || this.$router.currentRoute.path === '/conference/detail') {
        this.currentPage = 0
      } else if (this.$router.currentRoute.path === '/research' || this.$router.currentRoute.path.indexOf('/researchDetail') !== -1) {
        this.currentPage = 1
      } else if (this.$router.currentRoute.path === '/conferenceMinder') {
        this.currentPage = 2
      }
      this.menusArray.forEach((item, i) => {
        if (i === this.currentPage) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      this.changeIndex(this.currentPage)
    },
    changeIndex (index) {
      setTimeout(() => {
        //const rect = this.$refs.menuItem[index].offsetLeft
        let positionNum = this.$refs.menuItem[index].offsetLeft + 45
        this.Left = positionNum + 'px'
      }, 10)
    },
    search () {
      if (this.tool.isNullOrEmpty(this.searchKey)) {
        return
      }
      if (this.$router.currentRoute.path === '/search') {
        this.$bus.$emit('searchEvent', this.searchKey)
        return
      }
      this.$router.push({
        path: '/search',
        query: {
          keyword: this.searchKey
        }
      })
    },
    changeEvent () {
      this.$store.dispatch('view/changeSearchKeyword', this.searchKey)
    },
    jumpSettingPage () {
      if (this.$router.currentRoute.path === '/setting') {
        return
      }
      this.$router.push('/setting')
    },
    clickLogout (){
      this.$store.dispatch('user/logout')
      this.$router.replace('/login')
    }
  },
  data () {
    return {
      Left: '',
      Width: '25px',
      searchKey: '',
      menusArray: [
        {isActive: true, activeTitle: '凯盛会议', normalTitle: '凯盛会议', info: '共0场，本周新增0场', path: '/conference'},
        {isActive: false, activeTitle: '研究报告', normalTitle: '研究报告', info: '共0份，本周新增0份', path: '/research'},
        // {isActive: false, activeTitle: '会议图谱', normalTitle: '会议图谱', info: '共0份，本周新增0份', path: '/conferenceMinder'},
        // {isActive: false, activeTitle: '凯盛33', normalTitle: '凯盛33', info: '共0个，本周新增0个', path: '/topic'},
      ],
      currentPage: 0,
      logoutOption:[{
        value: '退出登录',
        label: '退出登录'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-main-contain{
    position: fixed;
    height: 74px;
    display: flex;
    z-index: 998;
    flex-direction: row;
    align-items: center;
    background:rgba(255,255,255,1);
    box-shadow:0px 3px 6px rgba(0,0,0,0.16);
    opacity:1;
    width: 1280px;
    margin: 0px auto;
  }
  .logo-img{
    height: 50px;
    width: 190px;
    margin-left: 30px;
    margin-right: 40px;
  }
  .menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    cursor: pointer;
    &:hover {
      color: #2C57B8;
    }
  }
    .search-main-back{
      background-color: #F5F5F5;
      height: 28px;
      width: 220px;
      margin-left: auto;
      margin-right: 20px;
      border:0.5px solid #e0e0e0;
      border-radius:28px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  .search-icon{
    margin-left: 10px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .input-view::-webkit-input-placeholder{
    color:#bfbfbf;
  }
    .input-view{
      background-color: rgb(1,1,1,0);
      margin-left: 0px;
      margin-right: 0px;
      height: 28px;
      width: 110px;
      border: 0;
      outline: none;
    }
    .search-btn{
      margin-left: auto;
      margin-right: 0px;
      background-color: #315FD6;
      width: 50px;
      height: 28px;
      border-radius:14px;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 28px;
      cursor: pointer;
      &:hover {
        background-color: #5a7dd9;
      }
    }
  .nav-footer-cursor{
    height: 3px;
    border-radius: 3px;
    background-color: #2C57B8;
    position: absolute;
    top: 70px;
    transition: all 0.15s linear 0.01s;
  }
    .active-btn{
      color: #2C57B8;
      font-size: 18px;
      font-family:PingFang SC;
      font-weight: 600;
    }
    .normal-btn{
      font-size: 18px;
      font-family:PingFang SC;
      font-weight: 600;
    }
    .info-text{
      color: #7E7E7E;
      text-align: left;
      font-size: 12px;
      font-weight: 200;
      padding-left: 10px;
      padding-right: 0px;
    }
  .user-name-set{
    max-width: 287px;
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
    align-items: center;
  }
  .set-icon{
    height: 20px;
    width: 20px;
    margin-right: 26px;
    cursor: pointer;
  }
    .user-name{
      color: #404040;
      font-size: 16px;
      line-height: 20px;
      height: 20px;
      font-weight: bold;
      text-align: right;
      margin-right: 28px;
      overflow: hidden;
    }
</style>
