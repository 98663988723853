<template>
  <div class="team_right">
    <div v-if="isLogin" class="card-server-team-contaier">
       <div style="width: 100%; height: 26px; display: flex; display: -webkit-flex; margin-bottom: 6px;">
         <div style="background-color: #4880FF; width: 6px; height: 18px; margin-top: 16px;"></div>
         <div style="color:black; font-size: 18px; margin-left: 14px; margin-top: 12px; font-weight: bold;">服务团队</div>
       </div>
       <div class="list_card_container" v-for="(hostItem, i) in getHostArray" :data="hostItem" :key="hostItem.name">
         <div class="team_host_horizon_container">
<!--           <img class="avatar_team_host" v-if="hostItem.gender==1" src="../assets/img/avatar_boy-01.png"/>
           <img class="avatar_team_host" v-else src="../assets/img/avatar_girl-01.png"/> -->
           <div class="team_host_vertical_container">
             <div style="font-size: 16px; color: black; text-align: left; font-weight: bold;">{{ hostItem.employee.englishname + '    ' }}<span style="color: #FFA000;font-size: 12px;">{{ hostItem.employee.position }}</span></div>
             <div style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;">
               <img style="width: 14px;height: 14px;margin-right: 10px;" src="../assets/img/iphone.png" />
               <div style="font-size: 14px; color: black; text-align: left;font-weight: 500;">{{ hostItem.employee.phone }}</div>
             </div>
             <div v-if="hostItem.email !== null" @click="sendMail(hostItem.email)" style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;">
               <img style="width: 14px;height: 14px;margin-right: 10px;" src="../assets/img/email.png" />
               <div style="font-size: 14px;text-align: left;color: #4880FF;font-weight: 500;">{{ hostItem.email }}</div>
             </div>
           </div>
         </div>
          <div class="divider_grey_percent80_center" v-if="i != getHostArray.length-1 && amArray.length === 0"></div>
       </div>
<!--       <div class="list_card_container" v-for="(hostItem, i) in amArray" :data="hostItem" :key="hostItem.name">
         <div class="team_host_horizon_container">
           <div class="team_host_vertical_container">
             <div style="font-size: 16px; color: black; text-align: left; font-weight: bold;">{{ hostItem.englishname + '    ' }}<span style="color: #FFA000;font-size: 12px;">客户经理</span></div>
             <div v-if="hostItem.phone" style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;">
               <img style="width: 14px;height: 14px;margin-right: 10px;" src="../assets/img/iphone.png" />
               <div style="font-size: 14px; color: black; text-align: left;font-weight: 500;">{{ hostItem.phone }}</div>
             </div>
             <div v-if="hostItem !== null" @click="sendMail(hostItem.email)" style="display: flex;flex-direction: row;align-items: center;margin-top: 5px;">
               <img v-if="hostItem.email !== null" style="width: 14px;height: 14px;margin-right: 10px;" src="../assets/img/email.png" />
               <div v-if="hostItem.email !== null" style="font-size: 14px;text-align: left;color: #4880FF;font-weight: 500;">{{ hostItem.email }}</div>
             </div>
           </div>
         </div>
          <div class="divider_grey_percent80_center" v-if="i != amArray.length-1"></div>
       </div> -->
       <button class="conference_btn_commit" @click="showFeedbackDialog">提交反馈</button>
    </div>
    <div class="ad_view">
      <div style="cursor: pointer;" @click="showFeedback = true" class="ad_view_item">
        <img style="margin-top: 8px;width: 100px;height: 85px;" src="../assets/img/feedback_new_s.svg" />
        <div style="margin-top: 9px;color: #1E459D;font-size: 11px;font-weight: bold;">意见反馈</div>
      </div>
      <div style="cursor: pointer;" class="ad_view_item" @click="goToDownload">
        <img style="margin-top: 8px;width: 85px;height: 85px;" src="../assets/img/qrcode_new_s.svg" />
        <div style="margin-top: 9px;color: #1E459D;font-size: 11px;font-weight: bold;">凯盛专家APP</div>
      </div>
    </div>
   <!-- <div class="card-server-team-contaier"> -->
<!-- 		<img style="width: 100%;height: auto;" src="../assets/img/app_info.svg" />
 --><!--      <div style="width: 100%; height: 26px; display: flex; display: -webkit-flex; margin-bottom: 6px;">
        <div style="background-color: #4880FF; width: 6px; height: 18px; margin-top: 16px;"></div>
        <div style="color:black; font-size: 18px; margin-left: 14px; margin-top: 12px; font-weight: bold;">凯盛专家APP</div>
      </div>
      <img class="qrcode_image_center" src="../assets/img/qr_code.png"/>
      <p style="codisplay: flex; color: #919191; font-size: 12px; margin-top:5px; margin-bottom: 42px;">扫码下载“凯盛专家”APP<br/>随时随地查项目、看研报、约访谈</p> -->
    <!-- </div> -->
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' extraMessage='服务团队入口：'></feedback>
   </div>
</template>

<script>
import rightBarApi from "../api/right_bar.js"
import { mapState,mapGetters } from "vuex"
import feedback from './feedback.vue'
export default {
  computed: {
    ...mapGetters('view', {
      getHostArray: 'getHostArray'
    }),
    isLogin: function(){
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return false
      }else{
        return true
      }
    },
    ...mapState({
      user: state => state.user.sid
    }),
    ...mapGetters('user', {
      sid: 'sid'
    })
  },
  watch: {
    sid() {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === false) {
        this.getAmList()
      }
    }
  },
  components: {
    feedback
  },
  mounted () {
    this.getAmList()
  },
  data () {
    return {
      amArray: [],
      showFeedback:false
    }
  },
  methods: {
    goToDownload() {
      window.open('http://iksh.com', '_blank')
    },
    sendMail (val) {
      if (this.tool.isNullOrEmpty(val) ===  true) {
        return
      }
      window.location.href = `mailto:${val}`
    },
    showFeedbackDialog () {
      this.showFeedback = true
    },
    getAmList() {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      var params = {}
      params.id = this.$store.getters.client.id
      params.extra = 'am_list.employee,primary_am.user'
      rightBarApi.getRightMenuClientList(params).then( res => {
        if (res.code === '0000') {
          if (res.data !== null) {
            if (res.data.primary_am !== null) {
              this.amArray.push(res.data.primary_am)
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .ad_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  .ad_view_item {
    width: 155px;
    height: 125px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ad_view_item_content {
    height: 110px;
    width: calc(100% - 70px);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .team_right{
    width: 100%;
  }
  .avatar_team_host{
    height: 66px;
    width: 66px;
    padding: 16px;
  }
  .card-server-team-contaier{
    width: 100%;
    height: auto;
    margin-top: 14px;
    margin-right: 10px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
  }
  .team_host_horizon_container{
    width: 100%;
    height: 100%;
    margin-top: 12px;
    padding-left: 20px;
    background-color: white;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
  }
  .conference_btn_commit{
    width: calc(100% - 54px);
    height: 38px;
    font-size: 14px;
    background-color: #4880FF;
    color: white;
    border-radius: 8px;
    margin-top: 14px;
    margin-bottom: 24px;
    border-color: #F6FAFB;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .qrcode_image_center{
    width: 180px;
    height: 180px;
    margin-top: 27px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
  }
  .list_card_container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .divider_grey_percent80_center{
    width: calc(100% - 58px);
    height: 1px;
    margin-top: 18px;
    background-color: #EDEDED;
  }
</style>
