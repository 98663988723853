<template>
  <div id="list-top-menu">
    <div class="right-option-menu">
      <el-tooltip class="item" effect="dark" :content="allOpen ? '仅展开一个卡片' : '展开所有卡片'" placement="top-start">
        <div class="btn-view" @click="changeMenu">
          <div class="line"></div>
          <img class="btn-icon" src="../assets/img/change_icon.png" />
        </div>
      </el-tooltip>
<!--      <div class="num-view">
        <label class="num-tip1">共</label>
        <label class="num-text">{{num}}</label>
        <label class="num-tip2">条<span class="responsive-ipad-show">内容</span></label>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
    allOpen: Boolean
  },
  data () {
    return {
    }
  },
  methods: {
    changeMenu () {
      this.$emit('changeEvent')
    }
  }
}
</script>

<style>
  #list-top-menu {
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
    display: flex;
    flex-direction: row;
    align-items: center;
    /*height: 50px;*/
    min-height: 50px;
  }
  .right-option-menu {
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    margin-left: auto;
    align-items: center;
  }

  .btn-view {
    margin-right: 0px;
    width: 50px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .btn-icon {
    width: 20px;
    height: 16px;
  }

  .line {
    height: 16px;
    width: 1px;
    background-color: #E0E0E0;
    margin-left: 0px;
    margin-right: 15px;
  }

  .num-view {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }

  .num-tip1 {
    font-size: 12px;
    color: #272727;
    margin-right: 8px;
  }

  .num-tip2 {
    font-size: 12px;
    color: #272727;
    margin-left: 8px;
  }

  .num-text {
    font-size: 18px;
    color: #FFA000;
  }

  @media only screen and (max-width: 768px) {
    .responsive-ipad-show {
      display: none;
    }
  }

</style>
