const state = {
  showRightMenu: true,
  isLoginPage: false,
  hostArray: [],
  searchKeyword: '',
  showTopMenu: true,
  leftMainH: 0,
  expertListToken: '',
  fromShareList: false,
  showConferenceMenu: false,
  searchTag: '',
  searchConferenceTag: '',
  searchIndustryId: ''
}
const getters = {
  searchIndustryId: state => {
    return state.searchIndustryId
  },
  searchConferenceTag: state => {
    return state.searchConferenceTag
  },
  searchTag: state => {
    return state.searchTag
  },
  showRightMenu: state => {
    return state.showRightMenu
  },
  showConferenceMenu: state => {
    return state.showConferenceMenu
  },
  expertListToken: state => {
	return state.expertListToken
  },
  fromShareList: state => {
	return state.fromShareList
  },
  isLoginPage: state => {
    return state.isLoginPage
  },
  getHostArray: state => {
    return state.hostArray
  },
  getSearchKeyword: state => {
    return state.searchKeyword
  },
  showTopMenu: state => {
    return state.showTopMenu
  },
  leftMainH: state => {
    return state.leftMainH
  }
}
const actions = {
  changeSearchIndustryId({ commit }, data) {
    commit('set', ['searchIndustryId', data])
  },
  changeSearchConferenceTag({ commit }, data) {
    commit('set', ['searchConferenceTag', data])
  },
  changeSearchTag({ commit }, data) {
    commit('set', ['searchTag', data])
  },
  changeShowConference ({ commit }, data) {
    commit('set', ['showConferenceMenu', data])
  },
  changeShow ({ commit }, data) {
    commit('set', ['showRightMenu', data])
  },
  updataFromShareList ({ commit }, data) {
    commit('set', ['fromShareList', data])
  },
  updataExpertListToken ({ commit }, data) {
    commit('set', ['expertListToken', data])
  },
  changeIsLoginPapge ({ commit }, data) {
    commit('set', ['isLoginPage', data])
  },
  changeHostArray ({ commit }, data) {
    commit('set', ['hostArray', data])
  },
  changeSearchKeyword ({ commit }, data) {
    commit('set', ['searchKeyword', data])
  },
  changeShowTopMenu ({ commit }, data) {
    commit('set', ['showTopMenu', data])
  },
  changeLeftMainH ({ commit }, data) {
    commit('set', ['leftMainH', data])
  }
}
const mutations = {
  set (state, [name, data]) {
    state[name] = data
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
