<template>
  <div :class="showTopMenu ? 'pc-app' : 'app'">
    <homeMenu v-if="showTopMenu"></homeMenu>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" id="main" :class="showTopMenu ? 'contain-view' : 'contain-view-h'"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" id="main" :class="showTopMenu ? 'contain-view' : 'contain-view-h'"/>
    <rightMenu v-if="showRightMenu && showConferenceMenu == false"></rightMenu>
    <conference-right-menu :isIndustry="$route.meta.rightIndutsry" v-if="showConferenceMenu"></conference-right-menu>
    <SiteFooter v-if="(showRightMenu == false && showConferenceMenu == false) && showTopMenu == true"></SiteFooter>
    <div class="iphoneXBottom"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import homeMenu from './components/capvision_home_nav.vue'
import rightMenu from './components/right_menu.vue'
import SiteFooter from './components/SiteFooter.vue'
import conferenceRightMenu from './components/conference_right_menu.vue'
export default {
  computed: {
    ...mapGetters('view', {
      showRightMenu: 'showRightMenu',
      showTopMenu: 'showTopMenu',
      showConferenceMenu: 'showConferenceMenu'
    })
  },
  components: {
    homeMenu,
    rightMenu,
    SiteFooter,
    conferenceRightMenu
  },
  data () {
    return {
    }
  },
  mounted () {
    document.querySelector('body').setAttribute('style', 'background-color: #F6FAFB;')
  },
  methods: {
  },
  created () {
  }
}
</script>

<style>
.pc-app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1280px;
  margin: 0px auto;
  position: relative;
}
.app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#main {
  min-height: calc(100vh - 74px);
}
.contain-view{
  padding-top: 74px;
}
.contain-view-h
{
  padding-top: 0px;
}
.iphoneXBottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: env(safe-area-inset-bottom);
  background: #fff;
  z-index: 500;
}
</style>
