<template>
  <div style="display: flex;flex-direction: row;">
    <div style="margin-left: auto;display: flex;flex-direction: column;align-items: center;">
      <img style="width: 440px;height: 400px;margin-top: 60px;margin-right: 90px;" src="../assets/img/loginImg.png" />
      <!-- <label style="margin-top: 40px;font-size: 15px;text-align: center;width: 428px;color: #1E459D;">{{'凯盛内容平台发布行业会议、研究报告、凯盛33等行业信息' + total_count + `条，${doHandleDate()}新增` + added_this_month_count + '条。'}}</label> -->
    </div>
    <loginMenu></loginMenu>
  </div>
</template>

<script>
import loginMenu from '../components/login_menu.vue'
import loginAPI from '../api/login.js'
export default {
  components: {
    loginMenu
  },
  data () {
    return {
      total_count: 0,
      added_this_month_count: 0
    }
  },
  mounted () {
    // this.getHomepageInfo()
  },
  methods: {
    getHomepageInfo () {
      loginAPI.homepageInfo().then(res => {
        if (res) {
          this.total_count = res.data.total_count
          this.added_this_month_count = res.data.added_this_month_count
        }
      })
    },
    doHandleDate () {
      var myDate = new Date()
      var tYear = myDate.getFullYear()
      var tMonth = myDate.getMonth()
      var m = tMonth + 1
      return tYear + '年' + m + '月'
    }
  }
}
</script>

<style>
</style>
