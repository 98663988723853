<template>
  <div class="zero-industry-view">
    <div v-if="zeroIndustrys.length > 0" class="zero-industry-content-back">
      <div class="zero-industry-content">
        <div v-for="(item,index) in zeroIndustrys" :key="index">
          <button @click="selZeroEvent(index)" :class="index == zeroSelIndex ? 'zero_item_sel' : 'zero_item'">{{ item }}</button>
        </div>
      </div>
    </div>
    <div v-if="showIndustrys.length > 0" class="main-industry-view">
      <div class="content-view">
        <button class="industry-item" v-for="(subitem,subIndex) in showIndustrys" :key="subIndex" :class="subitem.isSel ? 'sel-status' : 'nor-status'" @click="selectEvent(subitem)">{{subitem.name}}</button>
        <button v-if="zeroSelIndustrys.length>15" @click="openEvent()" class="industry-item-more">{{ isOpen?'收起':'展开' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import conference from '../api/conference.js'
  export default {
    data () {
      return {
        zeroIndustrys: [],
        industrys: [],
        isOpen: false,
        showIndustrys: [],
        zeroSelIndex: 0,
        zeroSelIndustrys: []
      }
    },
    props: {
      haveDataIndustrys: Array,
      clearFlag: Boolean
    },
    mounted() {
      this.getZeroIndustrys()
    },
    watch: {
      clearFlag: function() {
        if (this.clearFlag == true) {
          this.zeroSelIndex = 0
          this.getZeroIndustrys()
        }
      },
      haveDataIndustrys:function() {
        this.getZeroIndustrys()
      },
      zeroSelIndustrys: function() {
        this.showIndustrys = []
        if (this.zeroSelIndustrys.length >= 16) {
          this.isOpen = false
          var num = 0
          this.zeroSelIndustrys.forEach( res => {
            if (num<15) {
              this.showIndustrys.push(res)
            }
            num++
          })
        }else{
          this.zeroSelIndustrys.forEach( res => {
            this.showIndustrys.push(res)
          })
        }
      }
    },
    methods: {
      selZeroEvent(index) {
        this.zeroSelIndex = index
        this.zeroSelIndustrys.length = 0
        this.showIndustrys.length = 0
        this.industrys.forEach( item=>{
          if (item.zero_industry == this.zeroIndustrys[this.zeroSelIndex] || this.zeroSelIndex==0) {
            item.isSel = false //可以每次切换清楚选中
            this.zeroSelIndustrys.push(item)
          }
        })
        var industry_ids = ''
        this.industrys.forEach( res => {
          if (res.zero_industry == this.zeroIndustrys[this.zeroSelIndex]) {
            industry_ids = industry_ids.concat(res.id + ',')
          }
        })
        if (this.zeroSelIndex == 0) {
          this.$emit('screenIndustry_ids', '')
        }else {
          this.$emit('screenIndustry_ids', industry_ids.substr(0, industry_ids.length - 1))
        }
      },
      openEvent () {
        this.showIndustrys = []
        this.isOpen = !this.isOpen
        if (this.isOpen === true) {
          this.zeroSelIndustrys.forEach( res => {
            this.showIndustrys.push(res)
          })
        }else{
          var num = 0
          this.zeroSelIndustrys.forEach( res => {
            if (num<15) {
              this.showIndustrys.push(res)
            }
            num++
          })
        }
      },
      selectEvent (val) {
        val.isSel = !val.isSel
        var industry_ids = ''
        this.industrys.forEach( res => {
          if (res.id !== '0' && res.isSel === true) {
            industry_ids = industry_ids.concat(res.id + ',')
          }
        })
        if (this.zeroSelIndex != 0 && industry_ids == '') {
          this.industrys.forEach( res => {
            if (res.id !== '0' && res.zero_industry == this.zeroIndustrys[this.zeroSelIndex]) {
              industry_ids = industry_ids.concat(res.id + ',')
            }
          })
        }
        this.$emit('screenIndustry_ids', industry_ids.substr(0, industry_ids.length - 1))
        this.$forceUpdate()
      },
      isHaveIndustrys () {
        return true
        // var ishave = false
        // this.haveDataIndustrys.forEach( res => {
        //   if (parseInt(res.value) === parseInt(val.id)) {
        //     ishave = true
        //   }
        // })
        // return ishave
      },
      getZeroIndustrys() {
        conference.getZeroIndustries().then( zeroRes=> {
          if (zeroRes.code == '0000') {
            this.zeroIndustrys.length = 0
            this.zeroIndustrys.push('全部行业')
            zeroRes.data.forEach( zeroItem=> {
              this.zeroIndustrys.push(zeroItem)
            })
            conference.getIndustries().then(res => {
              if  (res.code == '0000') {
                this.industrys.length = 0
                this.zeroSelIndustrys.length = 0
                res.data.forEach( item => {
                  if (this.isHaveIndustrys(item)) {
                    this.industrys.push(item)
                    this.zeroSelIndustrys.push(item)
                  }
                })
              }
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .zero-industry-view {
    width: calc(100% - 15px);
    margin-top: 16px;
    margin-left: 0px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .zero-industry-content-back {
    width: 100%;
    border-bottom: 1px solid #F6FAFB;
    height: 68px;
    margin: 0px;
  }
  .zero-industry-content {
    width: calc(100% - 41px);
    float: left;
    margin: 0px 27px 0px 14px;
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
  }
  .zero_item_sel {
    border: none;
    background-color: #1E459D;
    outline: none;
    height: 34px;
    border-radius: 4px;
    cursor: pointer;
    height: 34px;
    line-height: 34px;
    padding: 0px 15px;
    width: 100px;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    margin: 0px 0px 0px 13px;
    white-space:nowrap;
  }
  .zero_item {
    border: none;
    background-color: #FFFFFF;
    outline: none;
    height: 34px;
    border-radius: 4px;
    cursor: pointer;
    height: 34px;
    line-height: 34px;
    padding:0px 15px;
    width: 100px;
    text-align: center;
    color: #121212;
    font-size: 14px;
    margin: 0px 0px 0px 13px;
    white-space:nowrap;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .main-industry-view {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .content-view{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 4px 14px 14px;
    overflow: hidden;
    transform: height .3s;
  }
  .industry-item{
    border: none;
    margin: 0 0 13px 13px;
    text-align: center;
    font-size: 14px;
    width: 100px;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .industry-item-more{
    border: none;
    margin: 0 0 10px 10px;
    text-align: center;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    width: 100px;
    padding: 0 15px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #F6FAFB;
    color: #4880FF;
  }
  .sel-status{
    background-color: #4880FF;
    color: #FFFFFF;
  }
  .nor-status{
    background-color: #F6FAFB;
    color: #121212;
    &:hover {
      background-color: #f0f0f0;
    }
  }
</style>
