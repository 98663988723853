<template>
  <div id="login_menu">
    <div class="login_back">
      <transition name="slide-fade">
        <label style="width: 328px;height:30px;border-radius: 2px;line-height: 30px;color: #CA3F3F;background-color: #FFE0E0;font-size: 12px;margin-top: 60px;position: absolute;" v-if="errorMessage.length !==0">{{errorMessage}}</label>
      </transition>
      <div class="nav-view">
        <label @click="changeLoginType(0)" style="margin-left: 36px;" :class="loginType === 0?'native-label':'normal-label'">验证码登录</label>
        <label @click="changeLoginType(1)" style="margin-right: 36px;margin-left: auto;" :class="loginType === 1?'native-label':'normal-label'">密码登录</label>
        <div class="nav-flag" :style="{left:leftx}"></div>
      </div>
      <div v-if="loginType === 0" style="display: flex;flex-direction: column;margin-top: 32px;width: 400px;">
        <label style="color:#202224;font-size: 12px;text-align: left;margin-left: 36px;font-weight: 500;opacity: 0.8;">账号:</label>
        <div style="height: 36px;margin-left: 36px;margin-top: 12px;display: flex;flex-direction: row;">
          <div style="width: 240px;height: 36px;margin-left: 0px;background-color: #F1F4F9;border-radius: 4px;border: 1px solid #D8D8D8;">
            <div class="input-min">
              <input class="input-view-s" v-model="account" placeholder="您提供给凯盛的手机号/邮箱" />
            </div>
          </div>
          <button @click="btnSend()" style="cursor: pointer;margin-right: 36px;font-size: 12px;margin-left: auto;color:#FFFFFF;background-color: #00B2F6;border-radius: 4px;height: 36px;width: 78px;outline: none;border: none;">{{sendCodeBtnText}}</button>
        </div>
        <label style="color:#202224;font-size: 12px;text-align: left;margin-left: 36px;font-weight: 500;opacity: 0.8;margin-top: 14px;">验证码:</label>
        <div style="margin-right: 36px;height: 36px;margin-left: 36px;background-color: #F1F4F9;border-radius: 4px;border: 1px solid #D8D8D8;margin-top: 12px;">
          <div class="input-min">
            <input @keydown="keyDownEvent()" class="input-view-s" v-model="code" placeholder="请输入验证码" />
          </div>
        </div>
      </div>
      <div v-else-if="loginType === 1" style="display: flex;flex-direction: column;margin-top: 32px;width: 400px;">
        <label style="color:#202224;font-size: 12px;text-align: left;margin-left: 36px;font-weight: 500;opacity: 0.8;">账号:</label>
        <div style="margin-right: 36px;height: 36px;margin-left: 36px;background-color: #F1F4F9;border-radius: 4px;border: 1px solid #D8D8D8;margin-top: 12px;">
          <div class="input-min">
            <input class="input-view-s" v-model="account" placeholder="您提供给凯盛的手机号/邮箱" />
          </div>
        </div>
        <div style="display: flex;flex-direction: row;">
          <label style="color:#202224;font-size: 12px;text-align: left;margin-left: 36px;font-weight: 500;opacity: 0.8;margin-top: 14px;">密码:</label>
          <label @click="changeLoginType(0)" style="cursor: pointer;color:#202224;font-size: 12px;text-align: left;margin-right: 36px;font-weight: 500;opacity: 0.8;margin-top: 14px;margin-left: auto;">忘记密码？</label>
        </div>
        <div style="margin-right: 36px;height: 36px;margin-left: 36px;background-color: #F1F4F9;border-radius: 4px;border: 1px solid #D8D8D8;margin-top: 12px;">
          <div class="input-min">
            <input @keydown="keyDownEvent()" ref="pass" type="password" class="input-view-s" v-model="pwd" placeholder="请输入密码" />
            <img @click="showPasswordType(false)" v-if="showpass" style="cursor: pointer;width: 20px;height: 14px;margin-left: 10px;margin-right: 8px;" src='../assets/img/passwordshow.png' />
            <img @click="showPasswordType(true)" v-else style="cursor: pointer;width: 19px;height: 16px;margin-left: 10px;margin-right: 8px;" src='../assets/img/login_password.png' />
          </div>
        </div>
      </div>
      <p style="margin-bottom: 30px;margin-right: 36px;margin-left: 36px;margin-top: 20px;color: #434343;font-size: 12px;">登录后，可查看、购买凯盛会议、研报、访谈主题等行业内容。若无法登录，请联系您的客户经理或<span @click="goToFeedBack" style="cursor: pointer;color: #00B2F6;font-size: 14px;">意见反馈</span></p>
      <el-button :loading="loading" type="primary" @click="loginEvent" style="margin-top: auto;margin-bottom: 30px;height: 45px;color: #FFFFFF;font-size: 16px;background-color: #1E459D;border-radius:4px;outline: none;border: none;width: 328px;">登录</el-button>
    </div>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false'></feedback>
  </div>
</template>

<script>
import { login, askVerificationCode } from '../api/login.js'
import feedback from './feedback.vue'
export default {
  components: {
    feedback
  },
  data () {
    return {
      account: '',
      pwd: '',
      code: '',
      loginType: 0, // 0验证码登录 1密码登录
      leftx: '68px',
      errorMessage: '',
      sendCodeBtnText: '发送验证码',
      disabled: false,
      loading: false,
      showpass: false,
      showFeedback: false
    }
  },
  methods: {
    keyDownEvent () {
      if (event.keyCode === 13) {
        this.loginEvent()
      }
    },
    changeLoginType (type) {
      this.loading = false
      this.loginType = type
      if (this.loginType === 0) {
        this.leftx = '68px'
      } else if (this.loginType === 1) {
        this.leftx = '232px'
      }
    },
    showPasswordType (val) {
      this.showpass = val
      if (this.showpass) {
        this.$refs.pass.setAttribute('type' ,'text')
      } else {
        this.$refs.pass.setAttribute('type' ,'password')
      }

    },
    goToFeedBack () {
      this.showFeedback = true
    },
    showError (msg) {
      this.errorMessage = msg
      setTimeout(() => {
        this.errorMessage = ''
      }, 4000)
    },
    loginEvent () {
      if (this.tool.isNullOrEmpty(this.account)) {
        this.showError('请填写帐号')
        return
      }
      var loginData = {}
      if (this.loginType === 0) {
        if (this.tool.isNullOrEmpty(this.code)) {
          this.showError('验证码不能为空')
          return
        }
        loginData.account = this.account
        loginData.code = this.code
      } else if (this.loginType === 1) {
        if (this.tool.isNullOrEmpty(this.pwd)) {
          this.showError('密码不能为空')
          return
        }
        loginData.account = this.account
        loginData.pwd = this.pwd
      }
      this.loading = true
      login(loginData, this.loginType).then(res => {
        this.loading = false
        if (res.code === '0000') {
          this.$store.dispatch('user/setUserInfo', res.data.client_contact)
          this.$store.dispatch('user/setSid', res.data.sid)
          if (this.tool.isNullOrEmpty(res.data.contract_type) === false) {
            this.$store.dispatch('user/setContractType', res.data.contract_type)
          }
          if (this.tool.isNullOrEmpty(res.data.client_contact.profile) === false) {
            this.$store.dispatch('user/setNickname', res.data.client_contact.profile.name)
          }
          this.$router.replace('/conference')
        } else {
          this.showError(res.message)
        }
      })
    },
    doLoop (seconds) {
      seconds = seconds || 60
      this.sendCodeBtnText = seconds + 's后获取'
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.sendCodeBtnText = seconds + 's后获取'
          --seconds
        } else {
          this.sendCodeBtnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },
    btnSend () {
      if (this.disabled === true) {
        return
      }
      if (this.tool.isNullOrEmpty(this.account)) {
        this.showError('请填写帐号')
        return
      }
      this.disabled = true
      this.sendCodeBtnText = '请稍候...'
      this.getVerificationCode()
    },
    getVerificationCode () {
      var data = {}
      data.account = this.tool.getRsaCode(this.account)
      askVerificationCode(data).then(res => {
        if (res.code !== '0000') {
          this.disabled = false
          this.sendCodeBtnText = '获取验证码'
          this.showError(res.message)
        }else if(res.code === '0000') {
          setTimeout(() => {
            this.doLoop(60)
          })
        }
      })
    }
  }
}
</script>

<style>
  #login_menu {
    width: 400px;
    margin-top: 60px;
    margin-right: auto;
    margin-left: 90px;
  }
  .login_back {
    position: relative;
    width: 400px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 0px solid #B9B9B9;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-view {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    height: 60px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-bottom: 0.5px solid #F2F2F2;
  }
  .native-label {
    position: relative;
    color: #1E459D;
    font-weight: bold;
    align-self: center;
    font-size: 20px;
    text-align: center;
    width: 164px;
    height: 60px;
    line-height: 60px;
  }
  .normal-label {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    width: 164px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    &:hover {
      color: #1E459D;
    }
  }
  .nav-flag {
    height: 2px;
    width: 100px;
    background-color: #1E459D;
    position: absolute;
    top: 58px;
    transition: all 0.15s linear 0.01s;
  }
  .input-view-s::-webkit-input-placeholder{
    color:#A6A6A6;
  }
  .input-min {
    height: 36px;
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input-view-s{
    text-align: left;
    width: 100%;
    color: #313131;
    font-size: 14px;
    background-color: transparent;
    border: 0;
    outline: none;
  }
  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
