<template>
  <div>
    <section class="section-copyright">
      <div class="container">
        <div class="container-copyright">Copyright © {{nowYYYY}} by Capvision. All Rights Reserved.</div>
        <div class="container-beian">
          <div>
            <a href="http://www.beian.miit.gov.cn" target="_blank">沪ICP备13007006号</a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602002944">
              <img class="image-beian" src="../assets/img/beian_gov_ghs.png"/>
              <span class="text-beian">沪公网安备 31010602002944 号</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'site-footer',
    computed: {
      nowYYYY: () => new Date().getFullYear()
    },
    components: {}
  }
</script>

<style lang="scss" scoped>
  .section-copyright {
    text-align: center;

    .container {
      padding: 20px 14px 14px;
      box-sizing: border-box;

      .container-copyright {
        color: #212121;
        font-family: initial;
      }

      .container-beian {
        margin-top: 8px;
        font-size: 12px;

        a {
          color: #969798;
          text-decoration: none;
          white-space: nowrap;
        }

        a:visited {
          color: #969798;
        }

        .image-beian {
          padding: 0 1em;
          vertical-align: middle;
        }

        .text-beian {
          color: #969798;
        }
      }
    }
  }
</style>
