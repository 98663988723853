<template>
  <div>
    <div v-if="tool.isNullOrEmpty(category.category_name) === false" @click="openMenu" :class="category.open ? 'expert-c-main-s' : 'expert-c-main'">
      <img style="width: 35px;height: 35px;margin-left: 15px;" src="../assets/img/expert_c.svg" />
      <label style="text-align: center;font-weight: 400;margin-left: 10px;font-size: 15px;color: #1D253C;line-height: 16px;">{{ category.category_name + ' - ' + category.count + '位专家' }}</label>
      <img :src="openIcon" style="width: 16px;height: 16px;margin-left: auto;margin-right: 10px;" />
    </div>
    <el-collapse-transition>
      <div v-if="category.open === true || tool.isNullOrEmpty(category.category_name)">
        <div v-for="(item, index) in category.list" :key='index'>
          <expertInfoCell :index="index" @addCar="addEvent()" :expertInfo='item'></expertInfoCell>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
  import expertInfoCell from './expert_info_cell.vue'
  export default {
    components: {
      expertInfoCell
    },
    computed: {
      openIcon: function () {
        if (this.category.open === false) {
          return require('../assets/img/next_down.png')
        }else {
          return require('../assets/img/next.png')
        }
      }
    },
    props: {
      category: {
        type: Object,
        default: ()=>{}
      }
    },
    data() {
      return {
      }
    },
    methods: {
      openMenu () {
        this.$emit('openEvent',this.category)
      },
      addEvent() {
        this.$emit('addCar')
      }
    }
  }
</script>

<style>
  .expert-c-main {
    margin: 0px 15px 10px 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    align-items: center;
    height: 52px;
  }
  .expert-c-main-s {
    margin: 0px 15px 10px 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    background: #D0EDFC;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    align-items: center;
    height: 52px;
  }
</style>
