<template>
  <div @touchmove.prevent class="expert-option-btn">
    <div v-if="num > 0" @click="showCarEvent" class="expert-sel-btn">
      <img style="width: 28px;height: 21px;" src="../assets/img/expert_head.svg" />
      <div style="display: flex;flex-direction: row;margin-left: 4px;align-items: center;">
        <label style="font-size: 14px;color: #FFFFFF;margin-right: 5px;">选中</label>
        <div ref="chmap" style="font-size: 20px;color: #FFFFFF;font-weight: 800;">{{ num }}</div>
        <label style="font-size: 14px;color: #FFFFFF;margin-left: 5px;">位专家</label>
      </div>
    </div>
    <div v-if="num > 0" @click="sendEvent" class="expert-ap-btn">
      <label style="font-size: 14;color: #FFFFFF;">请安排访谈</label>
    </div>
    <div v-if="num === 0" class="expert-num-zero-btn">
      <label style="font-size: 14;color: #FFFFFF;margin-right: 2px;">点击</label>
      <img style="width: 20px;height: 20px;" src="../assets/img/gou.svg" />
      <label style="font-size: 14;color: #FFFFFF;margin-left: 2px;">选中感兴趣的专家</label>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    props: {
      num: {
        type:Number,
        default:0
      }
    },
    methods: {
      showCarEvent() {
        this.$emit('popCar')
      },
      sendEvent() {
        this.$emit('sendCar')
      },
      animationNum() {
        if (this.num > 0) {
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.2 + ")"; },60)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.3 + ")"; },80)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.4 + ")"; },100)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.5 + ")"; },120)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.6 + ")"; },140)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.7 + ")"; },160)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.8 + ")"; },180)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.9 + ")"; },200)
          setTimeout(()=>{ this.$refs.chmap.style.transform = "scale(" + 1.0 + ")"; },220)
        }
      }
    },
    watch: {
      num() {
        this.animationNum()
      }
    }
}
</script>

<style>
  .expert-option-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 68px rgba(0, 0, 0, 0.13);
  }
  .expert-sel-btn {
    margin-left: 15px;
    height: 45px;
    width: calc(65% - 15px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    background: linear-gradient(48deg, #D59C1D 0%, #F0CB30 100%);
  }
  .expert-ap-btn {
    background: linear-gradient(49deg, #5791FF 0%, #009DFF 100%);
    border-radius: 0px 8px 8px 0px;
    height: 45px;
    width: calc(35% - 15px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .expert-num-zero-btn {
    background: linear-gradient(49deg, #5791FF 0%, #009DFF 100%);
    border-radius: 8px;
    height: 45px;
    margin-left: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
</style>
