<template>
  <div id="main-sss" class="fixedwraper">

<!--      <div id="card-spread-ad" @click="showSelectIndustry()" v-if="interestIndustries.length===0 && isLogin && isFixed===false">
        <p class="text-center">【选择您感兴趣的行业】</p>
      </div>
      <div id="card-industry-wrap" v-else-if="isLogin && isFixed===false">
        <div class="text_bar_container">
          <div style="width: 100%; height: 26px; display: flex; display: -webkit-flex; margin-bottom: 6px;border-radius: 4px;">
            <div style="background-color: #4880FF; width: 6px; height: 18px; margin-top: 16px;"></div>
            <div style="color:black; width: 100%; text-align: left; font-size: 17px; margin-left: 15px; margin-top: 13px; font-weight: bold;">我感兴趣的行业</div>
            <img class="industry-icon_select_image" src="../assets/img/select_industry.png" @click="showSelectIndustry()"/>
          </div>
        </div>
        <div class="industry_tags_container">
          <button class="industry-tag" v-for="item in interestIndustries" :data="item" :key="item.industry_id">{{item.industry_name}}</button>
        </div>
      </div> -->
      <el-collapse-transition>
        <div v-if="tagsList.length > 0" v-show="show && tagsList.length > 0" class="search_tag_view">
          <div class="section_view">
            <div class="section_flag"></div>
            <p class="section_title"><span style="color: #FFA000;">{{ tagName }}</span>&nbsp;共{{ count }}条相关</p>
          </div>
          <div @click="gotoDetail(conferenceItem)" class="tag_cell_view" v-for="(conferenceItem,conferenceIndex) in tagsList" :key="conferenceIndex">
            <div style="margin-left: 8px;font-size: 14px;color: #0745D1;text-align: left;width: calc(100% - 8px);">
              <img style="height: 12px;width: 12px;margin-right: 5px;" src="../assets/img/huayu.png"/>{{ conferenceItem.title }}
              <span style="color: #000000;font-size: 12px;">{{ ' ' + tool.getDateFormat('y-m-d', conferenceItem.start_time)  }}</span>
            </div>
          </div>
          <div style="width: 100%;height: 15px;"></div>
          <el-pagination style="margin-top: 0px;margin-bottom: 10px;" v-if="totalPage > 1"
          small
          background
          layout="pager" :page-size="5" :total="count" :current-page="page" @current-change="currentChange">
          </el-pagination>
        </div>
      </el-collapse-transition>
      <serviceTeam></serviceTeam>

      <el-dialog
        :visible.sync="centerDialogVisible"
        :modal-append-to-body='false'
        width="60%"
        center>
        <div slot="title" class="header-title">
           <span>选择您感兴趣的行业</span>
        </div>
       <div class="dialog_tags_container">
         <button :class="industry.isSelect?'right-industry-item-selected':'right-industry-item-unselect'" v-for="(industry,index) in industrys" :data="industry" :key="index" @click="selectRightIndustry(index)">{{ industry.name }}</button>
       </div>
       <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="commitIndustries" class="dialog_btn_corner_orange">选好了</el-button>
       </span>
      </el-dialog>
  </div>
</template>

<script>
import serviceTeam from './service_team.vue'
import rightBarApi from "@/api/right_bar"
import store from "@/store"
import elementResizeDetectorMaker from 'element-resize-detector'
import { mapState, mapGetters } from 'vuex'
import conference from '../api/conference.js'
export default {
  computed: {
    isLogin: function(){
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return false
      }else{
        return true
      }
    },
    mainLeftH: function(){
      return this.$store.getters.leftmainh
    },
    ...mapState({
      user: state => state.user.sid
    }),
    ...mapGetters('user', {
      sid: 'sid'
    }),
    ...mapGetters('view', {
      searchTag: 'searchTag'
    })
  },
  watch: {
    sid() {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === false) {
        this.createRequest()
      }
    },
    searchTag: {
      handler: function() {
        if (this.tool.isNullOrEmpty(this.searchTag) == false) {
          this.tagName = this.searchTag
          this.show = false
          this.requsetSearchTag()
          this.page = 1
          this.$store.dispatch('view/changeSearchTag', null)
        }
      },
      deep: true
    }
  },
  components: {
    serviceTeam
  },
  mounted() {
    this.createRequest()
  },
  data () {
    return {
      industrys: [],//全部行业
      interestIndustries: [],//感兴趣的行业
      centerDialogVisible: false,
      maxHeight: 0,
      isFixed: false,
      tagsList: [],
      page: 1,
      totalPage: 0,
      count: 0,
      tagName: '',
      show: false
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    const erd = elementResizeDetectorMaker()
    var id = this.interestIndustries.length>0?"card-industry-wrap":"card-spread-ad"
    erd.removeListener(id)
  },
  methods: {
    currentChange(e) {
      this.page = e
      this.requsetSearchTag()
      console.log(e)
    },
    gotoDetail (val) {
      if (val.resource_type == 'CONFERENCE') {
        let routeData = this.$router.resolve({
            path: "/conference/detail",
            query: {
              id: val.id,
              isMom: false,
            }
        })
        window.open(routeData.href, '_blank')
      }else if(val.resource_type == 'RESEARCH') {
        let routeData = this.$router.resolve({
          path: '/researchDetail',
          query: {id: val.id}
        })
        window.open(routeData.href, '_blank')
      }else if(val.resource_type == 'TOPIC') {
        let routeData = this.$router.resolve({
          path: '/topicDetail',
          query: {id: val.id}
        })
        window.open(routeData.href, '_blank')
      }
    },
    requsetSearchTag() {
      var data = {}
      data.keyword = this.tagName
      data.page = this.page
      data.size = 5
      conference.searchTags(data).then( res=> {
        if(res.code == '0000') {
          this.show = true
          this.tagsList = res.data.list
          this.count = res.data.count
          if(res.data.count > 0) {
            this.totalPage = Math.ceil(res.data.count / 5)
          }
        }
      })
    },
    createRequest () {
      // this.loadAllIndustries()
      // this.loadInterestIndustries(false)
      // if (this.tool.isNullOrEmpty(this.$store.getters.sid) === false) {
      //   const _this = this
      //   const erd = elementResizeDetectorMaker()
      //   var id = this.interestIndustries.length>0?"card-industry-wrap":"card-spread-ad"
      //   erd.listenTo(document.getElementById(id),(element)=>{
      //     _this.$nextTick(()=>{
      //       _this.maxHeight = element.offsetHeight
      //     })
      //   })
      // }
      window.addEventListener('scroll',this.handleScroll)
    },
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var mainH = document.getElementById('main-sss').offsetHeight
      if (this.mainLeftH < mainH) {
        return
      }
      if (scrollTop>this.maxHeight+15) {
        this.isFixed = true
      }else{
        this.isFixed = false
      }
    },
    showSelectIndustry () {
      this.centerDialogVisible = true
      this.loadInterestIndustries(true);
    },
    selectRightIndustry (index) {
      this.industrys[index].isSelect = !this.industrys[index].isSelect
    },
    loadAllIndustries () {
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      rightBarApi.loadIndustryList().then(res => {
        this.industrys = res.data;
      })
    },
    loadInterestIndustries(isClick) {//每次点击请求，首次也需请求，加参数区分
      if (this.tool.isNullOrEmpty(this.$store.getters.sid) === true) {
        return
      }
      let params = {
        client_contact_id: 0
      }
      params.client_contact_id = store.getters.uid
      rightBarApi.loadIndustryInterest(params).then(res => {
        if(res.code === '0000') {
          this.interestIndustries = res.data
          if(isClick){
            this.industrys.forEach(res1 => {
              this.$set(res1,'isSelect',false)
              this.interestIndustries.forEach(res2 =>{
                if(res1.id === res2.industry_id){
                  res1.isSelect = true
                }
              })
            })
          }
        }
      })

    },
    commitIndustries() {
      let params = {
        client_contact_id: store.getters.uid,
        industry_ids: this.getTargetIndustries()
      }
      rightBarApi.commitIndustries(params).then(res => {
        if (res.code === '0000'){
          this.centerDialogVisible = false
          this.loadInterestIndustries(false);
        }
      })
    },
    getTargetIndustries() {
      let industryTarget = ''
      this.industrys.forEach(function (industry) {
        if (industry.isSelect) {
          industryTarget = industryTarget.concat(industry.id + ',')
        }
      })
      industryTarget = industryTarget.substr(0, industryTarget.length - 1)
      return industryTarget
    }
  }
}
</script>

<style lang="scss">
  .wraper{
    position: absolute;
    top: 89px;
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: calc(100% - 320px);
  }
  .fixedwraper{
    position: fixed;
    top: 74px;
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: 960px;
  }
  #card-spread-ad{
    width: 100%;
    height: 72px;
    background-image: linear-gradient(to right,#315FD6,#388CD3,#44C7F2,#43E7ED);
    border-radius: 4px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  #card-industry-wrap{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    border-radius: 4px;
  }
  .text_bar_container{
    width: 100%;
    height: auto;
    display: flex;
    display: -webkit-flex;
    align-items: baseline;
    background: white;
    right: 30px;
  }
  .text_bar_container:last-child>div:last-child{
    margin-left: auto;
  }
  .industry_tags_container{
    width: 100%;
    height: auto;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .industry-tag{
    border: none;
    margin: 0 0 10px 8px;
    background-color: #F4F5F9;
    text-align: center;
    color: #545E80;
    font-size: 12px;
    height: 34px;
    width: 90px;
    line-height: 34px;
    outline: none;
    white-space: nowrap;
  }
  .industry-icon_select_image{
    margin-top: 16px;
    width: 16px;
    height: 16px;
    margin-right: 15px;
    cursor: pointer;
  }
  .text-center{
    color: white;
    text-align: center;
    margin: auto;
    font-size: 16px;
    font-weight: 500;
  }
  .dialog_tags_container{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 4px 14px 14px;
  }
  .right-industry-item-unselect{
    border: none;
    border-radius: 4px;
    margin: 0 0 10px 10px;
    background-color: #F6FAFB;
    text-align: center;
    color: #121212;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 28px;
    outline: none;
    cursor: pointer;
  }
  .right-industry-item-selected{
    border: none;
    border-radius: 4px;
    margin: 0 0 10px 10px;
    background-color: #4880FF;
    text-align: center;
    color: white;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 28px;
    outline: none;
    cursor: pointer;
  }
  .dialog_btn_corner_orange{
    background-color: #FFA000;
    color: white;
    font-size: 16px;
    border: 0px;
    border-radius: 8px;
    margin-top: 10px;
    text-align: center;
    width: 190px;
    height: 52px;
    align-items: center;
    cursor: pointer;
  }
  .header-title{
    font-size: 26px;
    font-weight: 500;
    margin-top: 36px;
  }
  .search_tag_view {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .tag_cell_view {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100% - 30px);
    border-bottom: 0.5px solid #eaeaea;
    padding: 14px 2px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .section_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
  .section_flag {
    height: 18px;
    width: 6px;
    background-color: #4880FF;
  }
  .section_title {
    color: #000000;
    font-size: 17px;
    font-weight: bold;
    margin-left: 14px;
  }
</style>
