<template>
  <div class="detail-topic-cell">
    <div class="normal-cell" @click="openEvent" v-show="mainModel.isOpen === false">
      <label class="cell-title">{{ titleExtra + mainModel.title}}</label>
      <div class="cell-right-option">
<!--        <img style="width: 15px;height: 14px;margin-right: 15px;" v-if="mainModel.like_status === 'YES'" src="../assets/img/favsel.png" />
        <img style="width: 15px;height: 14px;margin-right: 15px;" v-else src="../assets/img/favunsel.png" /> -->
        <label class="time-label-ss">{{ tool.getDateFormat('y-m-d', mainModel.create_time) }}</label>
        <img v-if="tool.isNullOrEmpty(statusString) === false" :src="statusString" style="height: 1.5rem;margin-right: 12px;" />
      </div>
    </div>
    <el-collapse-transition>
      <div id="detail-cell-ss" v-show="mainModel.isOpen === true">
        <label @click="closeEvent" style="cursor: pointer;font-size: 18px;font-weight: 500;margin-left: 20px;margin-right: 20px;line-height: 20px;color: #272727;margin-top: 24px;">{{ mainModel.title }}</label>
        <div class="middle-view">
          <div style="margin-top: 10px;margin-bottom: 10px;width: 0.5px;background-color: #F5F5F5;"></div>
          <div class="left-view">
            <label @click="gotoDetail" style="cursor: pointer;margin-left: 20px;margin-right: 20px;height: 14px;font-size: 14px;color: #272727;font-weight: 500;text-align: left;line-height: 14px;">【专家介绍】</label>
            <label @click="gotoDetail" style="cursor: pointer;line-height: 14px;font-size: 12px;margin-left: 20px;margin-top: 10px;margin-right: 20px;text-align: left;color: #4B4B4B;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;">{{ mainModel.author_desc }}</label>
<!--            <label @click="gotoDetail" style="cursor: pointer;margin-left: 20px;margin-right: 20px;height: 14px;font-size: 14px;color: #272727;font-weight: 500;text-align: left;line-height: 14px;margin-top: 10px;">【熟悉话题】</label>
            <div v-if="mainModel.tags.length > 0" style="overflow: hidden;display: flex;display: -webkit-flex;flex-direction: row;flex-wrap: wrap;margin-left: 14px;margin-right: 20px;margin-top: 4px;">
            <label v-for="(item, index) in mainModel.tags.split('/')" :key="index" style="margin-left: 6px;margin-top: 6px;height: 22px;font-size: 12px;color: #7E88A7;text-align: center;padding: 0 12px;background-color: #F4F5F9;border-radius: 2px;line-height: 22px;">{{ item }}</label>
            </div> -->
          </div>
          <div style="margin-top: 10px;margin-bottom: 0px;width: 0.5px;background-color: #F5F5F5;"></div>
          <div class="right-view">
            <label @click="gotoDetail" style="cursor: pointer;margin-left: 20px;margin-right: 20px;height: 14px;font-size: 14px;color: #272727;font-weight: 500;text-align: left;line-height: 14px;">【话题说明】</label>
            <label @click="gotoDetail" style="cursor: pointer;margin-top: 10px;margin-left: 20px;margin-right: 20px;color: #4B4B4B;text-align: left;font-size: 12px;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;white-space: pre-line;">{{ mainModel.topic_info }}</label>
          </div>
        </div>
        <div class="footer-option-view">
          <label style="color: #FFA000;font-size: 12px;margin-left: 20px;text-align: left;line-height: 15px;margin-right: 20px;">{{ listChargeInfo }}</label>
          <div style="display: flex;flex-direction: row-reverse;align-items: center;margin-right: 0px;margin-left: auto;">
            <button @click="gotoDetail()" style="cursor: pointer;margin-right: 20px;font-size: 12px;margin-left: auto;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">话题详情</button>
            <div v-if="isApplied" style="margin-right: 15px;font-size: 14px;color: #FFA000;margin-left: auto;">纪要获取中</div>
            <div v-else-if="isFailed" style="margin-right: 15px;font-size: 14px;color: #666666;margin-left: auto;">获取纪要失败，联系我们：<span @click="popFeedback" style="color: #3B7CFF;font-size: 14px;">这里</span></div>
            <button v-else-if="isSucceed" @click="goToSummary()" style="margin-left: auto;cursor: pointer;margin-right: 15px;font-size: 12px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">查看纪要全文</button>
            <button v-else @click="buyTopic()" style="margin-left: auto;cursor: pointer;margin-right: 15px;font-size: 12px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;">获取纪要全文</button>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' :extraMessage="'--来自主题id：' +  mainModel.id"></feedback>
    <alertView :title="alertTitle" :confirmTitle="confirmTitle" :cancelTitle="cancelTitle" :tipsArray="tipsArray" :isShow="showBuyMom" @cancelEvent="cancelAlert" @confirmEvent="confirmEvent"></alertView>
  </div>
</template>

<script>
import topic from '../api/topic.js'
import feedback from './feedback.vue'
import alertView from './alert_menu_view.vue'
  export default {
    components: {
      feedback,
      alertView
    },
    data () {
      return {
        showFeedback:false,
        showBuyMom: false,
        tipsArray: [],
        cancelTitle: "取消",
        confirmTitle: "确认",
        alertTitle: "提示"
      }
    },
    props: {
      mainModel: {},
      titleExtra: String,
      isSearch: Boolean
    },
    computed: {
      isApplied: function () {
        if (this.mainModel.topic_hot_contact) {
          if (this.mainModel.topic_hot_contact.status === 'APPLIED') {
            return true
          }
        }
        return false
      },
      isSucceed: function () {
        if (this.mainModel.topic_hot_contact) {
          if (this.mainModel.topic_hot_contact.status === 'SUCCEED') {
            return true
          }
        }
        return false
      },
      isFailed: function () {
        if (this.mainModel.topic_hot_contact) {
          if (this.mainModel.topic_hot_contact.status === 'FAILED' || this.mainModel.topic_hot_contact.status === 'CANCELED') {
            return true
          }
        }
        return false
      },
      statusString: function () {
        if (this.mainModel.topic_hot_contact) {
          if (this.mainModel.topic_hot_contact.status === 'APPLIED') {
            return require('../assets/img/yitijiao.svg')
          } else if (this.mainModel.topic_hot_contact.status === 'SUCCEED') {
            return require('../assets/img/yiwancheng.svg')
          } else if (this.mainModel.topic_hot_contact.status === 'FAILED' || this.mainModel.topic_hot_contact.status === 'CANCELED') {
            return require('../assets/img/huoqushibai.svg')
          }
        }
        return ''
      },
      listChargeInfo: function () {
        return `获取纪要全文，按${this.mainModel.charge_amount}小时计费`
      },
      isVip: function () {
        if (this.$store.getters.contractType === 'GRADE_PAY' ||
        this.$store.getters.contractType === 'MONTHLY' ||
        this.$store.getters.contractType === 'MONTHLY_TRAIL' ||
        this.$store.getters.contractType === 'FREE'
        ) {
          return true
        }
        return false
      }
    },
    methods: {
      gotoDetail () {
        let routeData = this.$router.resolve({
          path: '/topicDetail',
          query: {id: this.mainModel.id}
        })
        window.open(routeData.href, '_blank')
      },
      goToSummary () {
        let routeData = this.$router.resolve({
          path: '/topicDetail',
          query: {
            id: this.mainModel.id,
            isMom: true
          }
        })
        window.open(routeData.href, '_blank')
      },
      openEvent () {
        this.$emit('openEvent')
      },
      closeEvent () {
        this.$emit('closeEvent')
      },
      buyTopic () {
        this.alertTitle = '获取纪要全文'
        if (this.isVip) {
          this.tipsArray = [`话题名称：《${this.mainModel.title}》`]
        }else {
          this.tipsArray = [`话题名称：《${this.mainModel.title}》`,`费用：按${this.mainModel.charge_amount}小时计费`]
        }
        this.cancelTitle = '取消'
        this.confirmTitle = '确认'
        this.showBuyMom = true
      },
      cancelAlert () {
        this.showBuyMom = false
      },
      confirmEvent () {
        this.showBuyMom = false
        this.applyRequest()
      },
      applyRequest () {
        var data = {}
        if (this.$store.getters.uid) {
          data.contact_id = this.$store.getters.uid
        }
        data.topic_id = this.mainModel.id
        data.status = 'APPLIED'
        topic.creatTopicStatus(data).then( res=> {
          if (res.code === '0000') {
            this.mainModel.topic_hot_contact = res.data
            if (res.data.status === 'SUCCEED') {
              this.$message('获取纪要成功。')
            }else if(res.data.status === 'APPLIED') {
              this.$message('获取请求已收到，我们会尽快处理。')
            }else if(res.data.status === 'FAILED') {
              this.$message('获取纪要失败，请与您的客户经理联系。')
            }
          }
        }).catch( e=> e)
      },
      popFeedback () {
        this.showFeedback = true
      }
    }
  }
</script>

<style lang="scss">
  .detail-topic-cell {
    width: 100%;
    margin-top: 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .normal-cell{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    height: 60px;
    background-color: white;
    box-shadow:0px 0px 50px rgba(164,181,201,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
      box-shadow: 0px 0px 10px rgba(39, 138, 255, 0.26);
    }
  }
  #detail-cell-ss {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .cell-title{
    color: #393939;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 24px;
    text-align: left;
  }
  .cell-right-option{
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .time-label-ss{
    margin-right: 24px;
    font-size: 12px;
    color: #909090;
    width: 70px;
  }
  .status-label{
    background-color: rgba(72, 128, 255, 0.09);
    color: #4880FF;
    padding: 0 22px;
    height: 28px;
    font-size: 12px;
    margin-right: 20px;
    line-height: 28px;
    text-align: center;
    border-radius:4px;
  }
  .middle-view {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    margin-bottom: auto;
  }
  .right-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 69%;
    cursor: pointer;
  }
  .left-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 31%;
  }
  .footer-option-view {
    display: flex;
    flex-direction: row;
    height: 56px;
    margin-top: auto;
    margin-bottom: 0px;
    border-top: 0.5px solid #F5F5F5;
    align-items: center;
    cursor: pointer;
  }
</style>
