import JSEncrypt from 'jsencrypt'
const tool = {
  isNullOrEmpty: function (value) {
    if (value === '') return true
    if (value === 'null') return true
    if (value === undefined) return true
    if (value === null) return true
    return false
  },
  isMobile: function (value) {
    return /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/.test(value)
  },
  isEMail: function (value) {
    console.log(value)
    return true
    //return /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)
  },
  getRsaCode: function (value) {
    let pubKey = `-----BEGIN PUBLIC KEY-----MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIeed8l48OhLcg3FiiMgtaU53hr2FT1324Bzq4OC2qHjlmQcQBmqu5D1Jl+jpx0xkDpC/dvfq2RedNCijGeAa0kCAwEAAQ==-----END PUBLIC KEY-----`
    let encryptStr = new JSEncrypt()
    encryptStr.setPublicKey(pubKey)
    let data = encryptStr.encrypt(value.toString())
    return data
  },
  isShowConferenceTime (startTime) {
    if (startTime) {
      startTime = startTime.toString().replace('T',' ').replace(/-/g, '/').replace('Z','')
      var nowTime = new Date()
      var msecond = new Date(startTime).getTime() + 8*60*60*1000 - nowTime.getTime()
      if (msecond > 0) {
        return true
      }else {
        return false
      }
    }
    return false
  },
  isSignUpStop: function (startTime) {
    if (startTime) {
      // startTime = startTime.toString().replace('T',' ').replace(/-/g, '/').replace('Z','')
      var nowTime = new Date()
      var msecond = startTime*1000 - nowTime.getTime()
      if (msecond < 0) {
        return true
      }
      var minutes = Math.floor(msecond/(1000*60))
      if (minutes <= 15) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  isEnd: function (startTime) {
    if (startTime) {
      var nowTime = new Date()
      var msecond = startTime*1000 - nowTime.getTime()
      var minutes = Math.floor(Math.abs(msecond)/(1000*60))
      if (minutes > 15 && msecond < 0) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  },
  getTimeYMD: function (timestamp) {
      var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear(),
          M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
          D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      return Y + '-' + M + '-' + D
  },
  getTimeYMDHMS: function (timestamp) {
      var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear(),
          M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
          D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
          h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
          m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()),
          s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
  },
  getTimeYMDHM: function (timestamp) {
      var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear(),
          M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
          D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
          h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
          m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m
  },
  getTimeHM: function (timestamp) {
      var date = new Date(timestamp*1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
          m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      return h + ':' + m
  },
  isFree: function (startTime) {
    if (startTime) {
      startTime = startTime.toString().replace('T',' ').replace(/-/g, '/').replace('Z','')
      var nowTime = new Date()
      var msecond = nowTime.getTime() - new Date(startTime).getTime() - 8*60*60*1000
      if (msecond < 0) {
        return false
      }
      var second = Math.floor(msecond/1000)
      if (second >= 365*24*3600) {
        return true
      }
    } else {
      return false
    }
  },
  isNewFree: function (startTime) {
    if (startTime) {
      var nowTime = new Date()
      var msecond = nowTime.getTime() - startTime*1000
      if (msecond < 0) {
        return false
      }
      var second = Math.floor(msecond/1000)
      if (second >= 180*24*3600) {
        return true
      }
    }
  },
  getDateFormat: function (formatStr, fdate) {
    if (fdate) {
      fdate = fdate.toString().replace('T',' ').replace(/-/g, '/').replace('Z','')/* 解决safari时间问题 */
      var fTime = new Date(fdate)
      let fStr = 'ymdhis'
      var month = fTime.getMonth() + 1
      var day = fTime.getDate()
      var hours = fTime.getHours() + 8
      var minu = fTime.getMinutes()
      var second = fTime.getSeconds()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hours = hours < 10 ? ('0' + hours) : hours
      minu = minu < 10 ? '0' + minu : minu
      second = second < 10 ? '0' + second : second
      var formatArr = [
        fTime.getFullYear().toString(),
        month.toString(),
        day.toString(),
        hours.toString(),
        minu.toString(),
        second.toString()
      ]
      for (var i = 0; i < formatArr.length; i++) {
        formatStr = formatStr.replace(fStr.charAt(i), formatArr[i])
      }
      return formatStr
    } else {
      return ''
    }
  }
}

export {
  tool
}
