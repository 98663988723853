<template>
  <div id="search">
    <div id="main_search">
      <listTopMenu :allOpen="allOpen" :num="count" @chargeType='screenChargeType' @changeEvent='changeAllOpen'></listTopMenu>
      <div class="item-cell" v-for="(item,index) in list" :key="index">
        <ConferenceCard :mainModel="item" :isSearch='true' @openEvent='openEvent(index)' @closeEvent='closeEvent()'></ConferenceCard>
<!--        <ConferenceCard v-if="item.resource_type === 'CONFERENCE'" :mainModel="item" :isSearch='true' @openEvent='openEvent(index)' @closeEvent='closeEvent()'></ConferenceCard>
        <ResearchCard v-else-if="item.resource_type === 'RESEARCH'" titleExtra='【凯盛研究】' :isSearch='true' :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent()'></ResearchCard>
        <TopicCard v-else-if="item.resource_type === 'TOPIC'" titleExtra='' :mainModel="item" @openEvent='openEvent(index)' @closeEvent='closeEvent()'></TopicCard> -->
      </div>
      <div class="loading-text" v-show="noMore == false">
         {{loadingType === 0 ? contentText.contentdown : (loadingType === 1 ? contentText.contentrefresh : contentText.contentnomore)}}
      </div>
    </div>
  </div>
</template>

<script>
import listTopMenu from '../components/list_top_menu_search.vue'
import ConferenceCard from '../components/list_conference_cell.vue'
// import ResearchCard from '../components/list_research_cell.vue'
// import TopicCard from '../components/list_topic_cell.vue'
import conference from '../api/conference.js'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  components: {
    listTopMenu,
    ConferenceCard,
    // ResearchCard,
    // TopicCard
  },
  data () {
    return {
      list: [],
      resource_types:"CONFERENCE,RESEARCH,TOPIC",
      extra: 'contact_task_for_contact,consultants,materials,conference_summary',
      count: 0,
      allOpen: false,
      page: 1,
      size: 50,
      loading: false,
      noMore: true,
      keyword: '',
      contentText: {
          contentdown: "上拉显示更多",
          contentrefresh: "正在加载...",
          contentnomore: "没有更多了"
      },
      loadingType: 0
    }
  },
  created () {
    window.addEventListener('scroll',this.scroll,true)
  },
  mounted() {
    this.$bus.$on('searchEvent', this.searchEvent)
    this.keyword = this.$route.query.keyword
    this.noMore = true
    this.getSearchList(true)
    this.getMainH()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll,true)
  },
  methods: {
    searchEvent (val) {
      this.keyword = val
      this.getSearchList(true)
    },
    scroll () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.body.scrollHeight
      if(scrollTop+windowHeight>=scrollHeight){
        if (this.loadingType != 0){
          return false;
        }
        this.loadingType = 1;
        this.loadMore()
      }
    },
    loadMore () {
      if (this.noMore === false && this.loading === false) {
        this.loading = true
        this.getSearchList(false)
      }
    },
    getMainH () {
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById("main_search"),(element)=>{
        this.$nextTick(()=>{
          this.$store.dispatch('view/changeLeftMainH', element.offsetHeight)
        })
      })
    },
    changeRightHost (val) {
      var hostArray = []
      if (val !== 0) {
        if (val.servant !== null) {
          if (val.resource_type === 'CONFERENCE') {
            val.servant.employee.position = '主持人'
          } else if (val.resource_type === 'RESEARCH') {
            val.servant.employee.position = '研究员'
          } else if (val.resource_type === 'TOPIC') {
            val.servant.employee.position = '联系人'
          }
          hostArray.push(val.servant)
        }
      }
      this.$store.dispatch('view/changeHostArray', hostArray)
    },
    changeAllOpen () {
      this.allOpen = !this.allOpen
      this.list.forEach( (res) => {
          if (this.allOpen === true) {
            res.isOpen = true
            // this.changeRightHost(0)
          } else {
            res.isOpen = false
          }
      })
    },
    screenChargeType(val) {
      this.resource_types = val
      this.getSearchList(true)
    },
    openEvent (val) {
      this.list.forEach( (res, index) => {
          if (index === val) {
            res.isOpen = true
            this.changeRightHost(res)
          } else {
            res.isOpen = false
          }
      })
    },
    closeEvent () {
      this.list.forEach( (res) => {
        res.isOpen = false
      })
      this.changeRightHost(0)
    },
    getSearchList (isFresh) {
      var data = {}
      // data.resource_types = this.resource_types
      data.keyword = this.keyword
      // data.extra = this.extra
      if (isFresh === true) {
        this.allOpen = false
        this.page = 1
        this.list = []
      }
      data.size = this.size
      data.page = this.page
      data.extra = 'contact_task_for_contact,materials,consultants,conference_summary'
      data.sort = 'project.starttime desc,project.endtime desc'
      conference.getConferenceList(data).then( res=> {
        this.loading = false
        if (res.code === '0000') {
          if (res.data) {
            this.count = res.data.count
            if (res.data.list.length < 50) {
              this.noMore = true
              this.loadingType = 2
            }else {
              this.page++
              this.noMore = false
              this.loadingType = 0
            }
            if (isFresh == true) {
              this.list = []
            }
            res.data.list.forEach( (item) => {
              item.isOpen = this.allOpen
              if (item.conference_label) {
                item.all_tags = item.conference_label.split(',')
              }
              if (item.contact_task_for_contact) {
                item.my_task = {
                  ...item.contact_task_for_contact
                }
              }else {
                item.my_task = {}
              }
              this.list.push(item)
            })
          }
        }
      })
    }
  }
}
</script>
<style>
  #search{
    padding-top: 0px;
  }
  #main_search{
    width: calc(100% - 320px);
  }
  .item-cell{
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
  }
  .loading-text {
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    text-align: center;
  }
</style>
