import { fullgoalHttpService, fullgoalHttpNoErrorService } from '../utils/request.js'

export default {
  fullgoalConferenceOne(data) {
    return fullgoalHttpNoErrorService({
      url: '/web/conference/fullgoal/one',
      method: 'post',
      data
    })
  },
  fullgoalConferenceOneMom(data) {
    return fullgoalHttpNoErrorService({
      url: '/web/conference/fullgoal/one_mom',
      method: 'post',
      data
    })
  },
  fullgoalConferenceSendMomMail(data) {
    return fullgoalHttpService({
      url: '/web/conference/fullgoal/send_mom_mail',
      method: 'post',
      data
    })
  },
  fullgoalConferenceContactApply(data) {
    return fullgoalHttpService({
      url: '/web/conference/fullgoal/contact/apply',
      method: 'post',
      data
    })
  }
}
