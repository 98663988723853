<template>
  <div id="list-top-menu-search">
    <div class="left-option-menu">
<!--      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 0 15px;"></div> -->
<!--      <el-checkbox-group v-model="checkedMenus" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in menus" :label="item" :key="item">{{item}}</el-checkbox>
      </el-checkbox-group> -->
    </div>
    <div class="right-option-menu">
      <el-tooltip class="item" effect="dark" :content="allOpen ? '收起所有卡片' : '展开所有卡片'" placement="top-start">
        <div class="btn-view" @click="changeMenu">
          <div class="line"></div>
          <img class="btn-icon" src="../assets/img/change_icon.png" />
        </div>
      </el-tooltip>
      <div class="num-view">
        <label class="num-tip1">共</label>
        <label class="num-text">{{num}}</label>
        <label class="num-tip2">条<span class="responsive-ipad-show">内容</span></label>
      </div>
    </div>
  </div>
</template>

<script>
const menuOptions = ['会议', '研报']
export default {
  props: {
    num: Number,
    allOpen: Boolean
  },
  data () {
    return {
      checkAll: true,
      checkedMenus: ['会议', '研报'],
      menus: menuOptions,
      isIndeterminate: false
    }
  },
  methods: {
    changeMenu () {
      this.$emit('changeEvent')
    },
    handleCheckAllChange(val) {
      this.checkedMenus = val ? menuOptions : [],
      this.isIndeterminate = false
      var type = 'CONFERENCE,RESEARCH'
      if (val === false) {
        type = ''
      }
      this.$emit('chargeType',type)
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.menus.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.menus.length
      var type = []
      if (this.checkedMenus.indexOf('会议') !== -1) {
        type.push('CONFERENCE')
      }
      if (this.checkedMenus.indexOf('研报') !== -1) {
        type.push('RESEARCH')
      }
      this.$emit('chargeType',type.join(','))
    }
  }
}
</script>

<style>
  #list-top-menu-search {
    margin-top: 0px;
    margin-left: 0px;
    width: calc(100% - 15px);
    display: flex;
    flex-direction: row;
    align-items: center;
    /*height: 50px;*/
    height: 50px;
  }

  .el-checkbox {
    font-size: 12px;
    color: #363636;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4880FF;
    border-color: #4880FF;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4880FF;
  }

  .el-checkbox__inner:hover {
    border-color: #4880FF;
  }

  .left-option-menu {
    display: flex;
    flex-wrap: wrap;
    z-index: 0;
  }

  .right-option-menu {
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    margin-left: auto;
    align-items: center;
  }

  .btn-view {
    margin-right: 0px;
    width: 50px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .btn-icon {
    width: 20px;
    height: 16px;
  }

  .line {
    height: 16px;
    width: 1px;
    background-color: #E0E0E0;
    margin-left: 0px;
    margin-right: 15px;
  }

  .num-view {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }

  .num-tip1 {
    font-size: 12px;
    color: #272727;
    margin-right: 8px;
  }

  .num-tip2 {
    font-size: 12px;
    color: #272727;
    margin-left: 8px;
  }

  .num-text {
    font-size: 18px;
    color: #FFA000;
  }

  @media only screen and (max-width: 768px) {
    .responsive-ipad-show {
      display: none;
    }
  }

</style>
