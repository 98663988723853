import { HttpNoErrorService, HttpService } from '../utils/request.js'

export default {
  async getResearchesList (data) {
    return HttpNoErrorService({
      url: '/researches/page',
      method: 'post',
      data
    })
  },
  async getResearches (data) {
    return HttpService({
      url: '/researches/detail',
      method: 'post',
      data
    })
  },
  async updateResearches (data) {
    return HttpService({
      url: '/researches/update',
      method: 'post',
      data
    })
  },
  //提交反馈
  async commitFeedback (data) {
    return HttpService({
      url: '/client_contacts/feedback',
      method: 'post',
      data
    })
  },
  //研报任务查询
  async checkResearchStatus (data) {
    return HttpService({
      url: '/client_contacts/researches/list',
      method: 'post',
      data
    })
  },
  async createResearchStatus (data) {
    return HttpService({
      url: '/client_contacts/researches/create',
      method: 'post',
      data
    })
  },
  async purchaseResearchDetail(data){
    return HttpService({
      url:'/client_contacts/researches/create',
      method:'post',
      data
    })
  },
  //感兴趣
  async commitPraise (data) {
    return HttpService({
      url: '/client_contacts/resources/like',
      method: 'post',
      data
    })
  },
  //取消感兴趣
  async cancelPraise (data) {
    return HttpService({
      url: '/client_contacts/resources/like/cancel',
      method: 'post',
      data
    })
  }
}
