<template>
  <el-dialog @close="cancel()" width="580px" :modal-append-to-body='false' :close-on-click-modal="false" :show-close="false" :title="title" center :visible.sync="isShow">
    <div style="display: flex;flex-direction: column;" v-for="(item,index) in tipsArray" :data="item" :key="index">
      <label style="color: #272727;font-size: 14px;padding-bottom: 15px;text-align: left;padding-left: 30px;padding-right: 30px;">{{ item }}</label>
    </div>
    <div style="flex-direction: row;display: flex;padding-top: 40px;padding-bottom: 10px;justify-content: center;">
      <el-button size="medium" @click="cancel" style="width:150px;margin-right: 20px;">{{ cancelTitle }}</el-button>
      <el-button size="medium" type="primary" @click="confirm" style="width:150px;margin-left: 20px;">{{ confirmTitle }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      isShow: Boolean,
      title: String,
      tipsArray: Array,
      confirmTitle: String,
      cancelTitle: String
    },
    methods: {
      cancel () {
        this.$emit('cancelEvent')
      },
      confirm () {
        this.$emit('confirmEvent')
      }
    }
  }
</script>

<style>
</style>
