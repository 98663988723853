<template>
  <div class="topic_detail_wrapper">
    <div v-show="isMom === false" class="topic_detail_content_container">
      <div class="topic_detail_left">
        <div style="display: flex;flex-direction: row;align-items: center;">
          <p class="topic_title">{{ mainModel.title }}</p>
        </div>
        <div style="display: flex;flex-direction: column;" v-for="(itemModel,indexModel) in mainModel.contentArray" :key="indexModel+1">
          <div v-if="tool.isNullOrEmpty(itemModel.title) === false" class="title-nav-view-ss">
            <div class="flag-line-ss"></div>
            <label class="nav-title">{{ itemModel.title }}</label>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in itemModel.imageTextArray" :key='contentIndex'>
             <label
              v-if="tool.isNullOrEmpty(contentItem.text) === false" style="white-space: pre-wrap;text-align: left;margin-left: 24px;margin-right: 24px;margin-bottom: 20px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}</label>
             <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 48px);margin-left: 24px;margin-bottom: 20px;"  />
           </div>
        </div>
      </div>
      <div v-if="momRightShow" @click="changeType(1)" class="right-option-btn">
        <img style="width: 100%;height: auto;" src="../assets/img/redianjiyao.svg" />
      </div>
      <div v-if="momRightShow && showRightBigMenu" class="right-seg-btn">
         <img style="width: 100%;height: auto;margin-bottom: -10px;" src="../assets/img/redianxiangqingsel.svg" />
         <img @click="changeType(1)" style="width: 100%;height: auto;margin-top: -10px;" src="../assets/img/redianjiyaonor.svg" />
      </div>
    </div>
    <div v-show="isMom" class="topic_detail_content_container">
      <div class="topic_detail_left">
        <div style="display: flex;flex-direction: row;align-items: center;">
          <p class="topic_title">{{title}}</p>
        </div>
        <div style="display: flex;flex-direction: column;" v-for="(momModel,indexMomModel) in summaryArray" :key="indexMomModel+1">
              <div v-if="tool.isNullOrEmpty(momModel.title) === false" class="title-nav-view-ss">
                <div class="flag-line-ss"></div>
                <label class="nav-title">{{ momModel.title }}</label>
              </div>
              <div style="display: flex;flex-direction: column;margin-left: 0rpx;" v-for="(contentItem,contentIndex) in momModel.imageTextArray" :key='contentIndex'>
                <label
                     v-if="tool.isNullOrEmpty(contentItem.text) === false || tool.isNullOrEmpty(contentItem.ext) === false" style="white-space: pre-wrap;text-align: left;margin-left: 24px;margin-right: 24px;margin-bottom: 20px;color: #000000;font-size: 14px;line-height: 22px;">{{ contentItem.text }}<span style="color: #4880FF;">{{ contentItem.ext }}</span></label>
                <img v-if="tool.isNullOrEmpty(contentItem.img) === false" :src="contentItem.img" style="width: calc(100% - 48px);margin-left: 24px;margin-bottom: 20px;"  />
              </div>
        </div>
      </div>
      <div v-if="momRightShow" @click="changeType(0)" class="right-option-btn">
        <img style="width: 100%;height: auto;" src="../assets/img/redianxiangqing.svg" />
      </div>
      <div v-if="momRightShow && showRightBigMenu" class="right-seg-btn">
        <img @click="changeType(0)" style="width: 100%;height: auto;margin-bottom: -10px;" src="../assets/img/redianxiangqingnor.svg" />
        <img style="width: 100%;height: auto;margin-top: -10px;" src="../assets/img/redianjiyaosel.svg" />
      </div>
    </div>
    <!-- 吸底 -->
    <div v-if="isMom === false && isSucceed === false && tool.isNullOrEmpty(mainModel.title) === false" class="topic_detail_footer_container">
      <div class="topic_detail_footer_container_inner">
        <P class="topic-detail-status" v-if="isApplied">纪要获取中</P> <!--审核中 -->
        <P class="topic-detail-status" v-else-if="isFailed" v-html="purchaseFailed"></P> <!--驳回 -->
        <button @click="interviewExpert" class="btn_corner_able" v-else>获取纪要全文</button>
      </div>
    </div>
    <div v-if="isMom === true" class="topic_detail_footer_container">
      <div class="topic_detail_footer_container_inner">
        <button v-if="tool.isNullOrEmpty(btnT) === false" @click="buyEvent" :class="isEable ? 'btn_corner_able' : 'btn_corner_enable'">{{ btnT }}</button>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <span class="topic-text-center" v-html="alertContent"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="purchaseTopic()" >确 定</el-button>
      </span>
    </el-dialog>
    <alertView :title="alertTitle" :confirmTitle="confirmTitle" :cancelTitle="cancelTitle" :tipsArray="tipsArray" :isShow="showBuyMom" @cancelEvent="cancelAlert" @confirmEvent="confirmEvent"></alertView>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' extraMessage='提交反馈'></feedback>
    <unloginOptionView :isTopic="true" :noConference="true" resource_type='TOPIC' :isShow="showLogin" @dissmiss='showLogin = false' @unloginCreateOrder='unloginCreateOrder' @loginSuccessEvent='loginSuccessEvent'></unloginOptionView>
  </div>
</template>

<script>
import topicDetailApi from '../api/topic.js'
import store from '../store/index.js'
import feedback from "@/components/feedback"
import detailApi from '../api/un_login_detail.js'
import unloginOptionView from '../components/unlogin_option_view.vue'
import alertView from '../components/alert_menu_view.vue'
export default{
  created () {
    let _this = this
    window.showTopicFeedbackDialog = _this.showTopicFeedbackDialog
  },
  components: {
    feedback,
    unloginOptionView,
    alertView
  },
  data () {
    return {
      title: '',
      outline: '提纲',
      outlineArray: [],
      content: '关于主题',
      author: '作者',
      centerDialogVisible: false,
      alertContent: '提交访谈意向后，凯盛项目经理会与您取得联系，详细介绍专家和主题的情况。<br><br>如您需要，后续再安排访谈事宜。',
      btn_status: 'INITIAL', // INITIAL 未点击， CHECKING 已提交审核， ONLINE, 有效,  REJECTED,驳回
      purchaseSuccess: '<span>访谈成功，联系我们：<span style="color: deepskyblue" onclick="showTopicFeedbackDialog()">这里</span></span>',
      purchaseFailed:  '<span>获取纪要失败，联系我们：<span style="color: deepskyblue" onclick="showTopicFeedbackDialog()">这里</span></span>',
      client_id: store.getters.uid,
      topic_detail_id: 0,
      showFeedback: false,
      isInteresting: false,
      token:'',
      showLogin:false,
      showRightBigMenu: true,
      mainModel: {},
      isMom: false,
      summaryArray: [],
      summary_notes: '',
      btnT: '',
      summary_ext: '',
      key_idea: '',
      is_free_now: 0,
      topic_hot_contact: {},
      isSendingEmail:false,
      mom_email_address:'',
      showBuyMom: false,
      tipsArray: [],
      cancelTitle: "取消",
      confirmTitle: "确认",
      alertTitle: "提示"
    }
  },
  computed: {
    isApplied: function () {
      if (this.mainModel.topic_hot_contact) {
        if (this.mainModel.topic_hot_contact.status === 'APPLIED') {
          return true
        }
      }
      return false
    },
    isVip: function () {
      if (this.$store.getters.contractType === 'GRADE_PAY' ||
      this.$store.getters.contractType === 'MONTHLY' ||
      this.$store.getters.contractType === 'MONTHLY_TRAIL' ||
      this.$store.getters.contractType === 'FREE') {
        return true
      }
      return false
    },
    isSucceed: function () {
      if (this.mainModel.topic_hot_contact) {
        if (this.mainModel.topic_hot_contact.status === 'SUCCEED') {
          return true
        }
      }
      return false
    },
    isFailed: function () {
      if (this.mainModel.topic_hot_contact) {
        if (this.mainModel.topic_hot_contact.status === 'FAILED' || this.mainModel.topic_hot_contact.status === 'CANCELED') {
          return true
        }
      }
      return false
    },
    momRightShow: function () {
      if (this.topic_hot_contact.status === 'SUCCEED') {
        return true
      }
      return false
    },
    isEable: function () {
      if (this.topic_hot_contact.status === 'SUCCEED' || this.is_free_now === 1) {
        return true
      }else {
        if (this.topic_hot_contact.status === 'APPLIED' || this.topic_hot_contact.status === 'FAILED') {
          return false
        }else {
          return true
        }
      }
    },
    isFirstAndMonthClient: function () {
      if (this.$store.getters.client.has_monthly_conference_plan === true) {
        return true
      }
      return false
    },
    defaultEmail: function () {
       if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
         return this.$store.getters.email
       }else if(this.tool.isNullOrEmpty(this.$store.getters.app_email) === false) {
         return this.$store.getters.app_email
       }else {
         return ''
       }
    },
    isDBEmail: function () {
      if (this.tool.isNullOrEmpty(this.$store.getters.email) === false) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route' () {
      if (this.tool.isNullOrEmpty(this.$route.query.id) === false) {
        this.topic_detail_id = parseInt(this.$route.query.id)
      }
      this.loadTopicDetail()
    }
  },
  mounted () {
    setTimeout( ()=>{
      this.showRightBigMenu = false
    },5000)
    if (this.tool.isNullOrEmpty(this.$route.query.id) === false) {
      this.topic_detail_id = parseInt(this.$route.query.id)
    }
    this.isMom = this.$route.query.isMom === 'true' ? true : false
    this.token = this.$route.query.token
    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (isMobile) {
      this.$router.replace({ path:'/mobileTopicDetail', query:{token:this.token}})
    }
    this.loadTopicDetail()
    if (this.tool.isNullOrEmpty(this.token) === true) {
      this.loadTopicTaskDetail()
    }
  },
  methods: {
    changeType (type) {
      if (type === 0 && this.isMom === true) {
        this.isMom = false
      } else if (type === 1 && this.isMom === false) {
        this.isMom = true
        this.reloadSummaryNotes()
      }
    },
    changeRightHost (val) {
      var hostArray = []
      if (val !== 0) {
        if (val.servant !== null) {
          val.servant.employee.position = '联系人'
          hostArray.push(val.servant)
        }
      }
      this.$store.dispatch('view/changeHostArray', hostArray)
    },
    interviewExpert () {
      if (this.optionUnlogin()) {
        return
      }
      this.alertTitle = '获取纪要全文'
      if (this.isVip) {
        this.tipsArray = [`话题名称：《${this.mainModel.title}》`]
      }else {
        this.tipsArray = [`话题名称：《${this.mainModel.title}》`,`费用：按${this.mainModel.charge_amount}小时计费`]
      }
      this.cancelTitle = '取消'
      this.confirmTitle = '确认'
      this.showBuyMom = true
    },
    cancelAlert () {
      this.showBuyMom = false
    },
    confirmEvent () {
      this.showBuyMom = false
      this.purchaseTopic()
    },
    unloginCreateOrder (val) {
      this.showLogin = false
      var data = {}
      data.resource_id = this.topic_detail_id
      data.resource_type = 'TOPIC'
      data.mobile = val
      detailApi.createOrder(data).then( res=> {
        if (res.code === '0000') {
          if(res.data.status === 'PENDING') {
            this.$message('请求提交成功，我们会尽快与您联系。')
          }
        }
      })
    },
    loginSuccessEvent () {
      this.showLogin = false
      this.loadTopicDetail()
    },
    optionUnlogin () {
      if (this.tool.isNullOrEmpty(this.$store.getters.uid) === true) {
        this.showLogin = true
        return true
      }
      this.showLogin = false
      return false
    },
    loadTopicDetail () {
      let params = {}
      if (this.tool.isNullOrEmpty(this.token) === false  && this.topic_detail_id === 0) {
        params.token = this.token
        detailApi.getDetail(params).then( res=>{
          if (res.code === '0000') {
            this.mainModel = res.data
            if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
              this.loadTopicTaskDetail()
            }
            this.changeRightHost(res.data)
            if (this.tool.isNullOrEmpty(this.$store.getters.uid) === false) {
              this.topic_detail_id = parseInt(this.mainModel.id)
              this.loadTopicDetail()
            }
            this.title = res.data.title
            this.summary_notes = res.data.summary_notes
            this.is_free_now = parseInt(res.data.is_free_now)
            if (this.tool.isNullOrEmpty(res.data.topic_hot_contact) === false) {
              this.topic_hot_contact = res.data.topic_hot_contact
            }
            this.reloadContent(res.data.content)
            this.isInteresting = res.data.like_status === 'YES'
          }
        })
      }else {
        if (this.tool.isNullOrEmpty(this.$store.getters.uid) === true) {
          this.$store.dispatch('user/logout')
          this.$router.replace('/login')
          return
        }
        params.id = this.topic_detail_id
        params.extra = 'consultant,create_employee.user,topic_contacts,my_task,servant.employee.user,topic_consultants,topic_hot_contact'
        topicDetailApi.loadTopicDetail(params).then(res => {
          if (res.code === '0000') {
            this.changeRightHost(res.data)
            this.mainModel = res.data
            this.title = res.data.title
            this.summary_notes = res.data.summary_notes
            this.is_free_now = parseInt(res.data.is_free_now)
            if (this.tool.isNullOrEmpty(res.data.topic_hot_contact) === false) {
              this.topic_hot_contact = res.data.topic_hot_contact
            }
            this.isInteresting = res.data.like_status === 'YES'
            this.reloadContent(res.data.content)
            if (this.isMom === true) {
              this.reloadSummaryNotes()
            }
          }
        })
      }
    },
    reloadContent (val) {
      if (this.tool.isNullOrEmpty(val)) {
        return
      }
      this.mainModel.contentArray = []
      var contents = val.split('@@@')
      contents.forEach( (item) => {
        if(this.tool.isNullOrEmpty(item) === false) {
          var itemModel = {}
          if (item.indexOf('###') !== -1) {
            itemModel.title = item.slice(0,item.indexOf('###'))
            itemModel.content = item.slice(item.indexOf('###')+3,item.length)
            itemModel.imageTextArray = []
          }else {
            itemModel.title = ''
            itemModel.content = item
            itemModel.imageTextArray = []
          }
          var imgTextArray = itemModel.content.split('<img>')
          imgTextArray.forEach( imgText => {
            var imgTextModel = {}
            if (imgText.indexOf('</img>') !== -1) {
              var  subImgTextArray = imgText.split('</img>')
              if (subImgTextArray.length === 2) {
                for (var i = 0;i < subImgTextArray.length;i++) {
                  var imgSubTextModel = {}
                  if (i === 0) {
                    imgSubTextModel.img = subImgTextArray[0]
                    itemModel.imageTextArray.push(imgSubTextModel)
                  }else {
                    imgSubTextModel.text = subImgTextArray[1]
                    itemModel.imageTextArray.push(imgSubTextModel)
                  }
                }
              }else {
                imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                itemModel.imageTextArray.push(imgTextModel)
              }
            }else{
              imgTextModel.text = imgText
              itemModel.imageTextArray.push(imgTextModel)
            }
          })
          this.mainModel.contentArray.push(itemModel)
        }
      })
    },
    reloadSummaryNotes () {
      if (this.is_free_now === 1 || this.topic_hot_contact.status === 'SUCCEED') {
        this.btnT = '发送纪要到邮箱'
        let params = {}
        params.id = this.topic_detail_id
        topicDetailApi.loadMomDetail(params).then(res => {
          if (res.code === '0000') {
            this.summary_ext = ''
            this.summary_notes = res.data.summary_notes
            this.parseSummary()
          }
        })
      }
    },
    parseSummary () {
      this.summaryArray.length = 0
      if (this.tool.isNullOrEmpty(this.summary_notes) === false) {
        var contents = this.summary_notes.split('@@@')
        contents.forEach( (item) => {
          if(this.tool.isNullOrEmpty(item) === false) {
            var itemModel = {}
            if (item.indexOf('###') !== -1) {
              itemModel.title = item.slice(0,item.indexOf('###'))
              itemModel.content = item.slice(item.indexOf('###')+3,item.length)
              itemModel.imageTextArray = []
            }else {
              itemModel.title = ''
              itemModel.content = item
              itemModel.imageTextArray = []
            }
            var imgTextArray = itemModel.content.split('<img>')
            imgTextArray.forEach( (imgText) => {
              var imgTextModel = {}
              if (imgText.indexOf('</img>') !== -1) {
                var  subImgTextArray = imgText.split('</img>')
                if (subImgTextArray.length === 2) {
                  for (var i = 0;i < subImgTextArray.length;i++) {
                    var imgSubTextModel = {}
                    if (i === 0) {
                      imgSubTextModel.img = subImgTextArray[0]
                      itemModel.imageTextArray.push(imgSubTextModel)
                    }else {
                      imgSubTextModel.text = subImgTextArray[1]
                      itemModel.imageTextArray.push(imgSubTextModel)
                    }
                  }
                }else {
                  imgTextModel.img = imgText.slice(0,imgText.indexOf('</img>'))
                  itemModel.imageTextArray.push(imgTextModel)
                }
              }else{
                imgTextModel.text = imgText
                itemModel.imageTextArray.push(imgTextModel)
              }
              this.summaryArray.push(itemModel)
            })
          }
        })
      }
    },
    loadTopicTaskDetail () {
      if (this.tool.isNullOrEmpty(this.$store.getters.uid) === true) {
        this.$store.dispatch('user/logout')
        this.$router.replace('/login')
        return
      }
      let params = {
        contact_id:store.getters.uid,
        topic_id:this.topic_detail_id
      }
      topicDetailApi.queryTopicTaskDetail(params).then(res =>{
        if(res.code === '0000'){
          if(res.data===null || res.data.length === 0){
            this.btn_status = 'INITIAL'
          }else {
            this.btn_status = res.data[0].status
          }
        }
      })
    },
    purchaseTopic () {
      this.centerDialogVisible = false
      let params = {
        contact_id:this.$store.getters.uid,
        topic_id:this.topic_detail_id,
        status:'APPLIED'
      }
      topicDetailApi.creatTopicStatus(params).then(res =>{
        if(res.code === '0000'){
          if (res.data.status === 'SUCCEED') {
            this.$message('获取纪要成功。')
          }else if(res.data.status === 'APPLIED') {
            this.$message('获取请求已收到，我们会尽快处理。')
          }else if(res.data.status === 'FAILED') {
            this.$message('获取纪要失败，请与您的客户经理联系。')
          }
          this.loadTopicDetail()
        }
      })
    },
    showError () {
      this.$message({
        type: 'error',
        message: '请求失败,请稍后再试'
      });
    },
    showTopicFeedbackDialog () {
      this.showFeedback = true
    },
    commitInteresting(){
      let params = {
        client_contact_id: this.client_id,
        resource_id: this.topic_detail_id,
        resource_type: 'TOPIC'
      }
      topicDetailApi.commitPraise(params).then( res =>{
        if(res.code === '0000'){
          this.isInteresting = true;
        }
      })
    },
    cancelInteresting(){
      let params = {
        client_contact_id: this.client_id,
        resource_id: this.topic_detail_id,
        resource_type: 'TOPIC'
      }
      topicDetailApi.cancelPraise(params).then( res =>{
        if(res.code === '0000'){
          this.isInteresting = false
        }
      })
    },
    commitFeedback (valueMsg) {
      let params = {
        client_contact_id: store.getters.uid,
        content: valueMsg,
      }
      topicDetailApi.commitFeedback(params).then( res =>{
        if(res.code === '0000') {
          this.$message({
            type: 'success',
            message: '提交成功'
          });
        }else {
          this.showError()
        }
      })
    },
    buyEvent () {
      if (this.topic_hot_contact.status === 'SUCCEED' || this.is_free_now === 1) {
        if (this.isFirstAndMonthClient === false) {
          let params = {
            topic_id:this.topic_detail_id
          }
          topicDetailApi.checkSummarySend(params).then( res=> {
            if (res.code === '0000') {
              if (res.data.check_result === true) {
                this.sendNewMomEmail()
              }else {
                this.$message(res.data.message)
              }
            }
          })
        }else {
          this.sendNewMomEmail()
        }
      }else if (this.topic_hot_contact.status !== 'APPLIED' || this.topic_hot_contact.status !== 'FAILED') {
        this.buySummary()
      }
    },
    sendNewMomEmail () {
      var data = {}
      if (this.topic_detail_id) {
        data.topic_id = this.topic_detail_id
      }
      topicDetailApi.sendSummary(data).then( res=> {
        if (res.code === '0000') {
          var message = ''
          if (this.isDBEmail === true) {
            message = '已将会议资料发送到邮箱：' + this.$store.getters.email
          }else {
            message = '由于您未在凯盛登记邮箱，无法发送邮件，请与您的客户经理联系。'
          }
          this.$msgbox({
            message: message,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确认',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                done()
              }
            }
          })
        }
      })
    },
    buySummary () {
      let params = {
        topic_id:this.topic_detail_id
      }
      topicDetailApi.buySummary(params).then( res=> {
        if (res.code === '0000') {
          if (res.data.status === 'SUCCEED') {
            this.$message('购买成功！')
          }
          this.loadTopicDetail()
        }
      })
    },
    sendMomEmail () {
      var tips = `事项：发送《${this.mainModel.title}》纪要全文<br/>收件邮箱：`
      let email = this.defaultEmail
      this.$msgbox({
        title: '提示',
        message: tips,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确认',
        showInput: true,
        inputPlaceholder: '用于接收纪要',
        inputValue: email,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
          } else if (action === 'cancel') {
            done()
          }
        }
      }).then(value => {
        this.updateConferenceTaskEmail(value.value)
      }).catch( e=>e)
    },
    updateConferenceTaskEmail (email) {
      var sendEmail = email
      if (this.tool.isNullOrEmpty(email)) {
        sendEmail = this.defaultEmail
      }
      var data = {}
      data.email = sendEmail
      if (this.topic_detail_id) {
        data.topic_id = this.topic_detail_id
      }
      if (this.isSendingEmail === false || this.mom_email_address !== sendEmail) {
        this.isSendingEmail = true
        this.mom_email_address = sendEmail
        topicDetailApi.sendSummary(data).then( res => {
          if (res.code === '0000') {
            this.$message('发送成功')
            setTimeout( ()=>{
              this.isSendingEmail = false
            },60000)
            }
        })
      }
    }
  }
}
</script>

<style>
  .topic_detail_wrapper{
    width: calc(100% - 335px);
    height: 100%;
    display: flex;
    display: -webkit-flex;
    background-color: #F6FAFB;
    flex-direction: column;
    padding-bottom: 67px;
  }
  .topic_detail_content_container{
    width: 100%;
    height:auto;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
  }
  .topic_detail_left{
    flex: 1;
    height: auto;
    margin-left: 0px;
    margin-top: 15px;
    background: white;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
  }
  .topic_detail_footer_container{
    position: fixed;
    bottom: 0px;
    left: calc(50% - 640);
    right: calc(50% - 640);
    width: 945px;
    height: 67px;
    background-color: white;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.09);
  }
  .topic_detail_footer_container_inner{
    width: 945px;
    height: 100%;
    background-color: white;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .btn_corner_orange{
    background-color: #4880FF;
    color: white;
    font-size: 16px;
    border: 0;
    border-radius: 8px;
    width: 120px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .btn_corner_able{
    background-color: #4880FF;
    color: white;
    font-size: 16px;
    border: 0;
    border-radius: 8px;
    padding: 0px 30px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .btn_corner_enable{
    background-color: #FFFFFF;
    color: #FFA000;
    font-size: 16px;
    border: 0;
    border-radius: 8px;
    padding: 0px 30px;
    height: 45px;
    text-align: center;
    line-height: 45px;
  }
  .topic_title{
    width: 100%;
    height: auto;
    margin-top: 38px;
    font-size: 18px;
    color: #272727;
    font-weight: 500;
  }
  p.topic_detail_content{
    font-size: 14px;
    color: black;
    margin: 0px 24px 20px 24px;
    text-align: left;
    line-height: 28px;
    white-space: pre-wrap;
    line-height: 22px;
  }
  p.topic_detail_un_indent{
    font-size: 14px;
    color: black;
    margin: 16px 24px 20px 24px ;
    text-align: justify;
    line-height: 28px;
  }
  .topic-text-center{
    color: #272727;
  }
  /*.topic_detail_wrapper /deep/ .el-dialog__body{*/
  /*  text-align: center;*/
  /*}*/
  .topic-detail-status{
    color: #FFA000;
  }
  .research-img-heart-interesting{
    width: 40px;
    height: 40px;
    margin: 15px 10px;
  }
  .interesting-btn{
    width: 120px;
    height: 47px;
    border: 1px solid #DEDEDE;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
    }
  }
  .right-option-btn {
    margin-left: 925px;
    top: calc(50% - 60px);
    width: 20px;
    position: fixed;
  }
  .right-seg-btn {
    margin-left: 902px;
    width: 45px;
    position: fixed;
    top: calc(50% - 147px);
  }
</style>
