<template>
  <div class="detail-research-cell">
    <div :class="isSearch? 'normal-cell-r' : 'normal-cell'" @click="openEvent" v-show="mainModel.isOpen === false">
      <label style="margin-left: 15px;border-radius: 4px;margin-right: 5px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
      <label :class="getIconType() != '' ? 'cell-gap-title' : 'cell-title'">{{ titleExtra + mainModel.title}}</label>
      <div class="cell-right-option">
<!--        <img style="width: 15px;height: 14px;margin-right: 15px;" v-if="mainModel.like_status === 'YES'" src="../assets/img/favsel.png" />
        <img style="width: 15px;height: 14px;margin-right: 15px;" v-else src="../assets/img/favunsel.png" /> -->
        <label class="time-label-ss">{{ tool.getDateFormat('y-m-d', mainModel.publish_time) }}</label>
        <img v-if="tool.isNullOrEmpty(statusString) === false" :src="statusString" style="height: 1.5rem;margin-right: 12px;" />
      </div>
    </div>
    <el-collapse-transition>
      <div id="detail-cell" v-show="mainModel.isOpen === true">
        <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: 24px;margin-left: 20px;margin-right: 20px;">
          <label style="font-size: 14px;padding: 2px 5px;border-radius: 4px;margin-right: 5px;" :class="getIconTypeClass()" v-if="getIconType() != ''">{{ getIconType() }}</label>
          <label @click="closeEvent" style="font-size: 18px;font-weight: 500;line-height: 20px;color: #272727;cursor: pointer;">{{ mainModel.title }}</label>
        </div>
        <div class="middle-view">
          <div class="right-view">
            <label @click="gotoDetail()" style="margin-left: 20px;margin-right: 20px;font-size: 14px;color: #272727;font-weight: 500;text-align: left;line-height: 14px;">【研报背景】</label>
            <div @click="gotoDetail()" style="margin-left: 20px;margin-right: 20px;font-size: 12px;line-height: 20px;text-align: left;color: #272727;margin-top: 10px;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 6;">{{ mainModel.background }}</div>
            <div v-if="mainModel.tags && mainModel.tags != ''" class="tags_view">
              <div v-for="(tag,tagIndex) in mainModel.tags.split('/')" :key="tagIndex">
                <div v-if="tag != ''" @click="goToSearch(tag)" class="tags_view_label">
                  {{ tag }}
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px;margin-bottom: 10px;width: 0.5px;background-color: #F5F5F5;"></div>
          <div class="left-view">
            <label @click="gotoDetail()" style="cursor: pointer;margin-left: 20px;margin-right: 20px;height: 14px;font-size: 14px;color: #272727;font-weight: 500;text-align: left;line-height: 14px;">【研报目录】</label>
            <div @click="gotoDetail()" v-html="categoryContent" style="cursor: pointer;margin-top: 10px;margin-left: 20px;margin-right: 20px;color: #4B4B4B;text-align: left;font-size: 12px;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 6;"></div>
          </div>
        </div>
        <div class="footer-option-view">
          <label style="white-space: pre-wrap;color: #FFA000;font-size: 12px;margin-left: 20px;text-align: left;line-height: 15px;margin-right: 20px;">{{ chargeInfoContent }}</label>
          <div style="display: flex;flex-direction: row-reverse;align-items: center;margin-right: 0px;margin-left: auto;">
            <button @click="gotoDetail()" style="margin-left: auto;margin-right: 20px;font-size: 12px;color:#3B7CFF;background-color: #F4F8F9;border-radius: 8px;height: 36px;padding: 0px 20px;outline: none;border: none;cursor: pointer;">研报详情</button>
            <div v-if="isApplied" style="margin-left: auto;margin-right: 20px;font-size: 14px;color: #FFA000;">正在处理购买请求</div>
            <div style="margin-left: auto;margin-right: 20px;font-size: 14px;" v-else-if="isPurchased">购买成功。如果您有疑问，请点击：<span @click="popFeedback" style="color: #3B7CFF;">这里</span></div>
            <div style="margin-left: auto;margin-right: 20px;font-size: 14px;" v-else-if="isFailed">购买失败。请与您的服务团队联系，<span @click="popFeedback" style="color: #3B7CFF;">这里</span></div>
            <button v-else @click="buyResearch()" style="margin-left: auto;margin-right: 20px;font-size: 12px;color:#FFFFFF;background-color: #4880FF;border-radius: 8px;height: 36px;padding: 0px 32px;outline: none;border: none;cursor: pointer;">购买研报</button>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <feedback :isShow="showFeedback" @dissmiss='showFeedback = false' :extraMessage="'--来自研究报告id：' +  mainModel.id"></feedback>
    <alertView title="提交购买意向" confirmTitle="确认" cancelTitle="取消" :tipsArray="tipsArray" :isShow="showBuyTopic" @cancelEvent="cancelAlert" @confirmEvent="confirmEvent"></alertView>
  </div>
</template>

<script>
import research from '../api/research_report.js'
import feedback from './feedback.vue'
import alertView from './alert_menu_view.vue'
  export default {
    components: {
      feedback,
      alertView
    },
    data () {
      return {
        showFeedback:false,
        showBuyTopic:false,
        tipsArray:[]
      }
    },
    props: {
      mainModel: {},
      titleExtra: String,
      isSearch: Boolean
    },
    computed: {
      isApplied: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'APPLIED') {
            return true
          }
        }
        return false
      },
      isPurchased: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'PURCHASED') {
            return true
          }
        }
        return false
      },
      isFailed: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'FAILED') {
            return true
          }
        }
        return false
      },
      statusString: function () {
        if (this.mainModel.my_task) {
          if (this.mainModel.my_task.status === 'APPLIED') {
            return require('../assets/img/yitijiao.svg')
          } else if (this.mainModel.my_task.status === 'PURCHASED') {
            return require('../assets/img/yiwancheng.svg')
          }
        }
        return ''
      },
      chargeInfoContent: function () {
        return '发布时间：' + this.tool.getDateFormat('y-m-d', this.mainModel.publish_time) + '     ' + '收费说明：' + this.mainModel.charge
      },
      categoryContent: function () {
        return this.mainModel.catalog.replace(/\n/gm,"<br/>")
      }
    },
    methods: {
      goToSearch(tag) {
        this.$store.dispatch('view/changeSearchTag', tag)
      },
      getIconTypeClass() {
        if(this.mainModel.icon === 'CLIENT_PURCHASED') {
          return 'purchased_icon_cell'
        }else {
          return ''
        }
      },
      getIconType() {
        if(this.mainModel.icon === 'CLIENT_PURCHASED') {
          return '公司已购'
        }else {
          return ''
        }
      },
      gotoDetail () {
        let routeData = this.$router.resolve({
          path: '/researchDetail',
          query: {id: this.mainModel.id}
        })
        window.open(routeData.href, '_blank')
      },
      openEvent () {
        this.$emit('openEvent')
      },
      closeEvent () {
        this.$emit('closeEvent')
      },
      popFeedback () {
        this.showFeedback = true
      },
      cancelAlert () {
        this.showBuyTopic = false
      },
      confirmEvent () {
        this.applyRequest()
        this.showBuyTopic = false
      },
      buyResearch () {
        var tips = `${this.mainModel.charge}，收到您的购买意向后，我们会尽快与您联系。`
        if (this.mainModel.icon == 'CLIENT_PURCHASED') {
          tips = '提交获取意向后，我们会尽快与您联系。'
        }
        this.tipsArray = [tips]
        this.showBuyTopic = true
      },
      applyRequest () {
        var data = {}
        if (this.$store.getters.uid) {
          data.contact_id = this.$store.getters.uid
        }
        data.research_id = this.mainModel.id
        data.status = 'APPLIED'
        research.createResearchStatus(data).then( res=> {
          if (res.code === '0000') {
            this.mainModel.my_task = res.data
          }
        }).catch( e=> e)
      }
    }
  }
</script>

<style lang="scss">
  .detail-research-cell {
    width: 100%;
    margin-top: 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .normal-cell-r{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    height: 60px;
    background-color: #FFFCEA;
    box-shadow:0px 0px 50px rgba(164,181,201,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
      box-shadow: 0px 0px 10px rgba(39, 138, 255, 0.26);
    }
  }
  .normal-cell{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    height: 60px;
    background-color: white;
    box-shadow:0px 0px 50px rgba(164,181,201,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #4880FF;
      box-shadow: 0px 0px 10px rgba(39, 138, 255, 0.26);
    }
  }
  #detail-cell {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .cell-title{
    color: #393939;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 24px;
    text-align: left;
  }
  .cell-gap-title{
    color: #393939;
    font-size: 15px;
    margin-left: 0px;
    margin-right: 24px;
    text-align: left;
  }
  .cell-right-option{
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .time-label-ss{
    margin-right: 24px;
    font-size: 12px;
    color: #909090;
    width: 70px;
  }
  .status-label{
    background-color: rgba(72, 128, 255, 0.09);
    color: #4880FF;
    padding: 0 22px;
    height: 28px;
    font-size: 12px;
    margin-right: 20px;
    line-height: 28px;
    text-align: center;
    border-radius:4px;
  }
  .middle-view {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .right-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 69%;
    padding-bottom: 20px;
    cursor: pointer;
  }
  .left-view {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 31%;
    padding-bottom: 20px;
  }
  .footer-option-ss-view {
    display: flex;
    flex-direction: row-reverse;
    height: 56px;
    margin-top: auto;
    margin-bottom: 0px;
    align-items: center;
  }
  .footer-option-view {
    display: flex;
    flex-direction: row;
    height: 56px;
    margin-top: auto;
    margin-bottom: 0px;
    border-top: 0.5px solid #F5F5F5;
    align-items: center;
  }
  .purchased_icon_cell {
    padding: 3px 8px;
    background-color: #D8F1FA;
    color: #008BDE;
    font-size: 12px;
  }
  .tags_view {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 14px;
    width: calc(100% - 34px);
    margin-top: 4px;
  }
  .tags_view_label {
    padding: 4px 10px;
    margin-right: 6px;
    margin-top: 6px;
    border: 1px solid #1E459D;
    border-radius: 4px;
    font-size: 12px;
    color: #1E459D;
    &:hover {
      background-color: #f0f0f0;
    }
    cursor: pointer;
  }
</style>
