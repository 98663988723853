<template>
  <div @touchmove.prevent class="vue-popup-success" v-show="showMask">
    <div class="mask-success" @click="closeMask()" v-show="showMask"></div>
    <transition name="slide">
      <div class="popup-content-success" v-show="showMask">
        <div style="display: flex;flex-direction: column;margin: 0px 0px;background-color: #FFFFFF;width: 100%;border-radius: 20px;align-items: center;">
          <transition name="slide-fade">
            <label style="width: 88%;height:30px;border-radius: 2px;line-height: 30px;color: #CA3F3F;background-color: #FFE0E0;font-size: 12px;margin-top: 60px;position: absolute;text-align: center;" v-if="errorMessage.length !==0">{{errorMessage}}</label>
          </transition>
          <div style="position: relative;height: 60px;display: flex;flex-direction: row;border-bottom: 1px solid #EAEAEA;width: 100%;">
            <div @click="changeType(false)" :class="isRigister ? 'normal_btn' : 'select_btn'">客服服务</div>
            <div @click="changeType(true)" :class="isRigister ? 'select_btn' : 'normal_btn'">{{ isTopic ? '在线提交' : '在线获取' }}</div>
            <div style="position: absolute;background-color: #EAEAEA;width: 1px;height: 60px;left: 50%;transform:translate(-50%);"></div>
          </div>
          <div v-if="isRigister === false" style="margin-top: 0px;padding-bottom: 35px;display: flex;flex-direction: column;">
            <div style="margin-top: 50px;display: flex;flex-direction: row;align-items: center;height: 40px;">
              <label style="text-align: center;margin-left: 20px;font-size: 15px;color: #0D0D0D;line-height: 15px;height: 15px;width: 40px;">+86</label>
              <div style="width: 100%;height: 40px;margin-left: 10px;margin-right: 20px;background-color: #F8F8F8;border-radius: 4px;border: 1px solid #E0E0E0;">
                <div class="input-min">
                  <input class="input-view-s" @blur="changeBlur" type="number" v-model="mobile" placeholder="请输入您的手机号" />
                </div>
              </div>
            </div>
            <p style="margin-left: 20px;margin-right: 20px;color: #606060;font-size: 14px;line-height: 18px;">{{ tipText() }}</p>
            <div style="margin-left: 20px;margin-right: 20px;display: flex;flex-direction: row;align-items: center;margin-top: 30px;justify-content: center;">
              <el-button @click="cancel" plain style="width: 50%;">取消</el-button>
              <el-button @click="unloginCreateOrder" :disabled="tool.isNullOrEmpty(mobile)" type="primary" style="width: 50%;">确定</el-button>
            </div>
          </div>
          <div v-if="isRigister === true" style="margin-top: 0px;padding-bottom: 35px;display: flex;flex-direction: column;">
            <div style="height: 40px;margin-left: 20px;margin-right: 20px;margin-top: 50px;display: flex;flex-direction: row;">
              <div style="height: 40px;margin-left: 0px;margin-right: 10px;background-color: #F8F8F8;border-radius: 4px;border: 1px solid #E0E0E0;">
                <div class="input-min">
                  <input class="input-view-s" @blur="changeBlur" v-model="account" placeholder="凯盛客户的手机号/邮箱" />
                </div>
              </div>
              <button :class="disabled ? 'btn_enable_s' : 'btn_normal_s'" @click="btnSend()" style="margin-right: 0px;text-align: center;font-size: 12px;margin-left: auto;color:#FFFFFF;border-radius: 4px;line-height: 12px;height: 40px;outline: none;border: none;">{{sendCodeBtnText}}</button>
            </div>
            <div style="margin-right: 20px;height: 40px;margin-left: 20px;background-color: #F8F8F8;border-radius: 4px;border: 1px solid #E0E0E0;margin-top: 18px;">
              <div class="input-min">
                <input class="input-view-s" @blur="changeBlur" v-model="code" placeholder="输入验证码" />
              </div>
            </div>
            <div style="margin-left: 20px;margin-right: 20px;display: flex;flex-direction: row;align-items: center;margin-top: 30px;justify-content: center;">
              <el-button @click="cancel" plain style="width: 50%;">取消</el-button>
              <el-button :loading="loading" @click="loginEvent" plain type="primary" style="width: 50%;">登录</el-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { login, askVerificationCode } from '../../api/login.js'
  export default {
    data () {
      return {
        isRigister: true,
        mobile: '',
        errorMessage: '',
        account: '',
        code: '',
        sendCodeBtnText: '发送验证码',
        disabled: false,
        loading: false,
        showMask: false
      }
    },
    props: {
      isShow: Boolean,
      isComplete: Boolean,
      noConference: Boolean,
      isTopic: Boolean,
      value: {}
    },
    mounted(){
        this.showMask = this.value;
    },
    watch:{
        value(newVal){
          this.showMask = newVal;
        }
    },
    methods: {
      tipText () {
        if (this.noConference) {
          return '通过手机号提交意向，凯盛会尽快与您联系。'
        }else {
          return '请通过手机号提交请求，凯盛工作人员会尽快联系您。'
        }
      },
      changeBlur () {
        // setTimeout(() => {
        //   window.scrollTo(0,0)
        // }, 0)
      },
      showError (msg) {
        this.errorMessage = msg
        setTimeout(() => {
          this.errorMessage = ''
        }, 2000)
      },
      loginEvent () {
        if (this.tool.isNullOrEmpty(this.account)) {
          this.showError('请填写帐号')
          return
        }
        if (this.tool.isEMail(this.account) === false && this.tool.isMobile(this.account) === false) {
          this.showError('帐号必须是邮箱或手机号')
          return
        }
        var loginData = {}
        if (this.tool.isNullOrEmpty(this.code)) {
          this.showError('验证码不能为空')
          return
          }
        loginData.account = this.account
        loginData.code = this.code
        this.loading = true
        login(loginData, 0).then(res => {
          this.loading = false
          if (res.code === '0000') {
            this.$store.dispatch('user/setUserInfo', res.data.client_contact)
            this.$store.dispatch('user/setSid', res.data.sid)
            if (this.tool.isNullOrEmpty(res.data.contract_type) === false) {
              this.$store.dispatch('user/setContractType', res.data.contract_type)
            }
            this.$emit('loginSuccessEvent')
          } else {
            this.showError(res.message)
          }
        })
      },
      doLoop (seconds) {
        seconds = seconds || 60
        this.sendCodeBtnText = seconds + 's后获取'
        let countdown = setInterval(() => {
          if (seconds > 0) {
            this.sendCodeBtnText = seconds + 's后获取'
            --seconds
          } else {
            this.sendCodeBtnText = '获取验证码'
            this.disabled = false
            clearInterval(countdown)
          }
        }, 1000)
      },
      btnSend () {
        if (this.disabled === true) {
          return
        }
        if (this.tool.isNullOrEmpty(this.account)) {
          this.showError('请填写帐号')
          return
        }
        if (this.tool.isEMail(this.account) === false && this.tool.isMobile(this.account) === false) {
          this.showError('帐号必须是邮箱或手机号')
          return
        }
        this.disabled = true
        this.sendCodeBtnText = '请稍候...'
        this.getVerificationCode()
        setTimeout(() => {
          this.doLoop(60)
        })
      },
      getVerificationCode () {
        var data = {}
        data.account = this.tool.getRsaCode(this.account)
        askVerificationCode(data).then(res => {
          if (res.code !== '0000') {
            this.showError(res.message)
          }
        })
      },
      changeType (val) {
        this.isRigister = val
      },
      cancel () {
        this.$emit('dissmiss')
      },
      closeMask() {
        this.$emit('dissmiss')
      },
      unloginCreateOrder () {
        this.$emit('unloginCreateOrder', this.mobile)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-success{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.22);
      transition: all 0.15s linear;
      bottom: 0;
  }
  .vue-popup-success {
      .popup-content-success {
          position: absolute;
          height: calc(env(safe-area-inset-bottom) + 300px);
          bottom: 0px;
          left: 0px;
          width: 100%;
          background-color: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 8px 8px 0px 0px;
          -webkit-transition: all 0.15s linear;
          transition: all 0.15s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      position: fixed;
      width: 100%;
      bottom: 0px;
      left: 0px;
      top: 0px;
  }
  .slide-enter-active {
      animation-name: slideInUp;
      animation-duration: 0.15s;
      animation-fill-mode: both;
  }
  .slide-leave-active {
      animation-name: slideOutDown;
      animation-duration: 0.1s;
      animation-fill-mode: both;
  }
  @keyframes slideInUp {
      0% {
          transform: translate3d(0, 100%, 0);
          visibility: visible;
      }

      to {
          transform: translateZ(0);
      }
  }
  @keyframes slideOutDown {
      0% {
          transform: translateZ(0);
      }

      to {
          visibility: hidden;
          transform: translate3d(0, 100%, 0);
      }
  }
  .delay-leave-active {
      -webkit-animation-delay: 0.15s;
      -moz-animation-delay: 0.15s;
      -o-animation-delay: 0.15s;
      animation-delay: 0.15s;
  }
  .btn_normal_s {
    background-color: #1E9D40;
  }
  .btn_enable_s {
    background-color: #999999;
  }
  .select_btn {
    line-height: 60px;
    color: #1E459D;
    text-align: center;
    height: 60px;
    width: 50%;
    font-size: 15px;
    font-weight: bold;
  }
  .normal_btn {
    color: #000000;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 50%;
    font-size: 15px;
    opacity: 0.5;
  }
  .input-min {
    height: 40px;
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input-view-s{
    text-align: left;
    width: 100%;
    color: #313131;
    font-size: 14px;
    background-color: rgb(1,1,1,0);
    border: 0;
    outline: none;
  }
  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
  .el-dialog__wrapper >>> .el-dialog__header {
    padding: 0px 0px !important;
  }
  .el-dialog__wrapper >>> .el-dialog__body {
    text-align: initial;
    padding: 0px 0px !important;
  }
</style>
