<template>
  <div class="download_app_tip">
    <p style="white-space: pre-wrap;margin-top: 20px;color: #1998F8;font-size: 15px;text-align: center;line-height: 20px;">{{ tipString }}<span @click="goToDown()" style="color: #1E459D;padding-left: 10px;line-height: 15px;font-size: 12px;text-align: center;text-decoration: underline;line-height: 20px;">立即下载</span></p>
<!--    <div class="downlod-btn-view" @click="goToDown">
      <img style="margin-right: 5px;" src="../../assets/img/downloadicon.svg" />
      <label style="margin-right: 5px;color: #FFFFFF;font-size: 17px;">立即下载</label>
    </div> -->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        ios_URL: 'http://itunes.apple.com/cn/app/id1065427847',
        android_URL: 'https://cdn.capvision.cn/apk/capvision_v3.1.2.apk'
      }
    },
    props: {
      tipString: String
    },
    methods: {
      goToDown (){
        const ua = navigator.userAgent.toLowerCase()
        if (ua.indexOf('android') !== -1) {
          window.open(this.android_URL)
        }else if (ua.indexOf('iphone') !== -1) {
          window.open(this.ios_URL)
        }
      }
    }
  }
</script>
<style>
  .download_app_tip {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .downlod-btn-view {
    margin-top: 0px;
    width: calc(100% - 45px);
    height: 58px;
    border-radius: 29px;
    background: linear-gradient(236deg, #12A3FD 0%, #0E58DD 100%);
    box-shadow: 0px 2px 17px rgba(18, 132, 230, 0.57);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
